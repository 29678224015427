import React, { useState, useContext, useMemo, useEffect, useCallback } from 'react';
import axios from 'axios';
import { FirebaseContext } from '../../firebase';
import { uploadFile } from '../../common/fetch';
import styled from 'styled-components';
import {
  Container,
  Input,
  Dropdown,
  Div,
  Button,
  Form,
  Modal,
  Address,
  FileInput
} from '../../components';
import Loader from '../../components/Loader';
import { Logger } from '../../common/hooks';
import { UserStateContext } from '../../contexts/UserContext';

const Gap = styled.div`
  margin: 1.5rem 0;
`;

function AddPartner(props) {
  const [data, setData] = useState({
    level: 'admin',
    authLevel: 'NORMAL',
    status: true
  });
  const { db } = useContext(FirebaseContext);
  const user = useContext(UserStateContext);

  const [addressOpen, setAddressOpen] = useState(false);
  const [files, setFiles] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [depth_1_list, setDepth_1_list] = useState(); // 총판
  const [depth_2_list, setDepth_2_list] = useState(); // 대리점

  const showAddress = useMemo(() => {
    return data && data.address && `${data.address.road || ''} ${data.address.detail || ''}`;
  }, [data]);

  const onInputChange = e => {
    const { name, value } = e.target;
    name === 'depth'
      ? setData({ ...data, [name]: parseInt(value) })
      : setData({ ...data, [name]: value });
    if (name === 'email') setError(null);
  };

  const loadDepth_1_list = useCallback(() => {
    db.collection('users')
      .where('depth', '==', 1)
      .get()
      .then(snapshots => {
        let result = [];
        snapshots.forEach(doc => result.push({ ...doc.data(), id: doc.id }));
        setDepth_1_list(result);
      });
  }, [db]);

  const loadDepth_2_list = useCallback(() => {
    db.collection('users')
      .where('group', 'array-contains', data.depth_1)
      .get()
      .then(snapshots => {
        let result = [];
        snapshots.forEach(doc => result.push({ ...doc.data(), id: doc.id }));
        setDepth_2_list(result.filter(item => item.depth === 2));
      });
  }, [db, data.depth_1]);

  useEffect(() => {
    if (user && user.authLevel !== 'ROOT') {
      alert('접속 권한이 없습니다.');
      return props.history.goBack();
    }
  }, [props.history, user]);

  useEffect(() => {
    if (!depth_1_list) loadDepth_1_list();
    if (data.depth_1) loadDepth_2_list();
  }, [loadDepth_1_list, loadDepth_2_list, data.depth_1, data.depth_2, depth_1_list]);

  const onRegist = async () => {
    setIsLoading(true);
    let newData = {
      ...data,
      logs: [new Logger(user, false).toObject()]
    };

    delete newData.pw;

    // 파일 업로드 처리
    let fileArray;
    if (files) {
      fileArray = [];
      for (const name in files) {
        fileArray.push({ name, file: files[name] });
      }
    }
    if (!data.depth) return alert('등급을 선택하세요.');
    // 상위 협력사 선택 체크
    if (data.depth > 1) {
      if (!data.depth_1) return alert('총판을 선택하세요.');
      newData.group = [data.depth_1];
      delete newData.depth_1;
      if (data.depth_2) {
        newData.group = [...newData.group, data.depth_2];
        newData.companyName = depth_2_list.filter(item => item.id === data.depth_2)[0].companyName;
        delete newData.depth_2;
      } else {
        if (data.depth === 3) return alert('대리점을 선택하세요.');
      }
    }

    const result = await axios.post(
      'https://us-central1-deliquick-93b6b.cloudfunctions.net/api/create/partner',
      {
        email: data.email,
        pw: data.pw
      }
    );
    if (result.data.result === 'SUCCESS') {
      const { uid } = result.data;
      if (fileArray) {
        const promises = fileArray.map(item => uploadFile(`users/${uid}`, item.file, item.name));
        const uploadResult = await Promise.all(promises);
        uploadResult.forEach(item => {
          newData[item.id] = item.url;
        });
      }
      db.collection('users')
        .doc(uid)
        .set({
          ...newData,
          createdAt: new Date()
        })
        .then(_ => {
          props.history.push(`/partner`);
          console.log('success');
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      if (result.data.error.code === 'auth/email-already-exists') {
        setError('이미 사용중인 이메일 입니다.');
      } else {
        setError('이메일 주소가 올바르지 않습니다.');
      }
    }
    setIsLoading(false);
  };

  const onFileChange = e => {
    const file = e.target.files[0];
    if (!file) return;
    if (file.size > 10 * 1024 * 1024) {
      alert('10MB 이하의 파일만 업로드 가능합니다.');
      return null;
    }
    setFiles({ ...files, [e.target.name]: file });
  };

  return (
    <Container title="협력사 등록" navigator="신규 생성">
      <Form
        onSubmit={_ => {
          if (isLoading) return;
          onRegist();
        }}
      >
        <Div title="등급" required>
          <Dropdown
            name="depth"
            onChange={onInputChange}
            required
            options={[
              {
                text: '총판',
                value: 1
              },
              { text: '대리점', value: 2 },
              { text: '영업사원', value: 3 }
            ]}
          />
        </Div>
        {depth_1_list && data.depth > 1 && (
          <Div title="총판" required>
            <Dropdown
              name="depth_1"
              onChange={onInputChange}
              options={depth_1_list.map(item => ({ text: item.companyName, value: item.id }))}
            />
          </Div>
        )}
        {data.depth > 2 && (
          <Div title="대리점" required>
            <Dropdown
              name="depth_2"
              onChange={onInputChange}
              options={
                depth_2_list
                  ? depth_2_list.map(item => ({ text: item.companyName, value: item.id }))
                  : [{ text: data.depth_1 ? '총판을 선택하세요.' : 'loading...' }]
              }
            />
          </Div>
        )}

        <Gap />

        <Input title="이메일" onChange={onInputChange} type="email" name="email" required />
        {error && (
          <div style={{ fontSize: '12px', color: '#ff3250', marginLeft: '120px' }}>{error}</div>
        )}
        <Input title="비밀번호" onChange={onInputChange} name="pw" type="password" required />
        {data.depth === 3 ? (
          <Input
            title="협력사명"
            onChange={onInputChange}
            value={
              (data.depth_2 &&
                depth_2_list &&
                depth_2_list.filter(item => item.id === data.depth_2)[0].companyName) ||
              ''
            }
            name="companyName"
            required
            disabled={data.depth === 3}
          />
        ) : (
          <Input title="협력사명" onChange={onInputChange} name="companyName" required />
        )}
        <Input title="대표자명" onChange={onInputChange} name="name" required />
        <Input title="전화번호" onChange={onInputChange} name="phone" required type="tel" />

        <Gap />

        <Input title="사업자번호" onChange={onInputChange} name="bn" />

        <Input
          value={showAddress && showAddress}
          title={'주소'}
          name="address"
          style={{ width: '350px' }}
          disabled
        >
          <Button
            style={{
              marginLeft: '1rem',
              background: '#339af0'
            }}
            onClick={() => {
              setAddressOpen(true);
            }}
          >
            주소검색
          </Button>
        </Input>

        <Gap />

        <Input title="은행" onChange={onInputChange} name="bankName" />
        <Input title="계좌번호" onChange={onInputChange} name="account" />

        <Gap />

        <Div title="사업자등록증">
          <FileInput name="file-1" onChange={onFileChange} />
        </Div>
        <Div title="통장사본">
          <FileInput name="file-2" onChange={onFileChange} />
        </Div>
      </Form>
      {isLoading && <Loader msg="잠시만 기다려주세요..." />}

      {addressOpen && (
        <Modal
          onClose={() => {
            setAddressOpen(false);
          }}
        >
          <Address setData={setData} setAddressOpen={setAddressOpen} />
        </Modal>
      )}
    </Container>
  );
}

export default AddPartner;
