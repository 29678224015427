import React, { useContext, useState, useEffect, useMemo, useCallback } from 'react';
import { FirebaseContext } from '../../firebase';
import * as geofirex from 'geofirex'; // check: geofirex @0.1.0 업데이트시 에러 현재 (@0.0.6)
import Input from '../../components/Input';
import Button from '../../components/Button';
import Container from '../../components/Container';
import { Address, Modal, Notice, Status } from '../../components';
import { useFirebase } from '../../common/fetch';
import { find } from 'lodash';
import queryString from 'query-string';
import { UserStateContext } from '../../contexts/UserContext';
import { Link } from 'react-router-dom';

const StoreMapView = () => {
  const { db, app } = useContext(FirebaseContext);
  const [data, setData] = useState(null);
  const [distance, setDistance] = useState(2);
  const [locations, setLocations] = useState();
  const [code, setCode] = useState(queryString.parse(window.location.search).code);
  const user = useContext(UserStateContext);
  const geo = geofirex.init(app);

  const [address, setAddress] = useState();
  const [addressOpen, setAddressOpen] = useState(false);
  const [{ ...tags }] = useFirebase('tags', null, null, ['type', '==', '카테고리']);

  const tagData = useMemo(() => {
    const fixed = ['한식', '치킨', '중식', '일식', '이탈리안/피자/햄버거', '아시안', '샐러드', '디저트', '아메리칸', '아프리카', '멕시칸', '아랍/터키', '인도', '유럽', '도시락', '잡화'];
    return (
      tags.data &&
      tags.data
        .filter((item) => fixed.includes(item.kr))
        .map((item) => ({ id: item.id, kr: item.kr }))
    );
  }, [tags.data]);

  const addQuery = useCallback((code) => {
    if (!code) return;
    const query = queryString.stringify({ code });
    window.history.replaceState(
      { data: 'map' },
      'Query searching data',
      `${window.location.pathname}?${query}`
    );
  }, []);

  const loadData = useCallback(
    async (type) => {
      const stores = geo.collection('stores');
      if (distance > 10) return alert('최대 10km까지 검색가능합니다.');
      let geopoint, name;
      if (type === 'code') {
        if (!code) return alert('코드를 입력하세요.');
        const result = await db
          .collection('hotels')
          .doc(code)
          .get()
          .then(
            (doc) => doc.exists && { geopoint: doc.data().position.geopoint, name: doc.data().name }
          );
        if (!result) return alert('검색된 업체가 없습니다.');
        geopoint = result.geopoint;
        name = result.name;
      } else {
        if(!address) return;
        geopoint = address.position.geopoint;
        name = address.address.road || address.address.jibun;
      }

      setLocations({ lng: geopoint.longitude, lat: geopoint.latitude, name });
      const center = geo.point(geopoint.latitude, geopoint.longitude);
      const radius = distance;
      const field = 'position';

      const query = stores.within(center, radius, field);
      query.subscribe((data) => {
        const newData = data.map((item) => ({
          ...item,
        }));
        setData(newData.filter((item) => item.status && item.status !== '해지'));
        addQuery(code);
      });
    },
    [addQuery, code, db, distance, geo, address]
  );

  useEffect(() => {
    if (code) loadData('code');
  }, []); // eslint-disable-line

  useEffect(() => {
    // init map
    const initGoogleMap = (_) => {
      const google = window.google;
      const map = new google.maps.Map(document.getElementById('map'), {
        zoom: 15,
        center: { lat: locations.lat, lng: locations.lng },
      });

      new google.maps.Marker({
        position: { lat: locations.lat, lng: locations.lng },
        label: locations.name,
        map: map,
        icon: {
          url: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png',
        },
        zIndex: 10,
      });

      new google.maps.Circle({
        strokeColor: '#ff3250',
        strokeOpacity: 0.5,
        strokeWeight: 2,
        fillColor: '#748ffc',
        fillOpacity: 0.1,
        map: map,
        center: { lat: locations.lat, lng: locations.lng },
        radius: distance * 1000, // 단위 (m)
      });

      data.map((item) => {
        const { latitude, longitude } = item.position.geopoint;

        const marker = new google.maps.Marker({
          position: {
            lat: parseFloat(latitude),
            lng: parseFloat(longitude),
          },
          icon: {
            url: 'http://maps.google.com/mapfiles/ms/icons/pink-dot.png',
          },
          title: item.realName,
          map: map,
        });
        const infowindow = new google.maps.InfoWindow({
          content: item.realName,
        });

        return marker.addListener('click', function () {
          infowindow.open(map, marker);
        });
      });
    };
    if (data) initGoogleMap();
  }, [data, locations, distance]);

  // const updateStore = () => {
  //   const storeRef = db.collection('stores').doc('dGxwlf8xpZu1eyvQppaz');

  //   storeRef.get().then(doc => {
  //     const data = doc.data();
  //     const { x, y } = data.address.extras;
  //     const point = geo.point(parseFloat(y), parseFloat(x));
  //     storeRef.update({ position: point.data }).then(() => {
  //       console.log('success');
  //     });
  //   });
  // };

  const getCount = (id) => {
    return data && data.filter((item) => item.tags && item.tags[0] === id).length;
  };

  const getColor = (num) => {
    if (num > 3) {
      return '#37b24d';
    } else if (num > 0) {
      return '#999';
    } else {
      return '#ff6d72';
    }
  };

  return (
    <Container title="배달업체관리" navigator="거리별 업체 검색">
      <div>
        <Notice>
          <b>숙박업체코드</b> 혹은 <b>주소</b>로 직접 검색이 가능합니다.
          <br /> 최대 10km까지 검색 가능합니다.
        </Notice>
        <Input
          title="반경(km)"
          style={{ minWidth: '20px', width: '20px' }}
          onChange={(e) => {
            setDistance(parseFloat(e.target.value || 0));
          }}
          defaultValue={distance}
        />

        <Input
          title="1. 숙박업체코드"
          onChange={(e) => setCode(e.target.value)}
          defaultValue={code}
        >
          <Button
            onClick={() => {
              loadData('code');
            }}
          >
            코드로 검색
          </Button>
        </Input>

        <Input
          title="2. 주소"
          disabled
          style={{ width: '60%' }}
          value={address ? address.address.road || address.address.jibun : ''}
        >
          <Button
            onClick={() => {
              setAddressOpen(true);
            }}
            type="normal"
          >
            주소찾기
          </Button>
        </Input>

        <span />
        <Button
          style={{ marginLeft: '120px' }}
          onClick={() => {
            loadData('address');
          }}
        >
          주소로 검색
        </Button>

        {addressOpen && (
          <Modal
            onClose={() => {
              setAddressOpen(false);
            }}
          >
            <Address setAddressOpen={setAddressOpen} setData={setAddress} />
          </Modal>
        )}
      </div>
      <div>
        <hr />
        {data && (
          <>
            <b>검색결과</b>
            <span> | 총 {data.length}개</span>
            <span> | {parseInt((data.length / 40) * 100)}%</span>
            <table
              style={{
                width: '90%',
                margin: '0.5rem 5%',
                textAlign: 'center',
                border: '1px solid #ccc',
                borderCollapse: 'collapse',
              }}
            >
              <tbody>
                <tr>
                  <th>카테고리별</th>
                  <td style={{ border: '1px solid #ccc', padding: '0.25rem' }}>피자/버거</td>
                  {tagData &&
                    tagData
                      .filter((item) => item.kr !== '피자' && item.kr !== '버거')
                      .map((item, index) => (
                        <td key={index} style={{ border: '1px solid #ccc', padding: '0.25rem' }}>
                          {item.kr}
                        </td>
                      ))}
                </tr>
                <tr>
                  <th>매장수</th>
                  <td
                    style={{
                      border: '1px solid #ccc',
                      padding: '0.25rem',
                      fontWeight: 'bold',
                      color: getColor(
                        getCount('pInMwb3XIa1wBiZSoqts') + getCount('OAE9uKqrKagNUkmEMYj5')
                      ),
                    }}
                  >
                    {getCount('pInMwb3XIa1wBiZSoqts') + getCount('OAE9uKqrKagNUkmEMYj5')}
                  </td>
                  {tagData &&
                    tagData
                      .filter((item) => item.kr !== '피자' && item.kr !== '버거')
                      .map((item, index) => (
                        <td
                          key={index}
                          style={{
                            border: '1px solid #ccc',
                            padding: '0.25rem',
                            fontWeight: 'bold',
                            color: getColor(getCount(item.id)),
                          }}
                        >
                          {getCount(item.id)}
                        </td>
                      ))}
                </tr>
              </tbody>
            </table>

            <table
              style={{
                width: '90%',
                margin: '0.5rem 5%',
                textAlign: 'center',
                border: '1px solid #ccc',
              }}
            >
              <thead>
                <tr>
                  <th></th>
                  <th>업체명</th>
                  <th>거리</th>
                  <th>카테고리</th>
                  <th>등록상태</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => (
                  <tr key={index}>
                    <td style={{ padding: '0.25rem', textAlign: 'center' }}>{index + 1}</td>
                    <td>
                      {user && user.authLevel === 'ROOT' ? (
                        <Link to={`/store/edit/${item.id}`}>{item.realName}</Link>
                      ) : (
                        item.realName
                      )}
                    </td>
                    <td>{item.queryMetadata.distance.toFixed(2)}km</td>
                    <td>{item.tags && find(tagData, (tag) => tag.id === item.tags[0])?.kr}</td>
                    <td>
                      <Status type={item.status} msg={item.status} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}

        {data && (
          <div style={{ width: '90%', margin: '0.5rem 5%', border: '1px solid #ccc' }}>
            <div id="map" style={{ minHeight: '600px' }} />
          </div>
        )}
      </div>
    </Container>
  );
};

export default StoreMapView;