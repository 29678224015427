import React, { useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/free-solid-svg-icons';

const Container = styled.div`
  margin: 1rem 0;
  height: 100%;
  .input-label {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    outline: none;
    background: #aaa;
    font-weight: bold;
    color: white;
    border-radius: 4px;
    padding: 0 1rem;
    font-size: 14px;
    width: fit-content;
    cursor: pointer;
    height: 34px;
    &:hover {
      opacity: 0.9;
    }
  }
`;

const FileInput = ({ onChange, name, style, ...rest }) => {
  const [fileInfo, setFileInfo] = useState(null);
  return (
    <Container style={{ ...style }}>
      <label
        className="input-label"
        htmlFor={`file-input-${name}`}
        style={{
          background: fileInfo ? '#f06595' : '#aaa'
        }}
      >
        {fileInfo ? (
          <span>
            <FontAwesomeIcon
              icon={faFile}
              size="sm"
              color="white"
              style={{ marginRight: '0.25rem' }}
            />
            {fileInfo.name}
          </span>
        ) : (
          <span>
            <FontAwesomeIcon
              icon={faFile}
              size="sm"
              color="white"
              style={{ marginRight: '0.25rem' }}
            />
            파일 선택
          </span>
        )}
      </label>
      <input
        type="file"
        id={`file-input-${name}`}
        name={name}
        style={{ display: 'none', width: 0, height: 0 }}
        onChange={e => {
          onChange(e);
          const file = e.target.files[0];
          if (!file) return;
          setFileInfo(file);
        }}
        {...rest}
      />
    </Container>
  );
};

export default FileInput;
