import React, { useContext, useState, useEffect } from 'react';
import { FirebaseContext } from '../../firebase';
import { Button, Loader, Table, Container } from '../../components';

const WordList = () => {
  const { db } = useContext(FirebaseContext);
  const [data, setData] = useState(null);

  useEffect(() => {
    db.collection('words')
      .get()
      .then(snapshots => {
        let result = [];
        snapshots.forEach(doc => result.push({ ...doc.data(), id: doc.id }));
        setData(result);
      });
  }, [db]);

  return (
    <Container
      title="번역관리"
      navigator="번역데이터목록"
      buttons={
        <Button type="normal" to="/translation/add">
          +추가
        </Button>
      }
    >
      {data ? (
        <Table
          data={data}
          index
          headers={[
            { title: '분류', value: 'type' },
            { title: '한국어', value: 'kr' },
            { title: '영어', value: 'en' },
            { title: '중국어', value: 'cn' },
            { title: '일본어', value: 'jp' },
            { title: '태국어', value: 'th' },
            {
              title: '',
              value: '',
              render: row => (
                <Button type="small" to={`translation/edit/${row.id}`}>
                  수정
                </Button>
              ),
              notSearch: true,
              width: '10%'
            }
          ]}
          perPage={30}
        />
      ) : (
        <Loader />
      )}
    </Container>
  );
};

export default WordList;
