import React, { useContext, useState, useEffect } from 'react';
import Input from '../../components/Input';
import Button from '../../components/Button';
import { FirebaseContext } from '../../firebase';
import { Dropdown, Container, Toast as toast, Div, Form } from '../../components';

const EditTag = props => {
  const { id } = props.match.params;
  const { db } = useContext(FirebaseContext);
  const [data, setData] = useState(null);

  useEffect(() => {
    db.collection('tags')
      .doc(id)
      .get()
      .then(doc => {
        setData(doc.data());
      })
      .catch(err => console.log(err));
  }, [db, id]);

  const onChangeInput = e => {
    setData({
      ...data,
      [e.target.name]: e.target.value
    });
  };

  const onEdit = () => {
    db.collection('tags')
      .doc(id)
      .update({ ...data })
      .then(() => {
        toast('업데이트 되었습니다.');
        props.history.push(`/tags`);
      });
  };

  const onDelete = () => {
    let result = window.confirm('정말 삭제하시겠습니까?');
    if (result) {
      db.collection('tags')
        .doc(id)
        .delete()
        .then(() => {
          toast('삭제되었습니다.');
          props.history.push(`/tags`);
        });
    } else {
      return null;
    }
  };

  return (
    <>
      <Container
        title="태그수정"
        buttons={
          <Button
            style={{
              color: '#ff3250'
            }}
            type="delete"
            onClick={onDelete}
          >
            삭제
          </Button>
        }
      >
        {data && (
          <Form onSubmit={onEdit} btn="저장">
            <Div title="분류" required>
              <Dropdown
                placeholder="분류"
                options={[
                  { text: '카테고리', value: '카테고리' },
                  { text: '맛', value: '맛' },
                  { text: '재료', value: '재료' },
                  { text: '조리법', value: '조리법' },
                  { text: '소스', value: '소스' },
                  { text: '기타', value: '기타' }
                ]}
                style={{ width: '10%', marginRight: '0.2rem' }}
                onChange={onChangeInput}
                name="type"
                defaultValue={data.type}
              />
            </Div>

            <Input title={'한국어'} required onChange={onChangeInput} name="kr" value={data.kr} />
            <Input title={'영어'} required onChange={onChangeInput} name="en" value={data.en} />
            <Input title={'중국어'} required onChange={onChangeInput} name="cn" value={data.cn} />
            <Input title={'일본어'} required onChange={onChangeInput} name="jp" value={data.jp} />
            <Input title={'태국어'} required onChange={onChangeInput} name="th" value={data.th} />
          </Form>
        )}
      </Container>
    </>
  );
};

export default EditTag;
