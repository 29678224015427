import React, { useContext, useEffect, useState, useCallback } from 'react';
import { UserStateContext } from '../contexts/UserContext';
import styled from 'styled-components';
import { useFirebase } from '../common/fetch';
import { Table, Loader } from '../components';
import { Link } from 'react-router-dom';
import { MdLabel, MdEmail, MdPerson, MdReportProblem } from 'react-icons/md';
import { FirebaseContext } from '../firebase';
import Bowser from 'bowser';
import { sortBy } from 'lodash';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .box-container {
    display: flex;
    width: 100%;
    @media screen and (max-width: 480px) {
      flex-direction: column;
    }
  }
  .box {
    width: 48%;
    margin-right: 1%;
    margin-bottom: 1rem;
    height: 150px;
    background: #f6f6f6;
    font-size: 14px;
    color: #4e4e4e;
    @media screen and (max-width: 480px) {
      width: 100%;
      margin-right: 0;
    }
    .title {
      margin: 1rem;
      font-weight: bold;
      font-size: 16px;
    }
    .icon {
      color: #1d4371;
      font-size: 18px;
      font-weight: bold;
    }
    .item {
      display: flex;
      align-items: center;
      padding: 0.25rem 1rem;
    }
  }
  .top-h {
    padding: 1em 5%;
    width: 90%;
    font-size: 16px;
    font-weight: bold;
  }

  .count {
    border-top: 2.5px solid #1d4371;
    padding-top: 0.25rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    padding-left: 1rem;
    margin-right: 1rem;
    width: 70px;
    .name {
      font-size: 12px;
      margin-bottom: 0.25rem;
    }
  }
`;

const Home = () => {
  const user = useContext(UserStateContext);
  const { db } = useContext(FirebaseContext);
  const [{ ...notice }] = useFirebase('boards', null, null, ['notice', '==', true]);
  const [store, setStore] = useState();
  const [hotel, setHotel] = useState();
  const [partner, setPartner] = useState();
  const [ask, setAsk] = useState();
  const depthArray = ['본사', '총판', '대리점', '영업사원'];

  const browser = Bowser.parse(window.navigator.userAgent);

  const loadStore = useCallback(() => {
    if (!user) return;
    db.collection('stores')
      .where('group', 'array-contains', user.id)
      .get()
      .then(snapshots => {
        let result = [];
        snapshots.forEach(doc => {
          result.push(doc.data());
        });
        setStore(result);
      });
  }, [db, user]);

  const loadHotel = useCallback(() => {
    if (!user) return;
    db.collection('hotels')
      .where('group', 'array-contains', user.id)
      .get()
      .then(snapshots => {
        let result = [];
        snapshots.forEach(doc => {
          result.push(doc.data());
        });
        setHotel(result);
      });
  }, [db, user]);

  const loadPartner = useCallback(() => {
    if (!user) return;
    db.collection('users')
      .where('group', 'array-contains', user.id)
      .get()
      .then(snapshots => {
        let result = [];
        snapshots.forEach(doc => {
          result.push(doc.data());
        });
        setPartner(result);
      });
  }, [db, user]);

  const loadAsk = useCallback(() => {
    if (!user) return;
    db.collection('boards')
      .where('user.userId', '==', user.id)
      .get()
      .then(snapshots => {
        let result = [];
        snapshots.forEach(doc => {
          result.push(doc.data());
        });
        setAsk(result);
      });
  }, [db, user]);

  useEffect(() => {
    if (user && !user.id) return;
    loadStore();
    loadHotel();
    loadPartner();
    loadAsk();
  }, [loadStore, loadHotel, loadPartner, loadAsk, user]);

  return (
    <Container>
      {notice.isLoading && <Loader />}
      {browser.browser.name === 'Internet Explorer' && (
        <div
          className="top-h"
          style={{
            background: '#ffd43b',
            fontSize: '12px'
          }}
        >
          <MdReportProblem /> 이 브라우저는 일부 기능이 동작하지 않을 수 있습니다. 델리퀵
          관리자시스템은 크롬(Chrome)브라우저에 최적화 되어있습니다.
        </div>
      )}
      <div className="top-h">델리퀵 관리자시스템</div>
      {user && (
        <>
          <div className="box-container">
            <div className="box">
              <div className="title">내 정보</div>
              <div className="item">
                <MdLabel className="icon" style={{ marginRight: '0.5rem' }} />
                <span>
                  <b>{user.companyName}</b> | {depthArray[user.depth]}
                </span>
              </div>
              <div className="item">
                <MdEmail className="icon" style={{ marginRight: '0.5rem' }} />
                {user.email}
              </div>
              <div className="item">
                <MdPerson className="icon" style={{ marginRight: '0.5rem' }} />
                {user.name}
              </div>
            </div>

            <div className="box">
              <div className="title">통계</div>
              <div className="item">
                <div className="count">
                  <div className="name">배달업체 수</div>
                  <div className="icon">{store && store.length}</div>
                </div>

                <div className="count">
                  <div className="name">숙박업체 수</div>
                  <div className="icon statistic">{hotel && hotel.length}</div>
                </div>

                {user && user.authLevel === 'NORMAL' && (
                  <div className="count">
                    <div className="name">협력사 수</div>
                    <div className="icon statistic">{partner && partner.length}</div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="box-container">
            <div style={{ minWidth: '59%', minHeight: '300px' }} className="box">
              <div className="title">공지사항</div>
              <div style={{ width: '90%', padding: '0 5%' }}>
                {notice.data && (
                  <Table
                    headers={[
                      {
                        title: '제목',
                        value: 'title',
                        render: row => <Link to={`/board/view/${row.id}`}>{row.title}</Link>,
                        align: 'left',
                        width: '60%'
                      },
                      {
                        title: '작성일',
                        render: row => row.createdAt.toDate().toLocaleDateString(),
                        align: 'center'
                      }
                    ]}
                    notSearch
                    data={sortBy(notice.data, item => item.createdAt.toDate()).reverse()}
                    perPage={5}
                    notPagination
                    index
                  />
                )}
              </div>
            </div>

            {user.authLevel !== 'ROOT' && (
              <div style={{ minWidth: '37%', minHeight: '300px' }} className="box">
                <div className="title">내 문의/요청</div>
                <div className="item">
                  <div className="count" style={{ borderColor: '#999' }}>
                    <div className="name">대기</div>
                    <div className="icon" style={{ color: '#999' }}>
                      {ask && ask.filter(item => item.status === '미처리').length}
                    </div>
                  </div>
                  <div className="count" style={{ borderColor: '#37b24d' }}>
                    <div className="name">처리완료</div>
                    <div className="icon" style={{ color: '#37b24d' }}>
                      {ask && ask.filter(item => item.status === '처리완료').length}
                    </div>
                  </div>
                  <div className="count" style={{ borderColor: '#ff6d72' }}>
                    <div className="name">반려</div>
                    <div className="icon" style={{ color: '#ff6d72' }}>
                      {ask && ask.filter(item => item.status === '반려').length}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </Container>
  );
};

export default Home;
