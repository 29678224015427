import React, { useContext, useState } from 'react';
import { FirebaseContext } from '../../firebase';
import { withRouter } from 'react-router-dom';
import {
  Button,
  Container,
  Div,
  Input,
  Tag,
  Form,
  Modal,
  Notice,
  FileInput,
  Toast as toast
} from '../../components';
import SelectTag from '../7_Tags/SelectTag';
import { uploadFile } from '../../common/fetch';
import { generateKeywords } from '../../common/utils';

const AddDicMenuDB = props => {
  const { db } = useContext(FirebaseContext);
  const [data, setData] = useState(props.data);
  const [isTagOpen, setTagOpen] = useState(false);
  const [file, setFile] = useState(null);

  const onChangeInput = e => {
    setData({
      ...data,
      [e.target.name]: e.target.value
    });
  };

  const onAdd = async () => {
    const photo = await uploadFile('menus', file, 'photo');
    db.collection('menudb')
      .doc()
      .set({ ...data, photo: file ? photo.url : '', keywords: generateKeywords(data.kr) })
      .then(() => {
        toast('업데이트 되었습니다.');
        props.onClose();
      });
  };

  return (
    <>
      <Container type="small" title="시스템메뉴에 추가">
        <Form onSubmit={onAdd}>
          <Input title={'코드'} disabled placeholder="자동생성" />
          <Notice>한국어는 의미단위로 띄어쓰기 후 등록해주세요!</Notice>
          <Input title={'한국어'} onChange={onChangeInput} name="kr" defaultValue={data.kr} />
          <Input title={'로마자'} onChange={onChangeInput} name="roman" />
          <Input title={'영어'} onChange={onChangeInput} name="en" defaultValue={data.en} />
          <Input title={'중국어'} onChange={onChangeInput} name="cn" defaultValue={data.cn} />
          <Input title={'일본어'} onChange={onChangeInput} name="jp" defaultValue={data.jp} />
          <Input title={'태국어'} onChange={onChangeInput} name="th" defaultValue={data.th} />
          <Div title={'대표사진'}>
            <div style={{ marginBottom: '0.5rem' }}>
              <div
                className="photo"
                style={{
                  backgroundColor: '#dfdfdf',
                  backgroundImage: `url(${data && data.photo})`
                }}
              >
                {!data.photo && '사진 없음'}
              </div>
            </div>
            <FileInput
              onChange={e => {
                const file = e.target.files[0];
                if (!file) return;
                if (file.type.split('/')[0] !== 'image')
                  return alert('이미지파일만 업로드 가능합니다.');
                let reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = e => {
                  setData({ ...data, photo: e.target.result });
                  setFile(file);
                };
              }}
            />

            {data.photo && (
              <Button
                type="normal"
                onClick={() => {
                  setData({ ...data, photo: '' });
                }}
              >
                사진삭제
              </Button>
            )}
          </Div>
          <Div title={'태그(설명)'}>
            {data && data.tags && data.tags.map(tag => <Tag id={tag} />)}
          </Div>
          <Button
            style={{
              marginLeft: '120px',
              background: '#339af0'
            }}
            onClick={() => {
              setTagOpen(true);
            }}
          >
            태그등록
          </Button>
        </Form>

        {/* 태그입력용 */}
        {isTagOpen && (
          <Modal
            onClose={() => {
              setTagOpen(false);
            }}
          >
            <SelectTag
              current={[]}
              register={tags => {
                setData({
                  ...data,
                  tags
                });
                setTagOpen(false);
              }}
            />
          </Modal>
        )}
      </Container>
    </>
  );
};

export default withRouter(AddDicMenuDB);
