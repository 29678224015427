import React, { useEffect, useContext, useState } from 'react';
import Table from '../../components/Table/Table';
import Container from '../../components/Container';
import { FirebaseContext } from '../../firebase';
import Button from '../../components/Button';
import { Link } from 'react-router-dom';
import Loader from '../../components/Loader';
import { UserStateContext } from '../../contexts/UserContext';
import { formatPhone } from '../../common/format';
import { Status } from '../../components';
import { MdMap } from 'react-icons/md';

const HotelList = () => {
  const user = useContext(UserStateContext);

  switch (user && user.authLevel) {
    case 'ROOT':
      return <AdminList />;
    case 'NORMAL':
      return <NormalList />;
    default:
      return <Loader />;
  }
};

const headers = [
  { title: '업종', value: 'type', width: '10%' },
  {
    title: '업체명',
    value: 'name',
    render: row => (
      <>
        <Link to={`/hotel/edit/${row.id}`}>{row.name}</Link>
        <br />[{row.id}]
      </>
    )
  },
  {
    title: '전화번호',
    value: 'phone',
    width: '15%',
    render: row => formatPhone(row.phone)
  },
  {
    title: '주소',
    value: 'address'
  },
  {
    title: '협력사명',
    value: 'companyName',
    align: 'center'
  },
  {
    title: '상태',
    value: 'status',
    render: row => <Status msg={row.status} type={row.status === '설치완료' ? 'SUCCESS' : 'WAIT'} />
  }
];

const AdminList = () => {
  const [data, setData] = useState(null);
  const { db } = useContext(FirebaseContext);
  useEffect(() => {
    db.collection('hotels')
      .orderBy('createdAt', 'desc')
      .get()
      .then(snapshots => {
        let result = [];
        snapshots.forEach(doc => {
          result.push({
            ...doc.data(),
            id: doc.id,
            address:
              doc.data().address && `${doc.data().address.road} ${doc.data().address.detail || ''}`
          });
        });
        setData(result);
      });
  }, [db]);

  return (
    <>
      {data ? (
        <Container
          title="숙박업체관리"
          navigator="숙박업체목록"
          buttons={
            <>
              <Button to="/hotel/map" type="normal">
                <MdMap /> <span> 지도검색</span>
              </Button>
              <Button to="/hotel/add" type="normal">
                +업체추가
              </Button>
            </>
          }
        >
          <Table data={data} perPage={30} index headers={headers} />
        </Container>
      ) : (
        <Loader />
      )}
    </>
  );
};

const NormalList = () => {
  const [data, setData] = useState(null);
  const { db } = useContext(FirebaseContext);
  const user = useContext(UserStateContext);

  useEffect(() => {
    db.collection('hotels')
      .where('group', 'array-contains', user.id)
      .get()
      .then(snapshots => {
        let result = [];
        snapshots.forEach(doc => {
          result.push({
            ...doc.data(),
            id: doc.id,
            address:
              doc.data().address && `${doc.data().address.road} ${doc.data().address.detail || ''}`
          });
        });
        setData(result);
      });
  }, [db, user.id]);

  return (
    <>
      {data ? (
        <Container
          title="숙박업체관리"
          navigator="숙박업체목록"
          buttons={
            <Button to="/hotel/add" type="normal">
              +업체추가
            </Button>
          }
        >
          <Table data={data} perPage={30} index headers={headers} />
        </Container>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default HotelList;
