import React from 'react';
import styled, { css } from 'styled-components';

const Container = styled.div`
  ${({ size }) => css`
    width: ${size};
    height: ${size};
  `}
  border-radius: 50%;
  border: 2px solid rgba(255, 255, 255, 0.4);
  border-left-color: #ededed;
  border-bottom-color: #ededed;
  -webkit-animation: spin 1s infinite linear;
  -moz-animation: spin 1s infinite linear;
  -ms-animation: spin 1s infinite linear;
  animation: spin 1s infinite linear;

  @-webkit-keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @-moz-keyframes spin {
    100% {
      -moz-transform: rotate(360deg);
    }
  }
  @-ms-keyframes spin {
    100% {
      -ms-transform: rotate(360deg);
    }
  }
  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
`;

const sizes = {
  small: '20px',
  medium: '24px',
  larger: '30px'
};

function Spinner({ size = 'small' }) {
  return <Container size={sizes[size]} />;
}

export default Spinner;
