import React, { useContext, useState } from 'react';
import { FirebaseContext } from '../../firebase';
import styled from 'styled-components';
import Input from '../../components/Input';
import Button from '../../components/Button';
import Table from '../../components/Table/Table';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
`;

const DbListModal = ({ register }) => {
  const { db } = useContext(FirebaseContext);
  const [data, setData] = useState(null);
  const [keyword, setKeyword] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const search = () => {
    if (!keyword || keyword === '') return;
    setData(null);
    setIsLoading(true);
    db.collection('menudb')
      .where('keywords', 'array-contains', keyword)
      .get()
      .then(snapshots => {
        let result = [];
        // console.log(snapshots);
        snapshots.forEach(doc => result.push({ ...doc.data(), id: doc.id }));
        setData(result);
        setIsLoading(false);
        // console.log(result);
      });
  };

  return (
    <Container>
      <b>메뉴DB검색</b>
      <br />
      <div style={{ display: 'flex' }}>
        <Input
          placeholder="검색할 단어를 입력해주세요."
          onChange={e => setKeyword(e.target.value)}
          style={{ width: '40%' }}
          onKeyDown={e => {
            if (e.keyCode === 13) {
              search();
            }
          }}
        />
        <Button type="search" onClick={search} style={{ width: 'fit-content' }}></Button>
      </div>

      {data && data.length > 0 ? (
        <Table
          notSearch
          data={data}
          perPage={5}
          index
          headers={[
            { title: '한국어', value: 'kr' },
            { title: '영어', value: 'en' },
            { title: '중국어', value: 'cn' },
            { title: '일본어', value: 'jp' },
            { title: '태국어', value: 'th' },
            {
              title: '',
              value: 'id',
              width: 50,
              render: row => (
                <span>
                  <Button
                    type="normal"
                    style={{ fontSize: '12px', padding: 0, margin: 0 }}
                    onClick={() => {
                      register(row.id, row.kr, row.photo || '', row.tags || []);
                    }}
                  >
                    선택
                  </Button>
                </span>
              )
            }
          ]}
          defaultPageSize={10}
        />
      ) : (
        <div
          style={{
            color: '#aaa',
            fontSize: '12px',
            width: '100%',
            textAlign: 'center',
            padding: '4rem 0 '
          }}
        >
          {isLoading ? '검색중...' : '검색 결과 없음'}
        </div>
      )}
    </Container>
  );
};

export default DbListModal;
