import React from 'react';
import ani from '../images/ani.png';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: 100vh;
  z-index: 50;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #aaa;
  font-size: 12px;
  font-weight: bold;
`;

type Props = {
  msg?: string;
};

const Loader = ({ msg }: Props) => {
  return (
    <Container>
      <img src={ani} alt="" style={{ width: '65px' }} />
      <div style={{ marginTop: '0.5rem' }}>{msg}</div>
    </Container>
  );
};

export default Loader;
