import React, { useContext, useState } from 'react';
import { FirebaseContext } from '../../firebase';
import {
  Button,
  Container,
  Div,
  Tag,
  Form,
  Modal,
  FileInput,
  Toast as toast,
  Input,
  Notice,
  Gap,
  Loader
} from '../../components';
import SelectTag from '../7_Tags/SelectTag';
import { uploadFile } from '../../common/fetch';
import { generateKeywords } from '../../common/utils';

function AddImage(props) {
  const { db } = useContext(FirebaseContext);
  const [data, setData] = useState({});
  const [isTagOpen, setTagOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const onAdd = async () => {
    if (!data.photo || data.photo === '') return alert('사진을 추가하세요.');
    setIsLoading(true);
    const photo = await uploadFile('images', file, 'photo');
    db.collection('images')
      .doc()
      .set({
        ...data,
        photo: file ? photo.url : '',
        keywords: generateKeywords(data.desc)
      })
      .then(() => {
        setIsLoading(false);
        toast('업데이트 되었습니다.');
        props.history.push('/images');
      })
      .catch(err => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const onInputChange = ({ target }) => {
    const { name, value } = target;
    setData({ ...data, [name]: value });
  };

  return (
    <>
      {isLoading && <Loader />}
      <Container title="이미지 추가" navigator="이미지관리 > 이미지추가">
        <Form onSubmit={onAdd}>
          <Div title={'이미지'}>
            <div style={{ marginBottom: '0.5rem' }}>
              <div
                className="photo"
                style={{
                  backgroundColor: '#dfdfdf',
                  backgroundImage: `url(${data && data.photo})`
                }}
              >
                {!data.photo && '사진 없음'}
              </div>
            </div>
            <FileInput
              onChange={e => {
                const file = e.target.files[0];
                if (!file) return;
                if (file.type.split('/')[0] !== 'image')
                  return alert('이미지파일만 업로드 가능합니다.');
                let reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = e => {
                  setData({ ...data, photo: e.target.result });
                  setFile(file);
                };
              }}
            />
          </Div>

          <Gap>
            <Input name="desc" title="이미지 설명" onChange={onInputChange} />
            <Notice style={{ marginLeft: '120px' }}>
              띄어쓰기 단위로 키워드 생성되어 검색이 가능합니다.
            </Notice>
          </Gap>

          <Div title={'태그'}>
            {data && data.tags && data.tags.map((tag, key) => <Tag key={key} id={tag} />)}
          </Div>
          <span />
          <Button
            style={{
              marginLeft: '120px',
              background: '#339af0'
            }}
            onClick={() => {
              setTagOpen(true);
            }}
          >
            태그등록
          </Button>
        </Form>

        {/* 태그입력용 */}
        {isTagOpen && (
          <Modal
            onClose={() => {
              setTagOpen(false);
            }}
          >
            <SelectTag
              current={[]}
              register={tags => {
                setData({
                  ...data,
                  tags
                });
                setTagOpen(false);
              }}
            />
          </Modal>
        )}
      </Container>
    </>
  );
}

export default AddImage;
