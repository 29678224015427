import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { MdClose } from 'react-icons/md';
import { useOnClickOutside } from '../common/hooks';

const Container = styled.div`
  position: fixed;
  z-index: 300;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100%);
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  .modal-content {
    background: white;
    width: 90%;
    min-width: ${({ small }) => (small ? '250px' : '380px')};
    min-height: ${({ small }) => (small ? '300px' : '450px')};
    max-height: 80vh;
    padding: 1rem;
    padding-top: 0;
    border-radius: 4px;
    box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.3);
    overflow-y: scroll;
  }
`;

const Modal = ({ children, onClose, small, style }) => {
  const [hover, setHover] = useState(false);
  const ref = useRef();
  useOnClickOutside(ref, onClose);

  return (
    <Container small={small}>
      <div className="modal-content" style={style} ref={ref}>
        <span
          onClick={onClose}
          onMouseOver={() => {
            setHover(true);
          }}
          onMouseOut={() => {
            setHover(false);
          }}
          style={{
            cursor: 'pointer',
            position: 'sticky',
            float: 'right',
            zIndex: 10,
            top: '8px',
            color: hover ? '#ccc' : '#aaa'
          }}
        >
          <MdClose size="20px" />
        </span>
        {children}
      </div>
    </Container>
  );
};

export default Modal;
