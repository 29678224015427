import React, { createContext, useReducer } from 'react';

const UserStateContext = createContext(undefined);
const UserDispatchContext = createContext(undefined);

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_USER':
      return {
        ...state,
        ...action.payload
      };
    case 'CLEAR_USER':
      return {};
    default:
      throw new Error('No action type');
  }
};

export function UserContextProvider({ children }) {
  const [user, dispatch] = useReducer(reducer);

  return (
    <UserDispatchContext.Provider value={dispatch}>
      <UserStateContext.Provider value={user}>{children}</UserStateContext.Provider>
    </UserDispatchContext.Provider>
  );
}

export { UserStateContext, UserDispatchContext };
