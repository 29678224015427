import React, { useEffect, useState, useContext } from 'react';
import Container from '../../components/Container';
import { FirebaseContext } from '../../firebase';
import Table from '../../components/Table/Table';
import Loader from '../../components/Loader';
import { Link } from 'react-router-dom';
import Button from '../../components/Button';
import toast from '../../components/Toast';

const ContentsList = () => {
  const { db } = useContext(FirebaseContext);
  const [data, setData] = useState(null);
  const [checkList, setCheckList] = useState([]);

  const loadData = _ => {
    setData(null);
    db.collection('contents')
      .where('category.main', '==', true)
      .get()
      .then(snapshots => {
        let result = [];
        snapshots.forEach(doc => result.push({ ...doc.data(), id: doc.id }));
        setData(result);
        return result;
      });
  };

  useEffect(() => {
    loadData();
  }, []); // eslint-disable-line

  const activeContent = active => {
    if (checkList.length === 0) return alert('선택항목이 없습니다.');
    let batch = db.batch();

    checkList.forEach(item => {
      const contentsRef = db.collection('contents').doc(item.id);
      batch.update(contentsRef, { active });
    });
    batch
      .commit()
      .then(() => {
        toast('업데이트되었습니다.');
        loadData();
      })
      .catch(err => {
        console.log(err);
      });
  };

  return (
    <Container
      title="컨텐츠관리"
      navigator="컨텐츠목록"
      buttons={
        <>
          <Button
            type="normal"
            style={{ margin: '0 0.25rem' }}
            onClick={() => {
              activeContent(true);
            }}
          >
            선택 활성화
          </Button>
          <Button
            type="normal"
            style={{ margin: '0 0.25rem' }}
            onClick={() => {
              activeContent(false);
            }}
          >
            선택 비활성화
          </Button>

          <Button to="/contents/add" type="normal">
            +컨텐츠추가
          </Button>
        </>
      }
    >
      {data ? (
        <Table
          index
          checkbox
          data={data}
          notSearch
          perPage={15}
          setCheckedList={setCheckList}
          headers={[
            {
              title: '분류',
              value: 'category',
              width: '5.5%',
              align: 'center',
              render: item => (item.category.main ? '메인' : '서브')
            },
            {
              title: '활성여부',
              value: 'active',
              width: '10%',
              align: 'center',
              render: item => (item.active ? 'Y' : 'N')
            },
            {
              title: '컨텐츠명',
              value: 'name',
              render: item => <Link to={`contents/edit/${item.id}`}>{item.name || '?'}</Link>
            },
            {
              title: '등록일',
              value: 'createdAt',
              render: item => item.createdAt && item.createdAt.toDate().toLocaleDateString()
            },
            {
              title: '링크',
              value: 'name',
              render: item => (
                <Button
                  type="small"
                  onClick={() => {
                    window.open(
                      `https://app.deliquick.co.kr/content/${item.id}`,
                      '델리퀵 - 미리보기',
                      'width=375, height=667'
                    );
                    return false;
                  }}
                >
                  미리보기
                </Button>
              )
            }
          ]}
        />
      ) : (
        <Loader />
      )}
    </Container>
  );
};

export default ContentsList;
