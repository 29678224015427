import React, { useContext, useState } from 'react';
import Input from '../../components/Input';
import { FirebaseContext } from '../../firebase';
import { Dropdown, Container, Toast as toast, Div, Form } from '../../components';
import { randomId } from '../../common/format';

const AddCoupon = (props) => {
  const { db } = useContext(FirebaseContext);
  const [data, setData] = useState({
    id: randomId(5, 'A0'),
    isUsed: false,
  });

  const onChangeInput = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const onEdit = async () => {
    const docRef = db.collection('coupons').doc(data.id);
    const isExists = await docRef.get().then((doc) => doc.exists);
    if (isExists) return alert('쿠폰번호 중복에러 발생');
    docRef.set({ ...data }).then(() => {
      toast('업데이트 되었습니다.');
      props.history.push(`/coupons`);
    });
  };

  return (
    <>
      <Container title="쿠폰관리" navigator="쿠폰목록 > 쿠폰추가">
        <Form onSubmit={onEdit} btn="저장">
          <Input defaultValue={data.id} disabled title="쿠폰번호" />
          <Div title="분류" required>
            <Dropdown
              required
              placeholder="분류"
              options={[
                { text: '10% 할인', value: 'percent_10' },
                { text: '15% 할인', value: 'percent_15' },
                { text: '30% 할인', value: 'percent_30' },
                { text: '1000원 할인', value: 'minus_1000' },
                { text: '3000원 할인', value: 'minus_3000' },
              ]}
              style={{ width: '10%', marginRight: '0.2rem' }}
              onChange={onChangeInput}
              name="type"
            />
          </Div>
        </Form>
      </Container>
    </>
  );
};

export default AddCoupon;
