import React, { useContext, useState, useMemo } from 'react';
import styled from 'styled-components';
import { FirebaseContext } from '../../firebase';
import {
  Input,
  Button,
  Modal,
  FileInput,
  Loader,
  Div,
  Tag,
  Notice,
  Gap,
  Container,
  Address,
  Form,
  Textarea,
  Dropdown
} from '../../components';
import WordList from '../6_Word/WordListContainer';
import SelectTag from '../7_Tags/SelectTag';
import toast from '../../components/Toast';
import { getNewCode, uploadFile } from '../../common/fetch';
import { UserStateContext } from '../../contexts/UserContext';
import { Logger } from '../../common/hooks';

const Content = styled.div`
  font-size: 14px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  .title {
    margin-right: 1rem;
    font-weight: 400;
    color: black;
  }
  .main-title {
    color: black;
    font-weight: 600;
    margin-bottom: 1rem;
  }
  .flex {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .photo {
    width: 200px;
    height: 125px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 4px;
  }
  .media-flex {
    display: flex;
    align-items: center;
    @media (max-width: 600px) {
      flex-direction: column;
    }
  }
`;

const AddStore = props => {
  const { db } = useContext(FirebaseContext);
  const user = useContext(UserStateContext);
  const [data, setData] = useState({
    name: '',
    status: '대기',
    active: false,
    averageTime: 0,
    minprice: 0,
    deliveryFee: 0
  });
  const [time, setTime] = useState({
    isAllDayOpen: false,
    open_hour: 0,
    open_minute: 0,
    close_hour: 0,
    close_minute: 0
  });

  const [files, setFiles] = useState([]);
  const [isAddressOpen, setAddressOpen] = useState(false);
  const [isWordOpen, setWordOpen] = useState(false);
  const [isTagOpen, setTagOpen] = useState(false);

  const storeRef = db.collection('stores');

  const [isLoading, setIsLoading] = useState(false);

  const onChangeInput = e => {
    setData({
      ...data,
      [e.target.name]: e.target.value
    });
  };

  const onChangeNumber = e => {
    const value = e.target.value === '' ? '' : parseInt(e.target.value);
    if (Number.isNaN(value) && value !== '') {
      e.target.value = null;
      return alert('숫자만 입력가능합니다.');
    }
    setData({
      ...data,
      [e.target.name]: value
    });
  };

  const getCode = async () => {
    const code = await getNewCode('stores');
    return code;
  };

  const onAdd = async () => {
    setIsLoading(true);
    //const code = await getCode();
    //const store = storeRef.doc(code);
    const code = Math.random().toString(36).substr(2,11);
    const store = storeRef.doc(code);

    const exists = await store.get().then((doc) => {
      return doc.exists;
    });

    if (exists) {
      return alert('코드 중복입니다. 다시 시도해주세요');
    }

    let newData = {
      ...data,
      chargePerson: data.chargePerson || user.name,
      companyName: user.companyName,
      createdAt: new Date(),
      time: `${time.open_hour}:${time.open_minute === 0 ? '00' : time.open_minute}~${
        time.close_hour
      }:${time.close_minute === 0 ? '00' : time.close_minute}`,
      timeData: time,
      group: [...(user.group || []), user.id],
      logs: [new Logger(user, false).toObject()]
    };

    if (files && files.length > 0) {
      const promises = files.map(({ file, name }) => uploadFile(`stores/${code}`, file, name));
      const urls = await Promise.all(promises);
      urls.forEach(item => (newData[item.id] = item.url));
    }

    store
      .set(newData)
      .then(() => {
        setIsLoading(false);
        toast('저장되었습니다.');
        props.history.push('/store');
      })
      .catch(err => console.log(err));
  };

  const showAddress = useMemo(() => {
    if (data && data.address)
      return data && data.address && `${data.address.road || ''} ${data.address.detail || ''}`;
    return null;
  }, [data]);

  const changeHolidays = e => {
    const { value, checked } = e.target;
    const newData = data.holidays || [];
    if (checked) {
      setData({
        ...data,
        holidays: Array.from(new Set(newData.concat(parseInt(value))))
      });
    } else {
      // 삭제
      setData({
        ...data,
        holidays: Array.from(new Set(newData.filter(item => item !== parseInt(value))))
      });
    }
  };

  const onChangeTime = e => {
    setTime({ ...time, [e.target.name]: parseInt(e.target.value) });
  };

  return (
    <>
      <Container title="배달업체관리" navigator="배달업체목록 > 신규업체추가">
        <Content>
          <Form onSubmit={onAdd} requires={['address']} data={data}>
            {user && (
              <>
                <Input
                  disabled
                  title={'협력사명'}
                  name="companyName"
                  defaultValue={user.companyName}
                />
                <Input
                  title={'관리담당자'}
                  defaultValue={user.name}
                  onChange={onChangeInput}
                  name="chargePerson"
                />
              </>
            )}
            <Gap />

            <Gap>
              <Input
                title={'업체명(한국어)'}
                defaultValue={data.realName}
                onChange={onChangeInput}
                name="realName"
                placeholder="지점명포함 예시: 00치킨(강남점)"
                required
              />
              {user && user.authLevel === 'ROOT' && (
                <Input
                  value={data && data.name_id && `${data.name} (${data.name_id})`}
                  title={'선택된번역'}
                  onChange={onChangeInput}
                  name="name"
                  disabled
                  style={{ width: '300px' }}
                >
                  <Button
                    style={{
                      marginLeft: '1rem',
                      background: '#339af0'
                    }}
                    onClick={() => {
                      setWordOpen(true);
                    }}
                  >
                    단어검색
                  </Button>
                </Input>
              )}
            </Gap>
            <Input required title={'전화번호'} onChange={onChangeInput} name="phone" />
            <Input title={'핸드폰번호'} onChange={onChangeInput} name="mobile" />
            <Input title={'대표자명'} onChange={onChangeInput} name="owner" />
            <Input
              title={'사업자번호'}
              name="bn"
              onChange={onChangeInput}
              placeholder={`'-' 포함한 전체 번호`}
            />
            <Gap>
              <Input
                value={(showAddress && showAddress) || ''}
                title={'주소'}
                name="address"
                style={{ width: '60%' }}
                disabled
                required
              >
                <Button
                  type="primary"
                  onClick={() => {
                    setAddressOpen(true);
                  }}
                >
                  주소검색
                </Button>
              </Input>

              <Input
                value={data && data.address && data.address.en}
                title={'영문주소'}
                name="address"
                style={{ width: '60%' }}
                disabled
              />
            </Gap>

            <Div title={'대표사진'}>
              <div style={{ marginBottom: '0.5rem' }}>
                <div
                  className="photo"
                  style={{ backgroundColor: '#dfdfdf', backgroundImage: `url(${data.photo})` }}
                />
              </div>
              <FileInput
                name="photo"
                onChange={e => {
                  const file = e.target.files[0];
                  if (!file) return;
                  if (file.type.split('/')[0] !== 'image')
                    return alert('이미지파일만 업로드 가능합니다.');
                  let reader = new FileReader();
                  reader.readAsDataURL(file);
                  reader.onload = e => {
                    setData({ ...data, photo: e.target.result });
                    setFiles(
                      files.concat({
                        name: 'photo',
                        file
                      })
                    );
                  };
                }}
              />
              <span />
              {data.photo && (
                <Button
                  type="normal"
                  onClick={() => {
                    setData({ ...data, photo: '' });
                  }}
                >
                  사진삭제
                </Button>
              )}
            </Div>
            <Notice style={{ marginLeft: '120px' }}>
              <b>사진 업로드 안내</b> <br />
              5:3 비율을 권장합니다. <br />
              비율이 안맞을 경우 자동으로 중앙정렬해서 보여집니다. <br /> 권장 해상도: 480x288, 권장
              파일 사이즈: 100KB 미만
            </Notice>
            <Div title={'태그(카테고리)'}>
              {data.tags && data.tags.map(tag => <Tag id={tag} />)}
            </Div>
            <span />
            <Button
              style={{
                marginLeft: '120px',
                background: '#339af0'
              }}
              onClick={() => {
                setTagOpen(true);
              }}
            >
              태그등록
            </Button>
            <Notice
              style={{
                marginLeft: '120px'
              }}
            >
              첫번째 선택한 태그가 메인 카테고리로 등록됩니다.
            </Notice>

            <Input
              title={'최소주문금액'}
              required
              onChange={onChangeNumber}
              name="minprice"
              defaultValue={data.minprice}
            />
            <Input
              defaultValue={data.deliveryFee}
              title={'배달비'}
              onChange={onChangeNumber}
              name="deliveryFee"
              required
            />
            <Input
              title={'평균배달시간'}
              onChange={onChangeNumber}
              name="averageTime"
              placeholder={`단위: 분`}
              defaultValue={data.averageTime}
              required
            />

            <Gap>
              <Div title="영업시간" required>
                <div className="media-flex">
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Dropdown
                      options={timeArray.map(item => ({
                        text: item
                      }))}
                      className="small"
                      name="open_hour"
                      onChange={onChangeTime}
                      defaultValue={time.open_hour}
                    />

                    <Dropdown
                      options={[0, 15, 30, 45].map(item => ({
                        text: item
                      }))}
                      name="open_minute"
                      className="small"
                      onChange={onChangeTime}
                      defaultValue={time.open_minute}
                    />
                  </div>
                  <span style={{ margin: '0 0.5rem', fontSize: '18px', fontWeight: 'bold' }}>
                    ~
                  </span>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Dropdown
                      options={timeArray.map(item => ({
                        text: item
                      }))}
                      className="small"
                      name="close_hour"
                      onChange={onChangeTime}
                      defaultValue={time.close_hour}
                    />
                    <Dropdown
                      options={[0, 15, 30, 45].map(item => ({
                        text: item
                      }))}
                      className="small"
                      name="close_minute"
                      onChange={onChangeTime}
                      defaultValue={time.close_minute}
                    />
                  </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginTop: '1rem' }}>
                  <input
                    type="checkbox"
                    onChange={e => {
                      setTime({ ...time, isAllDayOpen: e.target.checked });
                    }}
                    defaultChecked={time.isAllDayOpen}
                  />
                  <div>24시간영업</div>
                </div>
              </Div>
            </Gap>
            <Div title="휴무일" style={{ marginTop: '1rem' }}>
              {['일', '월', '화', '수', '목', '금', '토'].map((item, index) => (
                <React.Fragment key={index}>
                  <input
                    type="checkbox"
                    value={index}
                    defaultChecked={data && data.holidays && data.holidays.includes(index)}
                    onChange={changeHolidays}
                  />
                  <span style={{ marginRight: '0.8rem' }}>{item}</span>
                </React.Fragment>
              ))}
            </Div>
            <Gap>
              <Div title="메뉴판 사진">
                <div>{data[`file-1`]}</div>
                <FileInput
                  accept=".jpg, .png, .pdf, .jpeg, .gif"
                  name="file-1"
                  onChange={e => {
                    const file = e.target.files[0];
                    if (!file) return;
                    if (file.size > 10 * 1024 * 1024) {
                      alert('10MB 이하의 파일만 업로드 가능합니다.');
                      return null;
                    }
                    setData({ ...data, [e.target.name]: file.name });
                    setFiles([
                      ...files,
                      {
                        name: e.target.name,
                        file
                      }
                    ]);
                  }}
                />
              </Div>
            </Gap>
            <Gap>
              <Textarea
                title="메모"
                onChange={e => {
                  setData({ ...data, [e.target.name]: e.target.value.replace(/\n/g, '<br/>') });
                }}
                name="memo"
                placeholder="등록시 전달사항이 있으면 남겨주세요."
              />
            </Gap>
          </Form>
        </Content>

        {isLoading && <Loader />}

        {/* 여기부터 주소입력용 모달 */}
        {isAddressOpen && (
          <Modal
            onClose={() => {
              setAddressOpen(false);
            }}
          >
            <Address setData={setData} setAddressOpen={setAddressOpen} />
          </Modal>
        )}
        {/* 단어입력용 모달 */}
        {isWordOpen && (
          <Modal
            onClose={() => {
              setWordOpen(false);
            }}
          >
            <WordList
              type="업체명"
              register={(name_id, name) => {
                setData({
                  ...data,
                  name_id,
                  name
                });
                setWordOpen(false);
              }}
            />
          </Modal>
        )}
        {/* 태그입력용 */}
        {isTagOpen && (
          <Modal
            onClose={() => {
              setTagOpen(false);
            }}
          >
            <SelectTag
              current={data.tags}
              register={tags => {
                setData({
                  ...data,
                  tags
                });
                setTagOpen(false);
              }}
            />
          </Modal>
        )}
      </Container>
    </>
  );
};

export default AddStore;

const timeArray = [
  0,
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23
];
