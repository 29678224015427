// 사용 카테고리
export const categoryList = [
  '룸서비스',
  '전체',
  '한식',
  '치킨',
  '중식',
  '일식',
  '이탈리안/피자/햄버거',
  '아시안',
  '샐러드',
  '디저트',
  '아메리칸',
  '아프리카',
  '멕시칸',
  '아랍/터키',
  '인도',
  '유럽',
  '도시락',
  '잡화',
];
