import React, { useEffect, useState, useContext, useCallback } from 'react';
import { Container, Button, Input, Loader } from '../../components';
import GridTable from './GridTable';
import { FirebaseContext } from '../../firebase';

function ImageList() {
  const PER_PAGE = 8;
  const [data, setData] = useState([]);
  const { db } = useContext(FirebaseContext);
  const [initialRef] = useState(db.collection('images').limit(PER_PAGE));
  const [ref, setRef] = useState(initialRef);
  const [isLoading, setIsLoading] = useState(false);
  const [isLast, setIsLast] = useState(false);
  const [keywords, setKeywords] = useState();

  const loadData = useCallback(
    reset => {
      setIsLoading(true);
      let settedRef = reset ? initialRef : ref;
      settedRef.get().then(snapshots => {
        let result = [];
        !reset && (result = [...data]);
        snapshots.forEach(doc => result.push({ ...doc.data(), id: doc.id }));
        setData([...result]);
        const lastDoc = snapshots.docs[snapshots.docs.length - 1];
        setIsLast(!lastDoc || snapshots.docs.length < PER_PAGE);
        lastDoc &&
          setRef(
            db
              .collection('images')
              .startAfter(lastDoc)
              .limit(PER_PAGE)
          );
        setIsLoading(false);
      });
    },
    [data, ref, db, initialRef]
  );

  const searchData = useCallback(() => {
    if (!keywords) return loadData(true);
    db.collection('images')
      .where('keywords', 'array-contains', keywords)
      .limit(PER_PAGE)
      .get()
      .then(snapshots => {
        let result = [];
        snapshots.forEach(doc => result.push({ ...doc.data(), id: doc.id }));
        setData([...result]);
        const lastDoc = snapshots.docs[snapshots.docs.length - 1];
        setIsLast(!lastDoc || snapshots.docs.length < PER_PAGE);
        setRef(
          db
            .collection('images')
            .where('keywords', 'array-contains', keywords)
            .startAfter(lastDoc)
            .limit(PER_PAGE)
        );
        setIsLoading(false);
      });
  }, [keywords, db, loadData]);

  // const addFilter = useCallback(filterId => {
  //   setRef(ref => ref.where('tags', 'array-contains', filterId));
  // }, []);
  // // todo: where filter가 중복으로 쌓임

  useEffect(() => {
    loadData();
  }, [db]); // eslint-disable-line

  return (
    <Container
      title="이미지관리"
      navigator="이미지관리 > 이미지목록"
      buttons={
        <Button to="/images/add" type="normal">
          +이미지 추가
        </Button>
      }
    >
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.5rem' }}>
        <Input
          placeholder="검색어입력"
          onChange={e => setKeywords(e.target.value)}
          onKeyDown={e => {
            e.keyCode === 13 && searchData();
          }}
        />
        <Button type="search" onClick={searchData} />
      </div>

      {data && (
        <GridTable
          data={data}
          edit
          addFilter={data => {
            // console.log(data);
          }}
        />
      )}
      <div
        style={{
          width: '100%',
          textAlign: 'center',
          color: '#aaa',
          fontWeight: 'bold',
          marginTop: '1rem',
          padding: '1rem 0'
        }}
      >
        {isLast ? (
          <span>마지막입니다.</span>
        ) : (
          <span
            onClick={() => loadData()}
            style={{
              cursor: 'pointer',
              padding: '0.5em 1.5em',
              borderRadius: '16px',
              border: !isLoading && '1px solid #ccc'
            }}
          >
            {isLoading ? 'loading...' : '더보기...'}
          </span>
        )}
      </div>
      {isLoading && <Loader />}
    </Container>
  );
}

export default ImageList;
