import xlsx from 'xlsx';
import { alert } from '../components/Toast';

export const jsonToSheet = (arr, filename) => {
  if (!arr || arr.length === 0) return alert('데이터가없습니다.');
  const fileName = `${filename}.xlsx`;

  const ws = xlsx.utils.json_to_sheet(arr);
  const wb = xlsx.utils.book_new();
  xlsx.utils.book_append_sheet(wb, ws, 'sheet1');

  xlsx.writeFile(wb, fileName);
};

export const generateKeywords = str => {
  if (!str) return [];
  let keywords = [];
  const arr = str.split(' ');
  for (const a of arr) {
    keywords.push(a);
  }
  let result = [];
  for (let i = 0; i < keywords.length; i++) {
    result.push(`${keywords[i]}`);
    if (i !== keywords.length - 1) {
      result.push(`${keywords[i]} ${keywords[i + 1]}`); // 중간에 공백 포함한 키워드
      result.push(`${keywords[i]}${keywords[i + 1]}`);
    }
  }
  return result;
};
