import React, { useState, useRef, useContext } from 'react';
import styled from 'styled-components';
import View from './View';
import { uniqueId } from 'lodash';
import Sortable from 'react-sortablejs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHeading,
  faCommentAlt,
  faImage,
  faMinus,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
import animateScrollTo from 'animated-scroll-to';
import EditItem from './EditItem';
import { FirebaseContext } from '../../firebase';
import { uploadFile } from '../../common/fetch';
import toast from '../../components/Toast';
import Loader from '../../components/Loader';
import { Gap, Div, Button, Notice, Modal, Tag } from '../../components';
import SelectTag from '../7_Tags/SelectTag';

const Container = styled.div`
  display: flex;
  min-height: 100vh;
  font-size: 14px;
  .button {
    outline: none;
    background: #aaa;
    font-weight: bold;
    color: white;
    border-radius: 8px;
    padding: 0.3rem 0.5rem;
    font-size: 14px;
    width: fit-content;
    cursor: pointer;
    &:hover {
      opacity: 0.9;
    }
  }
  .modal-item {
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 0.5rem;
    margin: 0.5rem 0;
  }
`;
const Editor = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  align-items: center;
  border: 1px solid #efefef;
`;

const IconButton = styled.div`
  border: 1.5px solid #aaa;
  border-radius: 8px;
  width: 50px;
  height: 50px;
  cursor: pointer;
  margin: 0 0.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  color: #aaa;
  font-weight: bold;
  user-select: none;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  &:active {
    box-shadow: 0px 0px 3px 1px #66d9e8;
  }
`;

const AddContents = (props) => {
  const { db } = useContext(FirebaseContext);
  const [data, setData] = useState({
    category: { main: true },
    contents: [],
    bg_img: '',
  });
  const [loading, setLoading] = useState(false);
  const ref = useRef();
  const [tagOpen, setTagOpen] = useState(false);

  // console.log(data);

  const scrollToPosition = (el) => {
    const pos = el.current.node.offsetHeight;
    animateScrollTo(pos + 100, {
      elementToScroll: el.current.node,
      speed: 100,
    });
  };

  const scrollView = (el) => {
    const view = document.getElementById('view-scroll');
    const pos = el.offsetTop;
    const height = el.getBoundingClientRect().height;
    animateScrollTo(pos + height / 2, {
      elementToScroll: view,
      speed: 100,
    });
  };

  const onSubmit = async () => {
    // console.log(data);
    if (!data.name) {
      alert('컨텐츠명을 입력해주세요');
      return null;
    }
    const bg_img = data.bg_img_file
      ? await uploadFile(`contents/${data.name}`, data.bg_img_file)
      : data.bg_img;
    const promises = data.contents.map(
      (item) => item.fileInfo && uploadFile(`contents/${data.name}`, item.fileInfo, item.id)
    );
    const urls = await Promise.all(promises);
    const result = data.contents.map((item) => {
      if (item.type === 'photo') {
        const url = urls
          .filter((url) => url) // undefined 제거
          .filter((url) => url.id === item.id && url);
        let newData = { ...item, photo: url[0].url }; // photo: base64 -> url
        delete newData.fileInfo;
        return newData;
      } else {
        return item;
      }
    });
    let resultData = {
      ...data,
      contents: result,
      bg_img: bg_img.url || '',
      createdAt: new Date(),
    };
    delete resultData.bg_img_file;
    // console.log(resultData);

    return db
      .collection('contents')
      .add(resultData)
      .then(() => {
        toast('저장되었습니다.');
        return true;
      })
      .catch((err) => false);
  };

  return (
    <Container>
      <div
        style={{
          width: '50%',
          display: 'flex',
          marginTop: '1rem',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <View data={data} setData={setData} />
        <Gap />
        <Div>
          <input
            placeholder="컨텐츠명(음식명)"
            style={{
              border: 'none',
              padding: '0.5rem',
              borderBottom: '1px solid #ccc',
              fontSize: '16px',
              fontWeight: 'bold',
              outline: 'none',
            }}
            defaultValue={data.name}
            onChange={(e) => {
              setData({ ...data, name: e.target.value });
            }}
          />
        </Div>

        <Div>{data && data.tags && data.tags.map((tag, key) => <Tag key={key} id={tag} />)}</Div>
        <Button onClick={() => setTagOpen(true)}>태그등록</Button>
        <Notice>해당 태그를 모두 포함한 업체만 하단 DeliQuick Picked에 노출됩니다.</Notice>
        {tagOpen && (
          <Modal
            onClose={() => {
              setTagOpen(false);
            }}
          >
            <SelectTag
              current={data.tags && [...data.tags]}
              register={(tags) => {
                setData({
                  ...data,
                  tags,
                });
                setTagOpen(false);
              }}
            />
          </Modal>
        )}

        <div style={{ fontSize: '12px', color: '#aaa' }}>
          <p>컨텐츠에디터 (Beta v0.1)</p>
        </div>
      </div>

      <Editor>
        <div style={{ marginTop: '1rem', display: 'flex' }}>
          <div
            style={{
              width: '50px',
              height: '50px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <FontAwesomeIcon icon={faPlus} size="2x" color="#aaa" />
          </div>

          <IconButton
            onClick={() => {
              const id = uniqueId(`add_${new Date().getMilliseconds()}_`);
              setData({
                ...data,
                contents: [...data.contents, { text: '', id, type: 'text' }],
              });
              setTimeout(() => {
                scrollToPosition(ref);
              }, 100);
            }}
          >
            <FontAwesomeIcon icon={faCommentAlt} size="2x" color="#aaa" />
            <div style={{ marginTop: '0.25rem' }}>텍스트</div>
          </IconButton>
          <IconButton
            onClick={() => {
              const id = uniqueId(`add_${new Date().getMilliseconds()}_`);
              setData({
                ...data,
                contents: [
                  ...data.contents,
                  {
                    sectionTitle: '',
                    id,
                    type: 'sectionTitle',
                  },
                ],
              });
              setTimeout(() => {
                scrollToPosition(ref);
              }, 100);
            }}
          >
            <FontAwesomeIcon icon={faHeading} size="2x" color="#aaa" />
            <div style={{ marginTop: '0.25rem' }}>중간제목</div>
          </IconButton>
          <IconButton
            onClick={() => {
              const id = uniqueId(`add_${new Date().getMilliseconds()}_`);
              setData({
                ...data,
                contents: [...data.contents, { photo: '', id, type: 'photo' }],
              });
              setTimeout(() => {
                scrollToPosition(ref);
              }, 100);
            }}
          >
            <FontAwesomeIcon icon={faImage} size="2x" color="#aaa" />
            <div style={{ marginTop: '0.25rem' }}>이미지</div>
          </IconButton>
          <IconButton
            onClick={() => {
              const id = uniqueId(`add_${new Date().getMilliseconds()}_`);
              setData({
                ...data,
                contents: [...data.contents, { smallbar: true, id, type: 'smallbar' }],
              });
              setTimeout(() => {
                scrollToPosition(ref);
              }, 100);
            }}
          >
            <FontAwesomeIcon icon={faMinus} size="2x" color="#aaa" />
            <div style={{ marginTop: '0.25rem' }}>분할바</div>
          </IconButton>
        </div>

        <div style={{ margin: '1rem 0', color: '#aaa' }}>
          {data.contents.length > 0
            ? '편집 박스를 드래그하여 순서를 변경할 수 있습니다.'
            : '편집 박스를 추가하여 컨텐츠를 완성하세요.'}
        </div>

        <Sortable
          tag="ul"
          ref={ref}
          options={{
            animation: 300,
          }}
          style={{
            margin: 0,
            minWidth: '332px',
            height: '60vh',
            minHeight: '520px',
            padding: '1rem 16px',
            border: '1.5px solid #efefef',
            borderRadius: '8px',
            overflowY: 'scroll',
          }}
          onChange={(orders) => {
            const result = orders.map(
              (order) => data.contents.filter((item) => order === item.id)[0]
            );

            setData({ ...data, contents: result });
          }}
        >
          {data.contents.map((item) => {
            return (
              <EditItem
                data={item}
                key={item.id}
                setData={(newItem) => {
                  const current = data.contents;
                  const result = current.map((item) => {
                    if (item.id === newItem.id) {
                      return newItem;
                    } else {
                      return item;
                    }
                  });

                  setData({ ...data, contents: result });
                  // data가 추가된 높이만 큼 이동하기 위해
                  setTimeout(() => {
                    const ref = document.getElementById(`view-${item.id}`);
                    scrollView(ref);
                  }, 100);
                }}
                onDelete={({ id }) => {
                  const current = data.contents;
                  const result = current.filter((item) => item.id !== id);
                  setData({ ...data, contents: result });
                }}
              />
            );
          })}
        </Sortable>
        <div style={{ marginTop: '1rem', display: 'flex' }}>
          {/* <button
            className="button"
            onClick={() => {
              if (loading) return;
              setLoading(true);
              onSubmit().then(result => {
                setLoading(!result);
                props.history.push("/contents");
              });
            }}
          >
            중간저장
          </button> */}
          <button
            className="button"
            onClick={() => {
              if (loading) return null;
              setLoading(true);
              onSubmit().then((result) => {
                setLoading(false);
                if (result) {
                  props.history.push('/contents');
                }
              });
            }}
          >
            저장 후 나가기
          </button>
          {loading && <Loader />}
        </div>
      </Editor>
    </Container>
  );
};

export default AddContents;
