import React from 'react';
import styled from 'styled-components';
import downIcon from '../images/down.svg';

const Box = styled.div`
  select {
    outline: none;
    display: flex;
    min-width: 180px;
    font-size: 14px;
    padding: 0.5em 0.5em;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url(${downIcon}) no-repeat 95% 50%;
    text-align: center;
    text-align-last: center;
  }
  select::-ms-expand {
    display: none;
  }
  .small {
    min-width: 65px;
    text-align: left;
    margin: 0 0.25rem;
    padding-left: 0;
  }
`;

const Dropdown = ({ options, defaultValue, onChange, name, style, ...rest }) => {
  return (
    <Box>
      <select name={name} onChange={onChange} defaultValue={defaultValue} style={style} {...rest}>
        {!defaultValue && <option value="">선택하세요</option>}
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.text}
          </option>
        ))}
      </select>
    </Box>
  );
};

export default Dropdown;
