import React from 'react';
import styled from 'styled-components';

const Container = styled.span`
  border: ${({ color }) => `1.5px solid ${color}`};
  color: ${({ color }) => color};
  font-weight: bold;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 4px;
  width: 52px;
  height: 20px;
  border-radius: 4px;
  font-size: 12px;
  @media (max-width: 480px) {
    font-size: 12px;
    font-weight: normal;
    padding: 0;
    border: none;
    width: 10%;
  }
`;

const getColors = (type: string) => {
  switch (type) {
    case 'WAIT':
      return '#999';
    case 'SUCCESS':
      return '#37b24d';
    case 'FAIL':
      return '#ff6d72';
    case 'NORMAL':
      return '#228be6';
    default:
      return '#999';
  }
};

type Props = {
  type?: string;
  msg?: string | 'WAIT' | 'SUCCESS' | 'FAIL';
};

function Status({ type, msg }: Props) {
  if (!msg) return <></>;
  return <Container color={getColors(type)}>{msg}</Container>;
}

export default Status;
