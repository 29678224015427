import React, { useState, useContext } from 'react';
import { Container, Dropdown, Input, Toast as toast, Notice, Form, Div } from '../../components';
import { FirebaseContext } from '../../firebase';

function AddWord() {
  const { db } = useContext(FirebaseContext);
  const [wordData, setWordData] = useState(null);

  const addAndSelect = () => {
    wordData &&
      db
        .collection('words')
        .add(wordData)
        .then(() => {
          toast('추가되었습니다.');
        });
  };

  const changeWordData = e => {
    setWordData({
      ...wordData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <Container title="변역관리" navigator="번역목록 > 번역추가">
      <Form onSubmit={addAndSelect}>
        <Div title="분류">
          <Dropdown
            options={[
              { text: '옵션', value: '옵션' },
              { text: '업체명', value: '업체명' },
              { text: '기타', value: '기타' }
            ]}
            onChange={changeWordData}
            name="type"
            style={{ marginRight: '0.25rem' }}
          />
        </Div>

        <Input title="한국어" required onChange={changeWordData} name="kr" />
        <Input title="영어" required onChange={changeWordData} name="en" />
        <Input title="중국어" onChange={changeWordData} name="cn" />
        <Input title="일본어" onChange={changeWordData} name="jp" />
        <Input title="태국어" onChange={changeWordData} name="th" />
        <Notice>
          단순번역에 사용되는 데이터입니다. (예: 업체명, 옵션명 등) <br /> (업체명은 영어란에
          로마자로 입력하여 사용할것, 디폴트값: 영어){' '}
        </Notice>
      </Form>
    </Container>
  );
}

export default AddWord;
