import React, { useEffect, useContext, useCallback } from 'react';
import styled from 'styled-components';
import { Route, Switch, withRouter } from 'react-router-dom';
import { FirebaseContext } from './firebase';
import { UserDispatchContext } from './contexts/UserContext';
import Header from './layouts/Header';
import Footer from './layouts/Footer';
import Home from './pages/Home';
import Login from './pages/0_Login/Login';
import FindPassword from './pages/0_Login/FindPassword';
import ChangePassword from './pages/0_Login/ChangePassword';
import AddPartner from './pages/1_Partners/AddPartner';
import PartnerList from './pages/1_Partners/PartnerList';
import EditPartner from './pages/1_Partners/EditPartner';
import StoreList from './pages/2_Store/StoreList';
import EditStore from './pages/2_Store/EditStore';
import MenuList from './pages/2_Store/MenuList';
import EditMenu from './pages/2_Store/EditMenu';
import EditOption from './pages/2_Store/EditOption';
import AddStore from './pages/2_Store/AddStore';
import AddOption from './pages/2_Store/AddOption';
import AddMenu from './pages/2_Store/AddMenu';
import AddStoreMenuCsv from './pages/2_Store/AddStoreMenuCsv';
import StoreMapView from './pages/2_Store/StoreMapView';
import HotelList from './pages/3_Hotels/HotelList';
import EditHotel from './pages/3_Hotels/EditHotel';
import AddHotel from './pages/3_Hotels/AddHotel';
import HotelMapView from './pages/3_Hotels/HotelMapView';
import OrderDetail from './pages/4_Order/OrderDetail';
import OrderList from './pages/4_Order/OrderList';
import UserList from './pages/5_User/UserList';
import EditUser from './pages/5_User/EditUser';
import WordList from './pages/6_Word/WordList';
import EditWord from './pages/6_Word/EditWord';
import AddWord from './pages/6_Word/AddWord';
import TagList from './pages/7_Tags/TagList';
import AddTag from './pages/7_Tags/AddTag';
import AddTagByCSV from './pages/7_Tags/AddTagByCSV';
import EditTag from './pages/7_Tags/EditTag';
import MenuDBList from './pages/8_SystemMenu/SystemMenuList';
import AddMenuDB from './pages/8_SystemMenu/AddSystemMenu';
import AddMenuCSV from './pages/8_SystemMenu/AddSystemMenuByCSV';
import EditMenuDb from './pages/8_SystemMenu/EditSystemMenu';
import AddContents from './pages/9_Contents/AddContents';
import ContentsList from './pages/9_Contents/ContentsList';
import EditContents from './pages/9_Contents/EditContents';
import EditTransData from './pages/9_Contents/EditTransData';
import DictionaryList from './pages/10_Dictionary/DictionaryList';
import BoardList from './pages/11_Board/BoardList';
import AddBoard from './pages/11_Board/AddBoard';
import BoardDetail from './pages/11_Board/BoardDetail';
import NoticeList from './pages/12_Notice/NoticeList';
import AddNotice from './pages/12_Notice/AddNotice';
import NoticeDetail from './pages/12_Notice/NoticeDetail';
import EditNotice from './pages/12_Notice/EditNotice';
import Sample from './pages/Sample';
import CopyStore from './pages/2_Store/CopyStore';
import CouponList from './pages/13_Coupons/CouponList';
import { ImageList, AddImage, EditImage } from './pages/9.5_Image';
import AddCoupon from './pages/13_Coupons/AddCoupon';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  .content {
    width: 95%;
    padding: 0 2.5%;
    max-width: 1280px;
    min-height: 70vh;
    padding-bottom: 1rem;
  }
`;

const App = (props) => {
  const path = props.location.pathname.split('/')[1];
  const { db, auth } = useContext(FirebaseContext);
  const dispatch = useContext(UserDispatchContext);

  const setUser = useCallback(
    (user) => {
      dispatch({ type: 'SET_USER', payload: user });
    },
    [dispatch]
  );

  const getUser = useCallback(
    (uid) =>
      db
        .collection('users')
        .doc(uid)
        .get()
        .then((doc) => ({ ...doc.data(), id: doc.id })),
    [db]
  );

  useEffect(() => {
    auth.onAuthStateChanged(async (result) => {
      if (result) {
        const user = await getUser(result.uid);
        if (!user.level || user.level !== 'admin') return auth.signOut();
        if (!user.status && path !== 'login') {
          props.history.push('/login');
          dispatch({ type: 'CLEAR_USER' });
          auth.signOut();
          return setTimeout(() => {
            alert('영업정지된 계정입니다. 관리자에게 문의하세요.');
          }, 300);
        }
        setUser(user);
        if (path === 'login') {
          props.history.push('/');
        }
      } else {
        path !== 'login' && props.history.push('/login');
      }
    });
  }, [auth, path, props.history, getUser, setUser, dispatch]);

  return (
    <>
      <Header />
      <Container>
        <div className="content">
          <Switch>
            <Route exact path="/" component={Home} />

            {/* 1. 협력사관리 */}
            <Route exact path="/partner" component={PartnerList} />
            <Route exact path="/partner/add" component={AddPartner} />
            <Route exact path="/partner/edit/:id" component={EditPartner} />

            {/* 2. 배달업체 */}
            <Route exact path="/store" component={StoreList} />
            <Route exact path="/store/add" component={AddStore} />
            <Route exact path="/store/edit/:id" component={EditStore} />
            <Route exact path="/store/menu/:id" component={MenuList} />
            <Route exact path="/store/addmenu/:id" component={AddMenu} />
            <Route exact path="/store/addcsv" component={AddStoreMenuCsv} />
            <Route exact path="/store/editmenu/:id" component={EditMenu} />
            <Route exact path="/store/option/:id" component={EditOption} />
            <Route exact path="/store/addoption/:id" component={AddOption} />
            <Route exact path="/store/copy/:id" component={CopyStore} />
            <Route exact path="/store/map" component={StoreMapView} />

            {/* 3. 숙박업체 */}
            <Route exact path="/hotel" component={HotelList} />
            <Route exact path="/hotel/edit/:id" component={EditHotel} />
            <Route exact path="/hotel/add" component={AddHotel} />
            <Route exact path="/hotel/map" component={HotelMapView} />

            {/* 4. 주문/정산 */}
            <Route exact path="/order" component={OrderList} />
            <Route exact path="/order/:id" component={OrderDetail} />

            {/* 5. 회원관리 */}
            <Route exact path="/user" component={UserList} />
            <Route exact path="/user/edit/:id" component={EditUser} />

            {/* 6. 번역관리 */}
            <Route exact path="/translation" component={WordList} />
            <Route exact path="/translation/edit/:id" component={EditWord} />
            <Route exact path="/translation/add" component={AddWord} />

            {/* 7. 태그관리 */}
            <Route exact path="/tags" component={TagList} />
            <Route exact path="/tags/edit/:id" component={EditTag} />
            <Route exact path="/tags/add" component={AddTag} />
            <Route exact path="/tags/add/csv" component={AddTagByCSV} />

            {/* 8.시스템메뉴관리 */}
            <Route exact path="/words" component={MenuDBList} />
            <Route exact path="/words/add" component={AddMenuDB} />
            <Route exact path="/words/edit/:id" component={EditMenuDb} />
            <Route exact path="/words/csv" component={AddMenuCSV} />

            {/* 9. 컨텐츠 관리  */}
            <Route exact path="/contents" component={ContentsList} />
            <Route exact path="/contents/add" component={AddContents} />
            <Route exact path="/contents/edit/:id" component={EditContents} />
            <Route exact path="/contents/trans/:id" component={EditTransData} />

            {/* 9.5. 이미지관리 */}
            <Route exact path="/images" component={ImageList} />
            <Route exact path="/images/add" component={AddImage} />
            <Route exact path="/images/edit/:id" component={EditImage} />

            {/* 10. 사전 */}
            <Route exact path="/dictionary" component={DictionaryList} />

            {/* 11. 게시판 */}
            <Route exact path="/board" component={BoardList} />
            <Route exact path="/board/add" component={AddBoard} />
            <Route exact path="/board/view/:id" component={BoardDetail} />

            {/* 12. 공지사항 */}
            <Route exact path="/notice" component={NoticeList} />
            <Route exact path="/notice/add" component={AddNotice} />
            <Route exact path="/notice/view/:id" component={NoticeDetail} />
            <Route exact path="/notice/edit/:id" component={EditNotice} />

            {/* 13. 쿠폰관리 */}
            <Route exact path="/coupons" component={CouponList} />
            <Route exact path="/coupons/add" component={AddCoupon} />

            <Route exact path="/test/sample/" component={Sample} />

            {/* 인증 */}
            <Route exact path="/change" component={ChangePassword} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/login/find" component={FindPassword} />
          </Switch>
        </div>
      </Container>

      <Footer />
    </>
  );
};

export default withRouter(App);
