import React, { useContext, useState, useEffect } from 'react';
import { FirebaseContext } from '../../firebase';
import { uploadFile } from '../../common/fetch';
import { generateKeywords } from '../../common/utils';
import {
  Div,
  Container,
  Form,
  Tag,
  Toast as toast,
  Modal,
  Button,
  Notice,
  Input,
  FileInput
} from '../../components';
import SelectTag from '../7_Tags/SelectTag';

const EditMenuDB = props => {
  const { id } = props.match.params;
  const { db } = useContext(FirebaseContext);
  const [data, setData] = useState([]);
  const [isTagOpen, setTagOpen] = useState(false);
  const [file, setFile] = useState(null);

  useEffect(() => {
    db.collection('menudb')
      .doc(id)
      .get()
      .then(doc => {
        setData(doc.data());
      })
      .catch(err => console.log(err));
  }, [db, id]);

  const onChangeInput = e => {
    setData({
      ...data,
      [e.target.name]: e.target.value
    });
  };

  const onEdit = async () => {
    const photo = await uploadFile('menus', file, 'photo');
    db.collection('menudb')
      .doc(id)
      .update({ ...data, photo: file ? photo.url : '', keywords: generateKeywords(data.kr) })
      .then(() => {
        toast('업데이트 되었습니다.');
        props.history.goBack();
      });
  };

  const onDelete = () => {
    db.collection('menudb')
      .doc(id)
      .delete()
      .then(() => {
        toast('삭제되었습니다.');
        props.history.push(`/words`);
      });
  };

  return (
    <>
      <Container
        title="시스템메뉴관리"
        navigator="시스템메뉴목록 > 시스템메뉴수정"
        buttons={
          <Button type="delete" onClick={onDelete} style={{ color: '#ff3250' }}>
            삭제
          </Button>
        }
      >
        <Form onSubmit={onEdit} btn="저장">
          <Input title="코드" defaultValue={id} disabled />
          <Input
            title={'로마자'}
            onChange={onChangeInput}
            name="roman"
            value={data && data.roman}
            required
          />
          <Input
            title={'한국어'}
            onChange={onChangeInput}
            name="kr"
            value={data && data.kr}
            required
          />
          <Input
            title={'영어'}
            onChange={onChangeInput}
            name="en"
            value={data && data.en}
            required
          />
          <Input title={'중국어'} onChange={onChangeInput} name="cn" value={data && data.cn} />
          <Input title={'일본어'} onChange={onChangeInput} name="jp" value={data && data.jp} />
          <Input title={'태국어'} onChange={onChangeInput} name="th" value={data && data.th} />
          <Div title={'대표사진'}>
            <div style={{ marginBottom: '0.5rem' }}>
              <div
                className="photo"
                style={{
                  backgroundColor: '#dfdfdf',
                  backgroundImage: `url(${data && data.photo})`
                }}
              >
                {data && !data.photo && '사진 없음'}
              </div>
            </div>
            <FileInput
              onChange={e => {
                const file = e.target.files[0];
                if (!file) return;
                if (file.type.split('/')[0] !== 'image')
                  return alert('이미지파일만 업로드 가능합니다.');
                let reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = e => {
                  setData({ ...data, photo: e.target.result });
                  setFile(file);
                };
              }}
            />
            <span />
            <Button
              type="normal"
              onClick={() => {
                setData({ ...data, photo: '' });
              }}
            >
              사진삭제
            </Button>
          </Div>
          <Notice style={{ marginLeft: '120px' }}>
            사진 업로드 안내 <br />
            5:3 비율을 권장합니다. <br />
            비율이 안맞을 경우 자동으로 중앙정렬해서 보여집니다. <br /> 권장 해상도: 480x288, 권장
            파일 사이즈: 100KB 미만
          </Notice>

          <Div title={'태그(설명)'}>
            {data && data.tags && data.tags.map((tag, index) => <Tag id={tag} key={index} />)}
          </Div>
          <span />
          <Button
            style={{
              marginLeft: '120px',
              background: '#339af0'
            }}
            onClick={() => {
              setTagOpen(true);
            }}
          >
            태그등록
          </Button>
        </Form>
        {/* 태그입력용 */}
        {isTagOpen && (
          <Modal
            onClose={() => {
              setTagOpen(false);
            }}
          >
            <SelectTag
              current={data && data.tags}
              register={tags => {
                setData({
                  ...data,
                  tags
                });
                setTagOpen(false);
              }}
            />
          </Modal>
        )}
      </Container>
    </>
  );
};

export default EditMenuDB;
