import React, { useState, useContext } from 'react';
import Input from '../../components/Input';
import { FirebaseContext } from '../../firebase';
import Button from '../../components/Button';
import styled from 'styled-components';

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 70vh;
`;

function FindPassword() {
  const [email, setEmail] = useState('');
  const { auth } = useContext(FirebaseContext);

  const sendEmail = () => {
    auth
      .sendPasswordResetEmail(email)
      .then(function() {
        alert('이메일이 전송되었습니다. ');
      })
      .catch(function(error) {
        // An error happened.
      });
  };

  return (
    <StyledDiv>
      <h3>비밀번호 찾기</h3>
      <Input
        onChange={e => setEmail(e.target.value)}
        placeholder="가입된 이메일 주소"
        style={{ width: '280px', marginBottom: '1.5rem' }}
        type="email"
        name="email"
      />

      <Button onClick={sendEmail} style={{ width: '280px', background: '#ff3250' }}>
        이메일 전송
      </Button>
    </StyledDiv>
  );
}

export default FindPassword;
