import React from 'react';
import styled from 'styled-components';
import { FaInfoCircle } from 'react-icons/fa';

const Container = styled.div`
  border-radius: 4px;
  box-shadow: 0 0 4px 0 #ff3250;
  background: #f1f3f5;
  padding: 0.5rem;
  font-size: 12px;
  margin: 0.5rem 0;
  user-select: none;
  width: fit-content;
  color: #373751;
  display: flex;
`;

const Notice = ({ children, style }) => {
  return (
    <Container style={{ ...style }}>
      <FaInfoCircle color="#ff3250" size="14px" style={{ marginRight: '0.25rem' }} />
      <div>{children}</div>
    </Container>
  );
};

export default Notice;
