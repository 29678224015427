import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import Firebase, { FirebaseContext } from './firebase';
import { UserContextProvider } from './contexts/UserContext';

(function() {
  const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  const s = document.createElement('script');
  const s1 = document.getElementsByTagName('script')[0];
  s.type = 'text/javascript';
  s.async = true;
  s.defer = true;
  s.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places&language=${window.navigator.language}`;

  s1.parentNode.insertBefore(s, s1);
})();

const firebase = new Firebase();
window.firebase = firebase;
ReactDOM.render(
  <FirebaseContext.Provider value={firebase}>
    <UserContextProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </UserContextProvider>
  </FirebaseContext.Provider>,
  document.getElementById('root')
);
