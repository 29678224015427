import { formatDistance, format } from 'date-fns';
import { ko } from 'date-fns/locale';

export const formatPrice = number => {
  return Number(number).toLocaleString('en');
};

export const formatBn = number => {
  return number && number.replace(/(\d{3})(\d{2})(\d{5})/, '$1-$2-$3');
};

export const capitalizeFirstLetter = str => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const possibilities = {
  lowerCased: 'abcdefghijklmnopqrstuvwxyz',
  capitals: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
  numbers: '0123456789',
  special: "~!@#$%^&()_+-={}[];',"
};

export const randomId = (len = 30, pattern = 'aA0') => {
  let chars = '';

  pattern.split('').forEach(a => {
    if (!isNaN(parseInt(a))) {
      chars += possibilities.numbers;
    } else if (/[a-z]/.test(a)) {
      chars += possibilities.lowerCased;
    } else if (/[A-Z]/.test(a)) {
      chars += possibilities.capitals;
    } else {
      chars += possibilities.special;
    }
  });

  let result = '';

  for (let i = 0; i < len; i++) {
    result += chars.charAt(Math.floor(Math.random() * chars.length));
  }

  return result;
};

export const formatSize = size => {
  if (!size) return null;
  const mb = size / 1e6;

  return mb < 1 ? Math.ceil(1000 * mb.toFixed(3)) + 'KB' : mb.toFixed(2) + 'MB';
};

export const dateToWords = date => {
  const time = new Date() - date;
  if (time > 3600000) {
    return format(date, 'yyyy.MM.dd. HH:mm');
  } else {
    return (
      formatDistance(date, new Date(), {
        includeSeconds: true,
        locale: ko
      }) + ' 전'
    );
  }
};

// 공백 제거
export const deleteEmpty = str => {
  return str.replace(/ /gi, '');
};

// 전화번호 - 삭제
export const formatDeleteDash = number => {
  return number.replace(/-/gi, '');
};

export const formatPhone = number => {
  if (number) {
    const noEmptyNum = deleteEmpty(number);
    switch (noEmptyNum.length) {
      case 12:
        return noEmptyNum.replace(/(\d{4})(\d{4})(\d{4})/, '$1-$2-$3');
      case 11:
        return noEmptyNum.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
      case 10:
        return noEmptyNum.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
      case 9:
        return noEmptyNum.replace(/(\d{2})(\d{3})(\d{4})/, '$1-$2-$3');
      default:
        return noEmptyNum;
    }
  }
};
