import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Tag } from '../../components';
// import { Link } from 'react-router-dom';
import { FaEdit } from 'react-icons/fa';

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
  padding: 0.5rem 0;
  @media (max-width: 720px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 480px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const ItemContainer = styled.div`
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 14px;
  max-width: 480px;
  padding: 0.5rem;
  .image-wrapper {
    display: flex;
    align-items: center;
    background: #efefef;
    width: 100%;
    height: 80%;
    cursor: pointer;
  }
  .photo {
    width: 200px;
    min-height: 200px;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 4px;
  }
  border: 1px solid #efefef;
`;

const categories = [
  { name: '전체', id: ['6kMrkmZarK5TFTGY65qF'] },
  { name: '한식', id: ['6kMrkmZarK5TFTGY65qF'] },
  { name: '치킨', id: ['8CW013YW1LNHTxO1sb6J'] },
  { name: '중식', id: ['cOi7Krdq2Gju5zoayLyL'] },
  { name: '일식', id: ['5794zbsABH9roo3mtxZ5'] },
  { name: '피자/햄버거', id: ['pInMwb3XIa1wBiZSoqts', 'OAE9uKqrKagNUkmEMYj5'] },
  { name: '아시안', id: ['opGv38CWW50jB83PUtIK'] },
  { name: '샐러드', id: ['pZGFaAQrYu06QMSySWIc'] },
  { name: '디저트', id: ['vf6RrvlClS2beDWtdadf'] }
];

function GridTable({ data, edit, select, callback, addFilter }) {
  const [category, setCategory] = useState(0);

  const filterCategory = category => {
    if (category === 0) return data;
    let filtered = [];
    data.forEach(item => {
      if (!item.tags) return null;
      const isIncludes =
        categories[category] && categories[category].id.map(id => item.tags.includes(id));
      if (isIncludes.includes(true)) filtered.push(item);
    });

    return filtered;
  };

  return (
    <>
      <div>
        {categories.map((item, key) => (
          <Button
            key={key}
            type="small"
            onClick={() => {
              setCategory(key);
              key !== 0 && addFilter(categories[key].id);
            }}
          >
            {item.name}
          </Button>
        ))}
      </div>
      <GridContainer>
        {data &&
          filterCategory(category).map((item, key) => (
            <ItemContainer key={key}>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={item.photo}
                className="image-wrapper"
              >
                <div
                  className="photo"
                  style={{ width: '100%', backgroundImage: `url(${item.photo})` }}
                />
              </a>
              <div
                style={{
                  padding: '0.5rem 0',
                  display: 'flex'
                }}
              >
                <div
                  style={{
                    marginRight: '0.25rem',
                    fontWeight: 'bold',
                    maxWidth: '160px',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis'
                  }}
                >
                  {item.desc}
                </div>
                {edit && (
                  <Button type="small" to={`/images/edit/${item.id}`}>
                    <FaEdit /> 수정
                  </Button>
                )}
                {select && (
                  <Button type="small" onClick={() => callback(item.photo)}>
                    등록
                  </Button>
                )}
              </div>

              <div style={{ width: '100%' }}>
                {item.tags &&
                  item.tags.map((tag, key) => (
                    <Tag id={tag} key={key} style={{ cursor: 'pointer' }} />
                  ))}
              </div>
            </ItemContainer>
          ))}
      </GridContainer>
    </>
  );
}

export default GridTable;
