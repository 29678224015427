import React, { useState, useCallback, useEffect, useContext } from 'react';
import { FirebaseContext } from '../../firebase';
import Tag from '../../components/Tag';
import Button from '../../components/Button';
import styled from 'styled-components';
import Loader from '../../components/Loader';
import { UserStateContext } from '../../contexts/UserContext';
import { Notice } from '../../components';

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(8, auto);
  grid-row-gap: 10px;
  padding: 0.5rem 0;
  @media (max-width: 720px) {
    grid-template-columns: repeat(6, auto);
  }
  @media (max-width: 480px) {
    grid-template-columns: repeat(4, auto);
  }
`;

const SelectTag = ({ current, register }) => {
  const { db } = useContext(FirebaseContext);
  const [data, setData] = useState(null);

  const user = useContext(UserStateContext);

  const loadData = useCallback(() => {
    db.collection('tags')
      .orderBy('kr')
      .get()
      .then(snapshots => {
        let result = [];
        snapshots.forEach(doc => result.push({ ...doc.data(), id: doc.id }));
        setData(result);
        localStorage.setItem('tags-admin', JSON.stringify({ data: result, createdAt: Date.now() }));
      });
  }, [db]);

  useEffect(() => {
    const cacheData = localStorage.getItem('tags-admin');

    if (!cacheData) {
      loadData();
    } else {
      setData(JSON.parse(cacheData).data);
    }
  }, [loadData]);

  switch (user && user.authLevel) {
    case 'ROOT':
      return (
        <AdminSelect
          data={data}
          current={current}
          register={register}
          setData={setData}
          loadData={loadData}
        />
      );
    case 'NORMAL':
      return (
        <NormalSelect
          data={data}
          current={current}
          register={register}
          setData={setData}
          loadData={loadData}
        />
      );
    default:
      return <Loader />;
  }
};

const AdminSelect = ({ data, current, register, setData, loadData }) => {
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    const newData = [];
    data &&
      current &&
      current.map(tag => data.filter(item => item.id === tag).forEach(item => newData.push(item)));
    setSelected(newData);
  }, [current, data]);

  const addSelect = tag => {
    const flag = selected.filter(item => item.id === tag.id);
    if (flag.length === 0) {
      if (selected.length >= 7) {
        alert('태그는 최대 7개까지 가능합니다.');
      } else {
        setSelected(selected.concat(tag));
      }
    }
  };

  const removeSelect = tag => {
    const newData = selected.filter(item => item.id !== tag.id);
    setSelected();
    setSelected(newData);
  };
  return (
    <>
      {data ? (
        <>
          <div
            style={{
              position: 'sticky',
              top: 0,
              background: '#fff',
              borderBottom: '1px solid #ccc',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              height: '120px'
            }}
          >
            <div style={{ color: 'gray', fontSize: '14px' }}>
              <b>선택한 태그</b>
            </div>
            <div style={{ padding: '1rem' }}>
              <div style={{ display: 'flex' }}>
                {selected &&
                  selected.map((tag, index) => (
                    <div key={`tag-${index}`}>
                      <Tag
                        id={tag.id}
                        key={tag.id}
                        style={{
                          cursor: 'pointer',
                          background: '#ffdeeb',
                          color: '#d6336c',
                          border: '1px solid #faa2c1'
                        }}
                        deleted
                        onClick={() => {
                          removeSelect(tag);
                        }}
                      />
                    </div>
                  ))}
              </div>
            </div>
            <Button
              style={{ width: 'fit-content', marginLeft: '0.5rem' }}
              onClick={() => {
                const ids = selected.map(tag => tag.id);
                register(ids);
              }}
            >
              선택등록
            </Button>
          </div>

          <div
            style={{
              fontSize: '14px',
              padding: '1rem 0',
              position: 'sticky',
              top: '121px',
              background: '#fff'
            }}
          >
            <b>전체태그목록</b>
            <Button
              type="small"
              style={{ marginLeft: '0.5rem' }}
              onClick={() => {
                setData(null);
                loadData();
              }}
            >
              새로고침
            </Button>
          </div>

          <div style={{ padding: '1rem 0', marginTop: '1rem' }}>
            <div>카테고리</div>
            <GridContainer>
              {data &&
                data
                  .filter(tag => tag.type === '카테고리')
                  .map((tag, index) => (
                    <div key={`tag-${index}`}>
                      <Tag
                        id={tag.id}
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          addSelect(tag);
                        }}
                      />
                    </div>
                  ))}
            </GridContainer>
          </div>
          <div style={{ padding: '1rem 0' }}>
            <div>재료</div>
            <GridContainer>
              {data &&
                data
                  .filter(tag => tag.type === '재료')
                  .map((tag, index) => (
                    <div key={`tag-${index}`}>
                      <Tag
                        id={tag.id}
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          addSelect(tag);
                        }}
                      />
                    </div>
                  ))}
            </GridContainer>
          </div>
          <div style={{ padding: '1rem 0' }}>
            <div>맛</div>
            <GridContainer>
              {data &&
                data
                  .filter(tag => tag.type === '맛')
                  .map((tag, index) => (
                    <div key={`tag-${index}`}>
                      <Tag
                        id={tag.id}
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          addSelect(tag);
                        }}
                      />
                    </div>
                  ))}
            </GridContainer>
          </div>
          <div style={{ padding: '1rem 0' }}>
            <div>조리법</div>
            <GridContainer>
              {data &&
                data
                  .filter(tag => tag.type === '조리법')
                  .map((tag, index) => (
                    <div key={`tag-${index}`}>
                      <Tag
                        id={tag.id}
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          addSelect(tag);
                        }}
                      />
                    </div>
                  ))}
            </GridContainer>
          </div>
          <div style={{ padding: '1rem 0' }}>
            <div>소스</div>
            <GridContainer>
              {data &&
                data
                  .filter(tag => tag.type === '소스')
                  .map((tag, index) => (
                    <div key={`tag-${index}`}>
                      <Tag
                        id={tag.id}
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          addSelect(tag);
                        }}
                      />
                    </div>
                  ))}
            </GridContainer>
          </div>
          <div style={{ padding: '1rem 0' }}>
            <div>기타</div>
            <GridContainer>
              {data &&
                data
                  .filter(tag => tag.type === '기타')
                  .map((tag, index) => (
                    <div key={`tag-${index}`}>
                      <Tag
                        id={tag.id}
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          addSelect(tag);
                        }}
                      />
                    </div>
                  ))}
            </GridContainer>
          </div>
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};

const NormalSelect = ({ data, current, register, setData, loadData }) => {
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    const newData = [];
    data &&
      current &&
      current.map(tag => data.filter(item => item.id === tag).forEach(item => newData.push(item)));
    setSelected(newData);
  }, [current, data]);

  const addSelect = tag => {
    const [first, ...exceptFirst] = selected;
    setSelected([tag, ...exceptFirst]);
    return first;
  };

  const categories = ['한식', '치킨', '중식', '일식', '피자', '버거', '아시안', '샐러드', '디저트'];

  return (
    <>
      {data ? (
        <>
          <div
            style={{
              position: 'sticky',
              top: 0,
              background: '#fff',
              borderBottom: '1px solid #ccc',
              marginTop: '-20px',
              padding: '1rem 0'
            }}
          >
            <div style={{ color: 'gray', fontSize: '14px' }}>
              <b>선택한 태그</b>
            </div>
            <div style={{ padding: '1rem' }}>
              <div style={{ display: 'flex' }}>
                {selected &&
                  selected.map((tag, index) => (
                    <div key={`tag-${index}`}>
                      <Tag
                        id={tag.id}
                        key={tag.id}
                        style={{
                          cursor: 'pointer',
                          background: index === 0 ? '#ffdeeb' : '#efefef',
                          color: index === 0 ? '#d6336c' : '#888',
                          border: index === 0 ? '1px solid #faa2c1' : '1px solid #888'
                        }}
                      />
                    </div>
                  ))}
              </div>
            </div>
            <Button
              style={{ width: 'fit-content', marginLeft: '0.5rem' }}
              onClick={() => {
                const ids = selected.map(tag => tag.id);
                register(ids);
              }}
            >
              선택등록
            </Button>
          </div>

          <div
            style={{
              fontSize: '14px',
              marginTop: '1rem',
              paddingTop: '1rem'
            }}
          >
            <span style={{ color: 'gray', fontSize: '14px' }}>카테고리태그</span>
            <Button
              type="small"
              style={{ marginLeft: '0.5rem' }}
              onClick={() => {
                setData(null);
                loadData();
              }}
            >
              새로고침
            </Button>
          </div>

          <div style={{ padding: '1rem 0' }}>
            <GridContainer>
              {data &&
                data
                  .filter(tag => tag.type === '카테고리' && categories.includes(tag.kr))
                  .map((tag, index) => (
                    <div key={`tag-${index}`}>
                      <Tag
                        id={tag.id}
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          addSelect(tag);
                        }}
                      />
                    </div>
                  ))}
            </GridContainer>
          </div>
          <Notice>
            첫번째 태그(메인 카테고리)만 수정 가능합니다. <br />그 외 태그의 수정은 요청게시판에
            남겨주세요.
          </Notice>
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default SelectTag;
