import React from 'react';
import styled, { CSSProperties } from 'styled-components';

const StyledArea = styled.textarea`
  font-size: 14px;
  padding: 0.5rem 1rem;
  border: none;
  outline: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  min-width: 300px;
  min-height: 80px;
  resize: none;
  &:focus {
    text-align: left;
  }
  &:focus,
  :hover {
    box-shadow: 0 0 2px 0.5px #228be6;
  }
  &::placeholder {
    font-size: 12px;
    color: #ccc;
    text-align: center;
  }
`;

type Props = {
  title?: string;
  placeholder?: string;
  defaultValue?: string;
  id?: string;
  name?: string;
  value?: string;
  disabled?: boolean;
  required?: boolean;
  style?: CSSProperties;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent) => void;
};

const Textarea = ({
  title,
  placeholder,
  onChange,
  defaultValue,
  disabled,
  name,
  style,
  value,
  id,
  onKeyDown,
  required
}: Props) => {
  return title ? (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        margin: '0.25rem 0'
      }}
    >
      {title && (
        <div style={{ width: '120px' }}>
          {title}
          {required && <span style={{ marginLeft: '0.25rem', color: '#ff3250' }}>*</span>}
        </div>
      )}
      {
        <StyledArea
          placeholder={placeholder}
          onChange={onChange}
          defaultValue={defaultValue && defaultValue.replace(/<br\/>/g, `\r\n`)}
          disabled={disabled}
          name={name}
          value={value}
          id={id}
          onKeyDown={onKeyDown}
          required={required}
          style={{ ...style }}
        />
      }
    </div>
  ) : (
    <StyledArea
      style={{ ...style }}
      placeholder={placeholder}
      onChange={onChange}
      defaultValue={defaultValue && defaultValue.replace(/<br\/>/g, `\r\n`)}
      disabled={disabled}
      name={name}
      value={value}
      id={id}
      onKeyDown={onKeyDown}
      required={required}
    />
  );
};

export default Textarea;
