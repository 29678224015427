import React, { useContext, useState, useEffect } from 'react';
import Input from '../../components/Input';
import Button from '../../components/Button';
import { FirebaseContext } from '../../firebase';
import toast from '../../components/Toast';
import Dropdown from '../../components/Dropdown';
import { Div, Container, Form } from '../../components';

const EditWord = props => {
  const { id } = props.match.params;
  const { db } = useContext(FirebaseContext);
  const [data, setData] = useState(null);

  useEffect(() => {
    db.collection('words')
      .doc(id)
      .get()
      .then(doc => {
        setData(doc.data());
      })
      .catch(err => console.log(err));
  }, [db, id]);

  const onChangeInput = e => {
    setData({
      ...data,
      [e.target.name]: e.target.value
    });
  };

  const onEdit = () => {
    db.collection('words')
      .doc(id)
      .update({ ...data })
      .then(() => {
        toast('업데이트 되었습니다.');
        props.history.push(`/translation`);
      });
  };

  const onDelete = () => {
    let result = window.confirm('정말 삭제하시겠습니까?');
    if (result) {
      db.collection('words')
        .doc(id)
        .delete()
        .then(() => {
          toast('삭제되었습니다.');
          props.history.push(`/translation`);
        });
    } else {
      return null;
    }
  };

  return (
    <>
      <Container
        title="번역관리"
        navigator="번역수정"
        buttons={
          <Button type="delete" onClick={onDelete} style={{ color: '#ff3250' }}>
            삭제
          </Button>
        }
      >
        {data && (
          <Form onSubmit={onEdit}>
            <Div title="분류" required>
              <Dropdown
                placeholder="분류"
                options={[
                  { text: '옵션', value: '옵션' },
                  { text: '업체명', value: '업체명' },
                  { text: '기타', value: '기타' }
                ]}
                style={{ width: '10%', marginRight: '0.2rem' }}
                onChange={onChangeInput}
                name="type"
                defaultValue={data.type}
              />
            </Div>

            <Input title="한국어" onChange={onChangeInput} name="kr" value={data.kr} required />
            <Input title="영어" onChange={onChangeInput} name="en" value={data.en} required />
            <Input title="중국어" onChange={onChangeInput} name="cn" value={data.cn} />
            <Input title="일본어" onChange={onChangeInput} name="jp" value={data.jp} />
            <Input title="태국어" onChange={onChangeInput} name="th" value={data.th} />
          </Form>
        )}
      </Container>
    </>
  );
};

export default EditWord;
