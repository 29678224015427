import React, { useContext, useState, useEffect, useCallback } from 'react';
import Input from '../../components/Input';
import { FirebaseContext } from '../../firebase';
import styled from 'styled-components';
import toast from '../../components/Toast';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  .modal-item {
    width: 90%;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 0.5rem;
    margin: 0.5rem 0;
  }
  .button {
    outline: none;
    background: #aaa;
    font-weight: bold;
    color: white;
    border-radius: 8px;
    padding: 0.3rem 0.5rem;
    font-size: 14px;
    width: fit-content;
    cursor: pointer;
    &:hover {
      opacity: 0.9;
    }
  }
  textarea {
    outline: none;
    border: 1px solid #aaa;
    border-radius: 8px;
    padding: 0.2rem 0.4rem;
    font-size: 14px;
    width: 90%;
    min-height: 100px;
    margin: 0.25rem 0;
  }
`;

const EditTransData = props => {
  const { db } = useContext(FirebaseContext);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const { id } = props.match.params;

  const onSubmit = useCallback(
    () =>
      db
        .collection('contents')
        .doc(id)
        .update(data)
        .then(() => {
          toast('저장되었습니다.');
        }),
    [db, id, data]
  );

  useEffect(() => {
    db.collection('contents')
      .doc(id)
      .get()
      .then(doc => {
        let result = { ...doc.data() };
        setData(result);
        setLoading(false);
      });
  }, [db, id]);

  useEffect(() => {
    const saveEvent = e => {
      if ((window.navigator.platform.match('Mac') ? e.metaKey : e.ctrlKey) && e.keyCode === 83) {
        e.preventDefault();
        onSubmit();
      }
    };
    document.addEventListener('keydown', saveEvent, false);
    return () => {
      document.removeEventListener('keydown', saveEvent);
    };
  }, [data, onSubmit]);

  return (
    <Container>
      <br />
      <div style={{ width: '90%' }}>
        <b>컨텐츠 번역데이터추가</b>
      </div>
      <br />
      {!loading && (
        <>
          <div className="modal-item">
            <b>썸네일제목(title)</b>
            <Input
              style={{ margin: '0 0.25rem' }}
              placeholder="한국어"
              defaultValue={data.title && data.title.kr}
              onChange={e => {
                setData({
                  ...data,
                  title: { ...data.title, kr: e.target.value }
                });
              }}
            />
            <Input
              style={{ margin: '0 0.25rem' }}
              placeholder="영어"
              defaultValue={data.title && data.title.en}
              onChange={e => {
                setData({
                  ...data,
                  title: { ...data.title, en: e.target.value }
                });
              }}
            />
            <Input
              style={{ margin: '0 0.25rem' }}
              placeholder="중국어"
              defaultValue={data.title && data.title.cn}
              onChange={e => {
                setData({
                  ...data,
                  title: { ...data.title, cn: e.target.value }
                });
              }}
            />
            <Input
              style={{ margin: '0 0.25rem' }}
              placeholder="일본어"
              defaultValue={data.title && data.title.jp}
              onChange={e => {
                setData({
                  ...data,
                  title: { ...data.title, jp: e.target.value }
                });
              }}
            />
          </div>
          <div className="modal-item">
            <b>썸네일부제목(subtitle)</b>
            <Input
              style={{ margin: '0 0.25rem' }}
              placeholder="한국어"
              defaultValue={data.subtitle && data.subtitle.kr}
              onChange={e => {
                setData({
                  ...data,
                  subtitle: { ...data.subtitle, kr: e.target.value }
                });
              }}
            />
            <Input
              style={{ margin: '0 0.25rem' }}
              placeholder="영어"
              defaultValue={data.subtitle && data.subtitle.en}
              onChange={e => {
                setData({
                  ...data,
                  subtitle: { ...data.subtitle, en: e.target.value }
                });
              }}
            />
            <Input
              style={{ margin: '0 0.25rem' }}
              placeholder="중국어"
              defaultValue={data.subtitle && data.subtitle.cn}
              onChange={e => {
                setData({
                  ...data,
                  subtitle: { ...data.subtitle, cn: e.target.value }
                });
              }}
            />
            <Input
              style={{ margin: '0 0.25rem' }}
              placeholder="일본어"
              defaultValue={data.subtitle && data.subtitle.jp}
              onChange={e => {
                setData({
                  ...data,
                  subtitle: { ...data.subtitle, jp: e.target.value }
                });
              }}
            />
          </div>
          <div className="modal-item">
            <b>썸네일설명(comment)</b>
            <Input
              style={{ margin: '0 0.25rem' }}
              placeholder="한국어"
              defaultValue={data.comment && data.comment.kr}
              onChange={e => {
                setData({
                  ...data,
                  comment: { ...data.comment, kr: e.target.value }
                });
              }}
            />

            <Input
              style={{ margin: '0 0.25rem' }}
              placeholder="영어"
              defaultValue={data.comment && data.comment.en}
              onChange={e => {
                setData({
                  ...data,
                  comment: { ...data.comment, en: e.target.value }
                });
              }}
            />
            <Input
              style={{ margin: '0 0.25rem' }}
              placeholder="중국어"
              defaultValue={data.comment && data.comment.cn}
              onChange={e => {
                setData({
                  ...data,
                  comment: { ...data.comment, cn: e.target.value }
                });
              }}
            />
            <Input
              style={{ margin: '0 0.25rem' }}
              placeholder="일본어"
              defaultValue={data.comment && data.comment.jp}
              onChange={e => {
                setData({
                  ...data,
                  comment: { ...data.comment, jp: e.target.value }
                });
              }}
            />
          </div>
          {data.contents &&
            data.contents
              .filter(content => content.type !== 'photo')
              .filter(content => content.type !== 'smallbar')
              .map((item, index) => (
                <div className="modal-item" key={index}>
                  <b>
                    {item.type === 'sectionTitle' && '중간제목'}
                    {item.type === 'text' && '텍스트박스'} [{index + 1}]
                  </b>
                  {item.type === 'text' && (
                    <div
                      style={{
                        borderBottom: '1px solid #ccc',
                        paddingBottom: '0.5rem'
                      }}
                    >
                      <div>텍스트 박스의 제목은 필수 입력이 아닙니다.</div>
                      <Input
                        style={{ margin: '0 0.25rem' }}
                        placeholder="한국어(제목)"
                        defaultValue={item.title && item.title.kr}
                        onChange={e => {
                          const current = data.contents;
                          const result = current.map(curItem => {
                            if (curItem.id === item.id) {
                              return {
                                ...item,
                                title: {
                                  ...item.title,
                                  kr: e.target.value
                                }
                              };
                            } else {
                              return curItem;
                            }
                          });

                          setData({ ...data, contents: result });
                        }}
                      />
                      <Input
                        style={{ margin: '0 0.25rem' }}
                        placeholder="영어(제목)"
                        defaultValue={item.title && item.title.en}
                        onChange={e => {
                          const current = data.contents;
                          const result = current.map(curItem => {
                            if (curItem.id === item.id) {
                              return {
                                ...item,
                                title: {
                                  ...item.title,
                                  en: e.target.value
                                }
                              };
                            } else {
                              return curItem;
                            }
                          });

                          setData({ ...data, contents: result });
                        }}
                      />
                      <Input
                        style={{ margin: '0 0.25rem' }}
                        placeholder="중국어(제목)"
                        defaultValue={item.title && item.title.cn}
                        onChange={e => {
                          const current = data.contents;
                          const result = current.map(curItem => {
                            if (curItem.id === item.id) {
                              return {
                                ...item,
                                title: {
                                  ...item.title,
                                  cn: e.target.value
                                }
                              };
                            } else {
                              return curItem;
                            }
                          });

                          setData({ ...data, contents: result });
                        }}
                      />
                      <Input
                        style={{ margin: '0 0.25rem' }}
                        placeholder="일본어(제목)"
                        defaultValue={item.title && item.title.jp}
                        onChange={e => {
                          const current = data.contents;
                          const result = current.map(curItem => {
                            if (curItem.id === item.id) {
                              return {
                                ...item,
                                title: {
                                  ...item.title,
                                  jp: e.target.value
                                }
                              };
                            } else {
                              return curItem;
                            }
                          });

                          setData({ ...data, contents: result });
                        }}
                      />
                    </div>
                  )}
                  {item.type !== 'text' && (
                    <>
                      <Input
                        style={{ margin: '0 0.25rem' }}
                        placeholder="한국어"
                        defaultValue={item[item.type] && item[item.type].kr}
                        onChange={e => {
                          const current = data.contents;
                          const result = current.map(curItem => {
                            if (curItem.id === item.id) {
                              return {
                                ...item,
                                [item.type]: {
                                  ...item[item.type],
                                  kr: e.target.value
                                }
                              };
                            } else {
                              return curItem;
                            }
                          });

                          setData({ ...data, contents: result });
                        }}
                      />

                      <Input
                        style={{ margin: '0 0.25rem' }}
                        placeholder="영어"
                        defaultValue={item[item.type] && item[item.type].en}
                        onChange={e => {
                          const current = data.contents;
                          const result = current.map(curItem => {
                            if (curItem.id === item.id) {
                              return {
                                ...item,
                                [item.type]: {
                                  ...item[item.type],
                                  en: e.target.value
                                }
                              };
                            } else {
                              return curItem;
                            }
                          });

                          setData({ ...data, contents: result });
                        }}
                      />
                      <Input
                        style={{ margin: '0 0.25rem' }}
                        placeholder="중국어"
                        defaultValue={item[item.type] && item[item.type].cn}
                        onChange={e => {
                          const current = data.contents;
                          const result = current.map(curItem => {
                            if (curItem.id === item.id) {
                              return {
                                ...item,
                                [item.type]: {
                                  ...item[item.type],
                                  cn: e.target.value
                                }
                              };
                            } else {
                              return curItem;
                            }
                          });

                          setData({ ...data, contents: result });
                        }}
                      />
                      <Input
                        style={{ margin: '0 0.25rem' }}
                        placeholder="일본어"
                        defaultValue={item[item.type] && item[item.type].jp}
                        onChange={e => {
                          const current = data.contents;
                          const result = current.map(curItem => {
                            if (curItem.id === item.id) {
                              return {
                                ...item,
                                [item.type]: {
                                  ...item[item.type],
                                  jp: e.target.value
                                }
                              };
                            } else {
                              return curItem;
                            }
                          });

                          setData({ ...data, contents: result });
                        }}
                      />
                    </>
                  )}
                  {item.type === 'text' && (
                    <>
                      <div>
                        <textarea
                          placeholder="한국어(내용)"
                          defaultValue={
                            item.text && item.text.kr && item.text.kr.replace(/<br\/>/g, `\r\n`)
                          }
                          onChange={e => {
                            const current = data.contents;
                            const result = current.map(curItem => {
                              if (curItem.id === item.id) {
                                return {
                                  ...item,
                                  [item.type]: {
                                    ...item[item.type],
                                    kr: e.target.value.replace(/\n/g, '<br/>')
                                  }
                                };
                              } else {
                                return curItem;
                              }
                            });

                            setData({ ...data, contents: result });
                          }}
                        />
                      </div>

                      <textarea
                        placeholder="영어(내용)"
                        defaultValue={item[item.type] && item[item.type].en}
                        onChange={e => {
                          const current = data.contents;
                          const result = current.map(curItem => {
                            if (curItem.id === item.id) {
                              return {
                                ...item,
                                [item.type]: {
                                  ...item[item.type],
                                  en: e.target.value
                                }
                              };
                            } else {
                              return curItem;
                            }
                          });

                          setData({ ...data, contents: result });
                        }}
                      />
                      <textarea
                        placeholder="중국어(내용)"
                        defaultValue={item[item.type] && item[item.type].cn}
                        onChange={e => {
                          const current = data.contents;
                          const result = current.map(curItem => {
                            if (curItem.id === item.id) {
                              return {
                                ...item,
                                [item.type]: {
                                  ...item[item.type],
                                  cn: e.target.value
                                }
                              };
                            } else {
                              return curItem;
                            }
                          });

                          setData({ ...data, contents: result });
                        }}
                      />
                      <textarea
                        placeholder="일본어(내용)"
                        defaultValue={item[item.type] && item[item.type].jp}
                        onChange={e => {
                          const current = data.contents;
                          const result = current.map(curItem => {
                            if (curItem.id === item.id) {
                              return {
                                ...item,
                                [item.type]: {
                                  ...item[item.type],
                                  jp: e.target.value
                                }
                              };
                            } else {
                              return curItem;
                            }
                          });

                          setData({ ...data, contents: result });
                        }}
                      />
                    </>
                  )}
                </div>
              ))}
          <div
            className="button"
            onClick={() => {
              onSubmit();
            }}
          >
            저장
          </div>{' '}
        </>
      )}
    </Container>
  );
};

export default EditTransData;
