import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import Container from '../../components/Container';
import Input from '../../components/Input';
import FileInput from '../../components/FileInput';
import Div from '../../components/Div';
import Button from '../../components/Button';
import toast from '../../components/Toast';
import { uploadFile, useFirebase } from '../../common/fetch';
import { UserStateContext } from '../../contexts/UserContext';
import Dropdown from '../../components/Dropdown';
import Notice from '../../components/Notice';
import Loader from '../../components/Loader';

const StyledTextarea = styled.textarea`
  width: 100%;
  height: 300px;
  border: 1px solid #ccc;
  resize: none;
  margin-top: 1rem;
  border-radius: 4px;
  font-size: 14px;
  outline-color: #228be6;
  line-height: 1.6;
`;

function EditNotice(props) {
  const { id } = props.match.params;
  const [{ data, docRef, setData }] = useFirebase('boards', null, id);
  const user = useContext(UserStateContext);
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const onChangeInput = e => setData({ ...data, [e.target.name]: e.target.value });
  const onChangeContent = e =>
    setData({ ...data, contents: e.target.value.replace(/\n/g, '<br/>') });

  const onEdit = async () => {
    setIsLoading(true);
    let newData = {
      ...data,
      user: {
        name: user.name,
        userId: user.id,
        companyName: user.companyName || ''
      },
      editedAt: new Date(),
      viewCount: 0,
      notice: data.title ? true : false
    };

    if (file) {
      const uploaded = await uploadFile(`boards/${id}`, file, 'fileUrl');
      newData.file = { fileUrl: uploaded.url, fileInfo: { name: file.name, size: file.size } };
    }

    docRef.update(newData).then(_ => {
      toast('업데이트되었습니다.');
      setIsLoading(false);

      props.history.push('/notice');
    });
  };

  useEffect(() => {
    if (user && data) {
      if (user.id !== data.user.userId) {
        alert('수정권한이 없습니다.');
        return props.history.goBack();
      }
    }
  }, [user, props.history, data]);

  return (
    <Container title="공지사항" navigator="게시글 수정">
      {data && (
        <>
          {user && user.authLevel !== 'ROOT' && (
            <Div title="분류">
              <Dropdown
                name="type"
                onChange={onChangeInput}
                defaultValue="메뉴수정"
                options={[
                  {
                    text: '메뉴수정'
                  },
                  {
                    text: '업체이관'
                  },
                  {
                    text: '업체해지'
                  },
                  {
                    text: '기타'
                  }
                ]}
              />
            </Div>
          )}
          {user && user.authLevel === 'ROOT' && (
            <>
              <Input
                title="제목"
                name="title"
                onChange={onChangeInput}
                defaultValue={data.title}
                style={{ width: '250px' }}
              />
              <Notice>제목을 입력하면 공지사항으로 등록됩니다. (관리자전용)</Notice>
            </>
          )}

          <StyledTextarea
            name="contents"
            onChange={onChangeContent}
            defaultValue={data.contents.replace(/<br\/>/g, `\r\n`)}
          />

          <Div title="첨부파일">
            {!file && data.file && (
              <a href={data.file.fileUrl} download target="_blank" rel="noopener noreferrer">
                {data.file.fileInfo.name}
              </a>
            )}

            <FileInput
              onChange={e => {
                const file = e.target.files[0];
                if (!file) return;

                if (file.size > 10485760) {
                  return alert('첨부파일은 10MB 미만으로 가능합니다.');
                }
                setFile(file);
              }}
            />
          </Div>

          {isLoading ? (
            <Loader />
          ) : (
            <Button
              onClick={() => {
                onEdit();
              }}
            >
              저장
            </Button>
          )}
        </>
      )}
    </Container>
  );
}

export default EditNotice;
