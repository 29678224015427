import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import jsonpAdapter from 'axios-jsonp';
import Button from './Button';
import Input from './Input';
import * as geofirex from 'geofirex';
import { FirebaseContext } from '../firebase';
import Notice from './Notice';
import Container from './Container';

const KAKAO_KEY = process.env.REACT_APP_KAKAO_APP_KEY;
const JUSO_KEY = process.env.REACT_APP_JUSO_KEY;

const Step = styled.span`
  border-radius: 8px;
  color: white;
  padding: 0.4em 0.8em;
  width: fit-content;
  text-align: center;
  background: #339af0;
  font-weight: bold;
  margin-right: 1rem;
`;

const Address = ({ setData, setAddressOpen }) => {
  const [keyword, setKeyword] = useState('');
  const [juso, setJuso] = useState([]); // 주소목록
  const [selectedAddress, setSelectedAddress] = useState(null); // 선택한 한글주소
  const [engJuso, setEngJuso] = useState(null); // 영문주소목록
  const [selectedEngAddress, setSelectedEngAddress] = useState(null); // 선택한 영문주소
  const [detail, setDetail] = useState(''); // 상세주소

  const [step, setStep] = useState(1);
  const { app } = useContext(FirebaseContext);
  const geo = geofirex.init(app);

  const getAddress = () => {
    if (keyword) {
      axios({
        method: 'get',
        url: `https://dapi.kakao.com/v2/local/search/address.json?query=${keyword}&size=30`,
        headers: {
          Authorization: `KakaoAK ${KAKAO_KEY}`
        }
      }).then(result => {
        const { documents } = result.data;
        setJuso(documents);
      });
    }
  };

  const getEnAddress = keyword => {
    axios({
      method: 'get',
      url: `https://www.juso.go.kr/addrlink/addrLinkApiJsonp.do?confmKey=${JUSO_KEY}=&keyword=${keyword}&resultType=json&countPerPage=20`,
      adapter: jsonpAdapter
    }).then(result => {
      const arr = result.data.results.juso.map(juso => juso.engAddr);
      setEngJuso(arr);
    });
  };

  const onChangeAddress = e => {
    setKeyword(e.target.value);
  };

  return (
    <Container type="small" title="주소검색">
      <div
        style={{
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <Input
          placeholder="도로명주소, 건물명 혹은 지번주소를 입력해주세요."
          onChange={onChangeAddress}
          name="search"
          style={{ width: '100%' }}
          onKeyDown={e => {
            if (e.target.value && e.keyCode === 13) {
              setStep(1);
              getAddress();
              setSelectedAddress(null);
            }
          }}
        />
        <Button
          onClick={() => {
            setStep(1);
            getAddress();
            setSelectedAddress(null);
          }}
          type="search"
        />
      </div>

      <div
        style={{
          border: '1px solid #ccc',
          borderRadius: '4px',
          marginTop: '1em',
          height: '400px',
          overflowY: 'scroll',
          overflowX: 'hidden',
          padding: '0.5em'
        }}
      >
        <div
          style={{
            margin: '0.25em 0 0.5em 0',
            fontSize: '14px',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Step>{step}단계</Step>
          {step === 1 && '주소 선택'}
          {step === 2 && '영문주소 선택'}
          {step === 3 && (
            <>
              <span>상세주소 입력</span>
              <Notice style={{ marginLeft: 'auto', marginRight: '0.25em' }}>
                상세주소는 한글로 입력해주세요.
              </Notice>
            </>
          )}
        </div>
        {step !== 1 && (
          <div
            style={{
              fontSize: '12px',
              margin: '0.5em 0'
            }}
          >
            <b>선택한주소</b>
            <span style={{ marginLeft: '0.5em' }}>
              {selectedAddress && selectedAddress.address
                ? selectedAddress.address.address_name
                : selectedAddress.road_address.address_name}{' '}
              &nbsp;{detail}
            </span>
          </div>
        )}

        {step === 1 &&
          juso.map((item, index) => (
            <div
              key={index}
              style={{
                fontSize: '12px',
                borderBottom: '1px solid #dfdfdf',
                padding: '0.3em',
                display: 'flex',
                cursor: 'pointer'
              }}
              onClick={() => {
                setSelectedAddress(item);
                getEnAddress(
                  item.address
                    ? item.address.address_name
                    : item.road_address && item.road_address.address_name
                );
                setStep(2);
              }}
            >
              <div style={{ marginRight: '1rem' }}>{index + 1}</div>
              <div>
                <div>{item.address && item.address.address_name}</div>
                <div>{item.road_address && item.road_address.address_name}</div>
              </div>
            </div>
          ))}

        {engJuso && step === 2 && (
          <>
            <h5>▶영문주소 검색결과</h5>
            {engJuso.map((item, index) => (
              <div
                key={index}
                style={{
                  fontSize: '12px',
                  borderBottom: '1px solid #dfdfdf',
                  padding: '0.3rem',
                  display: 'flex',
                  cursor: 'pointer'
                }}
                onClick={() => {
                  setSelectedEngAddress(item);
                  setStep(3);
                }}
              >
                <div style={{ marginRight: '1rem' }}>{index + 1}</div>
                <div>
                  <div>{item}</div>
                </div>
              </div>
            ))}
          </>
        )}

        {step === 3 && (
          <>
            <div>{selectedEngAddress}</div>
            <Input
              placeholder="상세주소"
              style={{ width: '90%', margin: '1rem 0' }}
              onChange={e => {
                setDetail(e.target.value);
              }}
            />
            <br />
            <div style={{ textAlign: 'center' }}>
              <Button
                style={{ width: '50%', margin: '0.25rem 0' }}
                onClick={() => {
                  if (!selectedEngAddress) return alert('영문 주소를 선택하세요.');
                  const extras = selectedAddress.address || selectedAddress.road_address;
                  setData(data => ({
                    ...data,
                    address: {
                      road:
                        (selectedAddress.road_address &&
                          selectedAddress.road_address.address_name) ||
                        '',
                      jibun: selectedAddress.address && selectedAddress.address.address_name,
                      detail: detail,
                      extras,
                      en: selectedEngAddress
                    },
                    position: geo.point(parseFloat(extras.y), parseFloat(extras.x)).data
                  }));
                  setAddressOpen(false);
                }}
              >
                입력
              </Button>
            </div>
          </>
        )}
      </div>
    </Container>
  );
};

export default Address;
