import React, { useContext, useState } from 'react';
import Input from '../../components/Input';
import { FirebaseContext } from '../../firebase';
import { Dropdown, Container, Toast as toast, Div, Form } from '../../components';

const AddTag = props => {
  const { db } = useContext(FirebaseContext);
  const [data, setData] = useState(null);

  const onChangeInput = e => {
    setData({
      ...data,
      [e.target.name]: e.target.value
    });
  };

  const onEdit = () => {
    db.collection('tags')
      .add({ ...data })
      .then(() => {
        toast('업데이트 되었습니다.');
        props.history.push(`/tags`);
      });
  };

  return (
    <>
      <Container title="태그관리" navigator="태그목록 > 태그추가">
        <Form onSubmit={onEdit} btn="저장">
          <Div title="분류" required>
            <Dropdown
              required
              placeholder="분류"
              options={[
                { text: '카테고리', value: '카테고리' },
                { text: '맛', value: '맛' },
                { text: '재료', value: '재료' },
                { text: '조리법', value: '조리법' },
                { text: '소스', value: '소스' },
                { text: '기타', value: '기타' }
              ]}
              style={{ width: '10%', marginRight: '0.2rem' }}
              onChange={onChangeInput}
              name="type"
            />
          </Div>

          <Input title={'한국어'} required onChange={onChangeInput} name="kr" />
          <Input title={'영어'} required onChange={onChangeInput} name="en" />
          <Input title={'중국어'} required onChange={onChangeInput} name="cn" />
          <Input title={'일본어'} required onChange={onChangeInput} name="jp" />
          <Input title={'태국어'} required onChange={onChangeInput} name="th" />
        </Form>
      </Container>
    </>
  );
};

export default AddTag;
