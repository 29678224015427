import React from 'react';
import styled from 'styled-components';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';

const Container = styled.div`
  display: flex;
  font-size: 13px;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  user-select: none;
  color: #aaa;
  .icon {
    cursor: pointer;
  }
`;

type PageProps = {
  active?: boolean;
};

const Page = styled.div<PageProps>`
  margin: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  font-size: 15px;
  cursor: pointer;
  color: ${({ theme, active }) => active && theme.mainColor};
`;

type Props = {
  lastPage: number;
  page: number;
  onPrev: () => void;
  onNext: () => void;
  onClickPage: (page: number) => void;
};

function Pagination({ lastPage, page, onPrev, onNext, onClickPage }: Props) {
  return (
    <Container style={{ marginTop: '1rem' }}>
      <MdChevronLeft className="icon" size="20px" onClick={onPrev} />
      <Container style={{ width: '200px' }}>
        {/* to prev */}
        {page > 2 && <Page onClick={() => onClickPage(1)}>1</Page>}
        {page > 3 && <span>…</span>}
        {page !== 1 && <Page onClick={() => onClickPage(page - 1)}>{page - 1}</Page>}

        {/* current */}
        <Page active>{page}</Page>

        {/* to next */}
        {lastPage !== 0 && page !== lastPage && (
          <Page onClick={() => onClickPage(page + 1)}>{page + 1}</Page>
        )}
        {page < lastPage - 2 && <span>…</span>}
        {page < lastPage - 1 && <Page onClick={() => onClickPage(lastPage)}>{lastPage}</Page>}
      </Container>
      <MdChevronRight className="icon" size="20px" onClick={onNext} />
    </Container>
  );
}

export default Pagination;
