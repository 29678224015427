import React, { useEffect, useState, useContext } from 'react';
import Modal from '../../components/Modal';
import { FirebaseContext } from '../../firebase';
import Table from '../../components/Table/Table';
import Button from '../../components/Button';
import toast, { alert } from '../../components/Toast';

const AddServiceStore = ({ onClose, load, hotelId }) => {
  const { db } = useContext(FirebaseContext);
  const [data, setData] = useState(null);
  const [checkedList, setCheckedList] = useState([]);
  const [currentList, setCurrentList] = useState();
  const [currentCheckedList, setCurrentCheckedList] = useState([]);

  useEffect(() => {
    if (load === true) {
      const storeRef = db.collection('stores');
      storeRef
        .orderBy('createdAt', 'desc')
        .get()
        .then(snapshots => {
          let result = [];
          snapshots.forEach(doc =>
            result.push({ ...doc.data(), id: doc.id, address: doc.data().address.road })
          );
          setData(result);
        });

      storeRef
        .where(`area.${hotelId}`, '==', true)
        .get()
        .then(snapshots => {
          let result = [];
          snapshots.forEach(doc =>
            result.push({ ...doc.data(), id: doc.id, address: doc.data().address.road })
          );
          setCurrentList(result);
        });
    }
  }, [db, hotelId, load]);

  const onDelete = () => {
    if (currentCheckedList.length === 0) return alert('선택항목이 없습니다.');

    let batch = db.batch();
    currentCheckedList.forEach(item => {
      const ref = db.collection('stores').doc(item.id);
      batch.update(ref, {
        area: { ...item.area, [hotelId]: false }
      });
    });
    batch
      .commit()
      .then(() => {
        toast('업데이트 되었습니다.');
        return onClose();
      })
      .catch(err => {
        console.log(err);
        alert('failed');
      });
  };

  const onSubmit = () => {
    if (checkedList.length === 0) return alert('선택항목이 없습니다.');

    let batch = db.batch();
    checkedList.forEach(item => {
      const ref = db.collection('stores').doc(item.id);
      batch.update(ref, {
        area: { ...item.area, [hotelId]: true }
      });
    });
    batch
      .commit()
      .then(() => {
        toast('업데이트 되었습니다.');
        return onClose();
      })
      .catch(err => {
        console.log(err);
        alert('failed');
      });
  };

  return (
    <Modal onClose={onClose} style={{ width: '70%' }}>
      <div style={{ position: 'relative', top: 0 }}>
        <h4>서비스업체등록</h4>
      </div>
      {data && currentList && (
        <>
          <b style={{ color: '#aaa' }}>1. 현재등록된업체</b>
          <Table
            perPage={10}
            data={currentList}
            checkbox
            setCheckedList={setCurrentCheckedList}
            notSearch
            headers={[
              {
                title: '업체명',
                value: 'realName'
              },
              { title: '주소', value: 'address' }
            ]}
          />
          <hr style={{ border: '1px solid #ccc' }} />
          <div style={{ display: 'flex' }}>
            <Button
              type="normal"
              onClick={onSubmit}
              style={{ marginRight: '0.5rem', color: '#339af0' }}
            >
              ↑선택등록
            </Button>
            <Button
              type="normal"
              onClick={onDelete}
              style={{ marginRight: '0.5rem', color: '#ff3250' }}
            >
              ↓선택제외
            </Button>
          </div>
          <br />
          <b style={{ color: '#aaa' }}>2. 전체업체목록</b>
          <Table
            perPage={10}
            data={data}
            checkbox
            setCheckedList={setCheckedList}
            headers={[
              {
                title: '업체명',
                value: 'realName'
              },
              { title: '주소', value: 'address' }
            ]}
          />
        </>
      )}
    </Modal>
  );
};

export default AddServiceStore;
