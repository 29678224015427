import React, { useContext, useState } from 'react';
import csv2json from 'csvjson-csv2json';
import Container from '../../components/Container';
import FileInput from '../../components/FileInput';
import Button from '../../components/Button';
import { FirebaseContext } from '../../firebase';
import toast from '../../components/Toast';

const AddSystemMenuByCSV = () => {
  const { db } = useContext(FirebaseContext);
  const [uploadData, setUploadData] = useState([]);

  const generateKeywords = str => {
    let keywords = [];
    const arr = str.split(' ');
    for (const a of arr) {
      keywords.push(a);
    }
    let result = [];
    for (let i = 0; i < keywords.length; i++) {
      result.push(`${keywords[i]}`);
      if (i !== keywords.length - 1) {
        result.push(`${keywords[i]} ${keywords[i + 1]}`); // 중간에 공백 포함한 키워드
        result.push(`${keywords[i]}${keywords[i + 1]}`);
      }
    }
    return result;
  };

  const fileUpload = e => {
    const file = e.target.files[0];
    if (file && file.type !== 'text/csv') {
      alert('.csv 파일만 업로드 가능합니다.');
      return null;
    }

    const readFileContent = file => {
      const reader = new FileReader();
      return new Promise((resolve, reject) => {
        reader.onload = e => resolve(e.target.result);
        reader.onerror = err => reject(err);
        reader.readAsText(file);
      });
    };
    let data = [];
    readFileContent(file).then(csv => {
      csv2json(csv).map(item =>
        data.push({
          ...item,
          tags: item.tags ? item.tags.split(',').filter(tag => tag !== '#N/A') : '',
          keywords: generateKeywords(item.kr)
        })
      );
      console.log(data);

      setUploadData(data);
    });
  };
  const submitData = () => {
    const batch = db.batch();
    uploadData.forEach(item => {
      if (item.th === '#N/A') return null;
      const ref = db.collection('menudb').doc();
      return batch.set(ref, item);
    });
    batch
      .commit()
      .then(() => toast('업데이트 되었습니다.'))
      .catch(err => {
        console.log(err);
        toast('failed');
      });
  };

  return (
    <Container>
      <b>메뉴DB일괄 업로드</b>
      <p>.csv 파일 이용하여 업로드 가능</p>
      <div>사용법 숙지 후 사용할것!</div>
      <FileInput onChange={fileUpload} name="xlsx" />
      <Button onClick={submitData} type="primary">
        업로드하기
      </Button>
    </Container>
  );
};

export default AddSystemMenuByCSV;
