import React, { useContext, useState, useEffect } from 'react';
import { FirebaseContext } from '../../firebase';
import styled from 'styled-components';
import Button from '../../components/Button';
import Loader from '../../components/Loader';
import Table from '../../components/Table/Table';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  min-width: 400px;
`;

const WordListContainer = ({ register, type }) => {
  const { db } = useContext(FirebaseContext);
  const [data, setData] = useState(null);
  // const [wordData, setWordData] = useState({ type: type || '기타' });

  useEffect(() => {
    if (type) {
      db.collection('words')
        .where('type', '==', type)
        .get()
        .then(snapshots => {
          let result = [];
          snapshots.forEach(doc => result.push({ ...doc.data(), id: doc.id }));
          setData(result);
        });
    } else {
      db.collection('words')
        .get()
        .then(snapshots => {
          let result = [];
          snapshots.forEach(doc => result.push({ ...doc.data(), id: doc.id }));
          setData(result);
        });
    }
  }, [db, type]);

  return (
    <Container>
      <b>단어DB검색</b>

      {data ? (
        <Table
          data={data}
          index
          searchStartOpen
          perPage={10}
          headers={[
            { title: '한국어', value: 'kr' },
            { title: '영어', value: 'en' },
            { title: '중국어', value: 'cn' },
            { title: '일본어', value: 'jp' },
            { title: '태국어', value: 'th' },
            {
              title: '',
              value: 'id',
              width: 50,
              notSearch: true,
              render: row => (
                <span>
                  <Button
                    type="small"
                    onClick={() => {
                      register(row.id, row.kr);
                    }}
                  >
                    선택
                  </Button>
                </span>
              )
            }
          ]}
          defaultPageSize={5}
        />
      ) : (
        <Loader />
      )}
    </Container>
  );
};

export default WordListContainer;
