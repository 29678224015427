import { sortBy } from 'lodash';
import React, { useContext, useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { formatPrice } from '../../common/format';
import Button from '../../components/Button';
import Container from '../../components/Container';
import Input from '../../components/Input';
import Modal from '../../components/Modal';
import Notice from '../../components/Notice';
import toast from '../../components/Toast';
import { FirebaseContext } from '../../firebase';
import Loader from '../../components/Loader';
import WordListContainer from '../6_Word/WordListContainer';
import { UserStateContext } from '../../contexts/UserContext';

const MenuList = (props) => {
  const { db } = useContext(FirebaseContext);
  const user = useContext(UserStateContext);
  const { id } = props.match.params;
  const [data, setData] = useState(null);
  const [storeData, setStoreData] = useState(null);
  const [updateList, setUpdateList] = useState([]); // 저장할때 업데이트할 (값이 변한) 목록
  const [modalOpen, setModalOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [wordOpen, setWordOpen] = useState(false);

  const loadData = useCallback(async () => {
    const storeData = await db
      .collection('stores')
      .doc(id)
      .get()
      .then((doc) => ({ ...doc.data(), id: doc.id }));

    const data = await db
      .collection('menues')
      .where('store_id', '==', id)
      .get()
      .then((snapshots) => {
        let result = [];
        snapshots.forEach((doc) => result.push({ ...doc.data(), id: doc.id }));
        return result;
      });

    setStoreData(storeData);
    setData(data);
  }, [db, id]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const updateChangeData = () => {
    if (updateList.length === 0) return alert('변경사항이 없습니다.');
    let batch = db.batch();
    updateList.forEach((item) => {
      const { category, index } = item;
      batch.update(db.collection('menues').doc(item.id), { category, index });
    });
    batch.commit().then(() => {
      toast('업데이트 되었습니다.');
      setUpdateList([]);
    });
  };

  const exceptToCategory = (menu) => {
    const myCategory = data.filter((item) => item.category === menu.category);
    const changeOtherAboveData = myCategory
      .filter((item) => item.index > menu.index)
      .map((item) => {
        return { ...item, index: item.index - 1 };
      });

    const newUpdatelist = [{ ...menu, category: '' }, ...changeOtherAboveData];

    let batch = db.batch();
    newUpdatelist.forEach((item) => {
      const { category, index } = item;
      batch.update(db.collection('menues').doc(item.id), {
        category: category || '',
        index: index || 0,
      });
    });
    batch.commit().then(() => {
      toast('업데이트 되었습니다.');
      loadData();
    });
  };

  const changeIndex = (menu, type) => {
    if (type === 'up') {
      if (menu.index === 1) return;
      const prevMenu = data.filter(
        (item) => item.category === menu.category && item.index === menu.index - 1
      )[0];

      const currentUpdate = { ...menu, index: menu.index - 1 };
      const prevUpdate = { ...prevMenu, index: prevMenu.index + 1 };
      const newData = data
        .filter(
          (item) =>
            item.category === menu.category &&
            item.index !== menu.index &&
            item.index !== menu.index - 1
        )
        .concat(currentUpdate)
        .concat(prevUpdate);

      setUpdateList(
        updateList
          .filter((item) => currentUpdate.id !== item.id)
          .filter((item) => prevUpdate.id !== item.id)
          .concat(currentUpdate)
          .concat(prevUpdate)
      );

      const otherCategoriesData = data.filter((item) => item.category !== menu.category);

      // console.log([...otherCategoriesData, ...newData]);
      setData([...otherCategoriesData, ...newData]);
    } else {
      // 아래로
      const length = data.filter((item) => item.category === menu.category).length;
      if (menu.index === length) return null;
      const nextMenu = data.filter(
        (item) => item.category === menu.category && item.index === menu.index + 1
      )[0];

      const nextUpdate = { ...nextMenu, index: nextMenu.index - 1 };
      const currentUpdate = { ...menu, index: menu.index + 1 };
      const newData = data
        .filter(
          (item) =>
            item.category === menu.category &&
            item.index !== menu.index &&
            item.index !== menu.index + 1
        )
        .concat(currentUpdate)
        .concat(nextUpdate);

      setUpdateList([...updateList, currentUpdate, nextUpdate]);

      setUpdateList(
        updateList
          .filter((item) => currentUpdate.id !== item.id)
          .filter((item) => nextUpdate.id !== item.id)
          .concat(currentUpdate)
          .concat(nextUpdate)
      );

      const otherCategoriesData = data.filter((item) => item.category !== menu.category);

      // console.log([...otherCategoriesData, ...newData]);
      setData([...otherCategoriesData, ...newData]);
    }
  };

  const deleteCategory = (category) => {
    let result = window.confirm('정말 삭제하시겠습니까?');
    if (!result) return;
    db.collection('stores')
      .doc(id)
      .update({
        ...storeData,
        menuCategories: [...storeData.menuCategories.filter((item) => item.name !== category.name)],
      })
      .then(() => {
        toast('업데이트되었습니다.');
        db.collection('stores')
          .doc(id)
          .get()
          .then((doc) => setStoreData(doc.data()));
      });
  };

  const onDeleteOneMenu = (menu) => {
    const result = window.confirm('정말삭제하시겠습니까?');
    if (result) {
      db.collection('menues')
        .doc(menu.id)
        .delete()
        .then(() => {
          toast('삭제되었습니다.');
          loadData();
        });

      db.collection('options')
        .where('menu_id', '==', menu.id)
        .get()
        .then((snapshots) => {
          if (snapshots.empty) return console.log('옵션없음');
          const id = snapshots.docs[0].id;
          db.collection('options')
            .doc(id)
            .delete()
            .then((_) => console.log('success'));
        });
    }
  };

  const checkInclude = (name) => {
    const categoriesArr = (storeData.menuCategories || []).map((item) => item.name);
    return categoriesArr.includes(name);
  };

  return (
    <Container
      title={storeData && storeData.realName}
      navigator="배달업체목록 > 업체정보수정 > 업체메뉴관리"
      buttons={
        <>
          {user && user.authLevel === 'ROOT' && (
            <Button to={`/store/copy/${id}`} type="normal">
              업체메뉴복사
            </Button>
          )}
          <Button to={`/store/addmenu/${id}`} type="normal">
            +단일메뉴추가
          </Button>
        </>
      }
    >
      <div>
        <b>메뉴 - 카테고리 목록 </b>
        <br />
        <div style={{ display: 'flex', alignItems: 'center', margin: '0.5rem 0', width: '100%' }}>
          <Notice>
            카테고리가 지정된 메뉴만 노출됩니다. <br />
            순서변경이 끝난 후에는 반드시 <b>변경사항저장</b> 버튼을 눌러주세요.
          </Notice>
          <div style={{ marginLeft: 'auto' }}>
            <Button type="primary" onClick={updateChangeData}>
              변경사항저장
            </Button>
            <Button
              type="normal"
              onClick={() => {
                setModalOpen('editCategory');
              }}
            >
              +카테고리추가
            </Button>
            <Button
              type="normal"
              onClick={() => {
                setModalOpen('changeCategory');
              }}
            >
              카테고리 순서변경
            </Button>
          </div>
        </div>

        {!storeData && !data && <Loader />}
        {storeData &&
          data &&
          storeData.menuCategories &&
          storeData.menuCategories.map((item, cateIndex) => (
            <div
              key={`${cateIndex}-mc`}
              style={{
                border: '1px solid #ccc',
                margin: '0.5rem 0',
                padding: '0.5rem',
                borderRadius: '4px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  padding: '0.8rem 0',
                  alignItems: 'center',
                }}
              >
                <span style={{ marginRight: '0.5rem', fontSize: '12px' }}>카테고리명</span>
                <b>{item.name}</b>
                <span style={{ marginLeft: 'auto', marginRight: '0.5rem', fontSize: '12px' }}>
                  카테고리메뉴
                </span>
                <Button
                  type="small"
                  onClick={() => {
                    setModalOpen(cateIndex);
                  }}
                >
                  +메뉴 추가
                </Button>

                <Button
                  type="small"
                  onClick={() => {
                    setEditOpen(cateIndex);
                  }}
                >
                  수정
                </Button>

                <Button
                  type="small"
                  style={{ color: '#ff3250' }}
                  onClick={() => {
                    deleteCategory(item);
                  }}
                >
                  삭제
                </Button>
              </div>

              <table
                style={{
                  width: '100%',
                  borderCollapse: 'collapse',
                  border: '1px solid #ccc',
                  textAlign: 'center',
                }}
              >
                <thead>
                  <tr
                    style={{
                      border: '1px solid #ccc',
                      background: '#e6e6e6',
                    }}
                  >
                    <th style={{ padding: '0.5rem 0' }}>순서</th>
                    <th>메뉴명</th>
                    <th>가격(원)</th>
                    <th>매칭</th>
                    <th>태그수</th>
                    <th>사진</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {sortBy(
                    data.filter((menu) => item.name === menu.category),
                    ['index']
                  ).map((menu, index) => (
                    <tr key={`tr-${cateIndex}-${index}`} style={{ border: '1px solid #ccc' }}>
                      <td style={{ fontSize: '12px' }}>{menu.index}</td>
                      <td style={{ width: '40%', padding: '0.4em 0' }}>
                        <Link to={`/store/editmenu/${menu.id}`}>{menu.realName}</Link>
                      </td>
                      {/* <EditablePrice value={menu.price} id={menu.id} onChange={onChangeInput} /> */}
                      <td>{formatPrice(menu.price)}</td>
                      <td>{menu.name_id ? 'Y' : 'N'}</td>
                      <td>{menu.tags && menu.tags.length}</td>
                      <td>{menu.photo ? 'Y' : 'N'}</td>
                      <td style={{ textAlign: 'right', paddingRight: '0.5rem' }}>
                        <Button
                          type="small"
                          onClick={() => {
                            changeIndex(menu, 'up');
                          }}
                        >
                          ↑
                        </Button>
                        <Button
                          type="small"
                          onClick={() => {
                            changeIndex(menu, 'down');
                          }}
                        >
                          ↓
                        </Button>
                        <Button
                          type="small"
                          onClick={() => {
                            exceptToCategory(menu);
                          }}
                        >
                          ―
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              {modalOpen === cateIndex && (
                <AddCategoryItem
                  onClose={() => {
                    setModalOpen(null);
                  }}
                  item={item}
                  data={data}
                  reload={loadData}
                  setData={setData}
                  lastIndex={data.filter((menu) => item.name === menu.category).length + 1}
                  checkInclude={checkInclude}
                />
              )}
              {editOpen === cateIndex && (
                <EditCategoryItem
                  item={item}
                  cateIndex={cateIndex}
                  storeData={storeData}
                  loadData={loadData}
                  onClose={() => {
                    setEditOpen(null);
                  }}
                />
              )}
            </div>
          ))}
      </div>

      {storeData && data && (
        <div
          style={{
            border: '1px solid #ccc',
            margin: '0.5rem 0',
            padding: '0.8rem',
            borderRadius: '4px',
          }}
        >
          <b>카테고리 미지정</b>
          <table
            style={{
              width: '100%',
              borderCollapse: 'collapse',
              border: '1px solid #ccc',
              textAlign: 'center',
            }}
          >
            <thead>
              <tr
                style={{
                  background: '#e6e6e6',
                  border: '1px solid #ccc',
                }}
              >
                <th style={{ width: '40%', padding: '0.5rem 0' }}>메뉴명</th>
                <th>가격(원)</th>
                <th>매칭</th>
                <th>태그수</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data
                .filter(
                  (menu) => !menu.category || menu.category === '' || !checkInclude(menu.category)
                )
                .map((menu, index) => (
                  <tr key={`tr-not-${index}`} style={{ border: '1px solid #ccc' }}>
                    <td style={{ padding: '0.4em 0' }}>
                      <Link to={`/store/editmenu/${menu.id}`}>{menu.realName}</Link>
                    </td>
                    <td>{formatPrice(menu.price)}</td>
                    <td>{menu.name_id ? 'Y' : 'N'}</td>
                    <td>{menu.tags && menu.tags.length}</td>
                    <td>
                      <Button
                        type="small"
                        style={{ color: 'red' }}
                        onClick={() => {
                          onDeleteOneMenu(menu);
                        }}
                      >
                        삭제
                      </Button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}
      <>
        {modalOpen === 'editCategory' && (
          <Modal onClose={() => setModalOpen(null)} small>
            <b>카테고리추가</b>
            <br />
            <br />
            <Input title="카테고리명" id="new-cate-name" />
            <br />
            <span
              onClick={() => {
                user && user.authLevel === 'ROOT' && setWordOpen(true);
              }}
            >
              <Input title="카테고리번역" id="new-cate-name-id" placeholder="" disabled />
            </span>

            <br />
            <Button
              onClick={() => {
                const name = document.getElementById('new-cate-name').value;
                if (!name || name === '') return alert('카테고리명을 입력하세요.');
                const name_id = document.getElementById('new-cate-name-id').value || '';
                if (!name_id) return alert('카테고리번역을 입력하세요.');
                db.collection('stores')
                  .doc(id)
                  .update({
                    ...storeData,
                    menuCategories: (storeData.menuCategories || []).concat({
                      name,
                      name_id,
                    }),
                  })
                  .then(() => {
                    toast('업데이트 되었습니다.');
                    setModalOpen(null);
                    loadData();
                  });
              }}
            >
              추가하기
            </Button>
            {wordOpen && (
              <Modal
                onClose={() => {
                  setWordOpen(false);
                }}
              >
                <WordListContainer
                  type="옵션"
                  register={(name_id) => {
                    document.getElementById('new-cate-name-id').value = name_id;
                    setWordOpen(false);
                  }}
                />
              </Modal>
            )}
          </Modal>
        )}

        {modalOpen === 'changeCategory' && (
          <Modal onClose={() => setModalOpen(null)}>
            <h4>카테고리순서변경</h4>
            <br />
            <br />
            <table
              style={{
                width: '100%',
                border: '1px solid #ccc',
                borderCollapse: 'collapse',
                textAlign: 'center',
              }}
            >
              <thead>
                <tr
                  style={{
                    background: '#e6e6e6',
                    border: '1px solid #ccc',
                  }}
                >
                  <th>순서</th>
                  <th>카테고리명</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {(storeData.menuCategories || []).map((item, index) => (
                  <tr key={`change-cate-${index}`} style={{ border: '1px solid #ccc' }}>
                    <td>{index + 1}</td>
                    <td style={{ width: '40%', padding: '0.4rem 0' }}>{item.name}</td>
                    <td>
                      <Button
                        type="small"
                        onClick={() => {
                          if (index === 0) return;
                          let categoriesArr = [...(storeData.menuCategories || [])];
                          categoriesArr[index] = categoriesArr[index - 1];
                          categoriesArr[index - 1] = item;
                          // console.log(categoriesArr);
                          db.collection('stores')
                            .doc(id)
                            .update({
                              ...storeData,
                              menuCategories: categoriesArr,
                            })
                            .then(() => {
                              toast('업데이트 되었습니다.');
                              // setModalOpen(null);
                              loadData();
                            });
                        }}
                      >
                        ↑
                      </Button>
                      <Button
                        type="small"
                        onClick={() => {
                          if (index === (storeData.menuCategories || []).length - 1) return;
                          let categoriesArr = [...(storeData.menuCategories || [])];
                          categoriesArr[index] = categoriesArr[index + 1];
                          categoriesArr[index + 1] = item;
                          // console.log(categoriesArr);
                          db.collection('stores')
                            .doc(id)
                            .update({
                              ...storeData,
                              menuCategories: categoriesArr,
                            })
                            .then(() => {
                              toast('업데이트 되었습니다.');
                              // setModalOpen(null);
                              loadData();
                            });
                        }}
                      >
                        ↓
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <br />
            {/* <Button
            onClick={() => {
              const categoriesArr = storeData.menuCategories || [];

              db.collection('stores')
                .doc(id)
                .update({
                  ...storeData,
                  menuCategories: (storeData.menuCategories || []).concat({
                    name: value
                  })
                })
                .then(() => {
                  toast('업데이트 되었습니다.');
                  setModalOpen(null);
                  loadData();
                });
            }}
          >
            저장
          </Button> */}
          </Modal>
        )}
      </>
    </Container>
  );
};

export default MenuList;

const AddCategoryItem = ({ onClose, item, data, lastIndex, reload, checkInclude }) => {
  const [selected, setSelected] = useState([]);
  const { db } = useContext(FirebaseContext);

  const onSubmit = () => {
    let batch = db.batch();
    selected.forEach((item) => {
      const { category, index } = item;
      batch.update(db.collection('menues').doc(item.id), { category, index });
    });
    batch.commit().then(() => {
      toast('업데이트 되었습니다.');
      onClose();
      reload();
    });
  };

  return (
    <Modal small onClose={onClose}>
      <h4>
        [{item.name}]<span style={{ fontWeight: 400 }}>에 메뉴 추가</span>
      </h4>

      <div style={{ margin: '0.5rem' }}>
        <table>
          <tbody>
            {sortBy(
              data.filter(
                (menu) => !menu.category || menu.category === '' || !checkInclude(menu.category)
              ),
              ['realName']
            ).map((menu, index) => (
              <tr key={`tr-not-${index}`}>
                <td>
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      const { checked } = e.target;
                      if (checked) {
                        setSelected([
                          ...selected,
                          { ...menu, category: item.name, index: lastIndex + selected.length },
                        ]);
                      } else {
                        setSelected(selected.filter((sel) => sel.id !== menu.id));
                      }
                    }}
                  />
                </td>
                <td>{menu.realName}</td>
                <td>{formatPrice(menu.price)}원</td>
              </tr>
            ))}
            {data.filter(
              (menu) => !menu.category || menu.category === '' || !checkInclude(menu.category)
            ).length === 0 && (
              <tr>
                <td colSpan={2}>미지정 메뉴 없음</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <Button type="primary" onClick={onSubmit}>
        추가하기
      </Button>
    </Modal>
  );
};

const EditCategoryItem = ({ onClose, item, storeData, cateIndex, loadData }) => {
  const [data, setData] = useState(item);
  const [wordOpen, setWordOpen] = useState(false);
  const { db } = useContext(FirebaseContext);
  return (
    <>
      <Modal onClose={onClose}>
        <h4>카테고리수정</h4>
        <Input
          title="카테고리명"
          disabled
          onChange={(e) => {
            setData({ ...data, name: e.target.value });
          }}
          defaultValue={item.name}
        />
        <br />
        <span
          onClick={() => {
            setWordOpen(true);
          }}
        >
          <Input
            title="카테고리번역"
            placeholder=""
            id="edit-cate-name-id"
            disabled
            defaultValue={item.name_id}
          />
        </span>
        <p></p>
        <Button
          type="primary"
          onClick={() => {
            let newMenuCategories = storeData.menuCategories.map((item) => item);
            newMenuCategories[cateIndex] = data;
            console.log(newMenuCategories);
            db.collection('stores')
              .doc(storeData.id)
              .update({
                ...storeData,
                menuCategories: newMenuCategories,
              })
              .then(() => {
                toast('업데이트 되었습니다.');
                onClose();
                loadData();
              });
          }}
        >
          저장
        </Button>
        <Notice>
          카테고리명은 수정불가합니다. <br />
          이름변경시에는 카테고리 삭제 후 재등록하세요.
        </Notice>
      </Modal>
      {wordOpen && (
        <Modal
          onClose={() => {
            setWordOpen(false);
          }}
        >
          <WordListContainer
            type="옵션"
            register={(name_id) => {
              document.getElementById('edit-cate-name-id').value = name_id;
              setData({ ...data, name_id });
              setWordOpen(false);
            }}
          />
        </Modal>
      )}
    </>
  );
};
