import React, { useCallback, useState } from 'react';
import Container from '../../components/Container';
import axios from 'axios';
import AsyncTable from '../../components/Table/AsyncTable';
import Loader from '../../components/Loader';
import { capitalizeFirstLetter } from '../../common/format';
import Input from '../../components/Input';
import Button from '../../components/Button';
import Modal from '../../components/Modal';
import AddDicWord from './AddToWord';
import AddDicMenuDB from './AddToSystemMenu';
import { Notice } from '../../components';

const DictionaryList = () => {
  const [data, setData] = useState(new Map());
  const [keyword, setKeyword] = useState();
  const [isLoading, setLoading] = useState(false);
  const [total, setTotal] = useState(20);
  const [page, setPage] = useState(1);
  const [addWord, setAddWord] = useState(false);
  const [addDb, setAddDb] = useState(false);

  const [selectedItem, setSelectedItem] = useState(null);

  const loadData = useCallback(
    async page => {
      setLoading(true);
      const result = await axios({
        method: 'get',
        url: `https://api.visitkorea.or.kr/openapi/service/rest/TransService/transWord?ServiceKey=26pp%2FMuu3O6w2SR4JKffDaRq8ovim9rof8lBVCsDHZYwelHfM7o0zanb6HxLvf0KHPapiFhsFipiTVa57RZwbQ%3D%3D&numOfRows=20&word=${keyword}&cat1=B&langSe=KOR&pageNo=${page}&MobileOS=ETC&MobileApp=AppTest&_type=json`
      });
      setTotal(result.data.response.body.totalCount);

      let item = result.data.response.body.items.item;

      if (!Array.isArray(item)) {
        item = [item];
      }

      if (result.data.response.body.totalCount > 0) {
        const newData = item.map(item => ({
          kr: item.dicKor,
          en: capitalizeFirstLetter(item.dicEn),
          cn: item.dicChb,
          jp: item.dicJph,
          jp_e: item.dicJpe
        }));
        return newData;
      } else {
        return null;
      }
    },
    [keyword]
  );

  const loadAndSetPage = async nextPage => {
    if (!data.get(nextPage)) {
      const newData = await loadData(nextPage);
      setData(data => data.set(nextPage, newData));
    }

    setPage(nextPage);
    setLoading(false);
  };

  const search = async () => {
    if (!keyword || keyword === '') return alert('검색어를 입력하세요');
    setData(new Map());
    setPage(1);

    const result = await loadData();
    setData(data => data.set(1, result));
    setLoading(false);
  };

  return (
    <Container
      title="음식용어사전 검색"
      navigator="외국어관광용례사전"
      buttons={<Notice>태국어는 제공되지 않습니다.</Notice>}
    >
      <div style={{ display: 'flex', marginTop: '1rem', alignItems: 'center' }}>
        <Input
          placeholder="검색할 단어를 입력해주세요."
          onChange={e => setKeyword(e.target.value)}
          style={{ width: '40%' }}
          onKeyDown={e => {
            if (e.keyCode === 13) {
              search();
            }
          }}
        />
        <Button type="search" disabled onClick={search} style={{ width: 'fit-content' }} />
      </div>
      {!isLoading && data.get(page) ? (
        <AsyncTable
          data={data.get(page) || []}
          perPage={20}
          totalCount={total}
          pageState={[page, loadAndSetPage]}
          index
          notSearch
          headers={[
            { title: '한국어', value: 'kr' },
            { title: '영어', value: 'en' },
            { title: '중국어', value: 'cn' },
            { title: '일본어(훈독)', value: 'jp' },
            { title: '일본어(음독)', value: 'jp_e' },
            {
              render: row => (
                <Button
                  type="small"
                  onClick={_ => {
                    setSelectedItem(row);
                    setAddWord(true);
                  }}
                >
                  번역에 추가
                </Button>
              )
            },
            {
              render: row => (
                <Button
                  type="small"
                  onClick={_ => {
                    setSelectedItem(row);
                    setAddDb(true);
                  }}
                >
                  시스템메뉴에 추가
                </Button>
              )
            }
          ]}
        />
      ) : (
        <div
          style={{
            color: '#aaa',
            fontSize: '12px',
            width: '100%',
            textAlign: 'center',
            padding: '4rem 0 '
          }}
        >
          {isLoading ? <Loader /> : '검색 결과 없음'}
        </div>
      )}
      {addWord && (
        <Modal onClose={_ => setAddWord(false)}>
          <AddDicWord data={selectedItem} onClose={_ => setAddWord(false)} />
        </Modal>
      )}
      {addDb && (
        <Modal onClose={_ => setAddDb(false)}>
          <AddDicMenuDB data={selectedItem} onClose={_ => setAddDb(false)} />
        </Modal>
      )}
    </Container>
  );
};

export default DictionaryList;
