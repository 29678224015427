import React, { useContext, useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import Input from '../../components/Input';
import Button from '../../components/Button';
import { FirebaseContext } from '../../firebase';
import { withRouter } from 'react-router-dom';
import Modal from '../../components/Modal';
import Loader from '../../components/Loader';
import Div from '../../components/Div';
import Tag from '../../components/Tag';
import SelectTag from '../7_Tags/SelectTag';
import toast from '../../components/Toast';
import DbListModal from '../8_SystemMenu/SystemMenuListModal';
import Container from '../../components/Container';
import { uniqueId } from 'lodash';
import Notice from '../../components/Notice';
import FileInput from '../../components/FileInput';
import { uploadFile } from '../../common/fetch';
import { UserStateContext } from '../../contexts/UserContext';

const Content = styled.div`
  font-size: 14px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  .title {
    margin-right: 1rem;
    font-weight: 400;
    color: black;
  }
  .main-title {
    color: black;
    font-weight: 600;
    margin-bottom: 1rem;
  }
  .flex {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0.25em 0;
  }
  .photo {
    width: 200px;
    height: 125px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 4px;
  }
`;

const EditMenu = props => {
  const { db } = useContext(FirebaseContext);
  const { id } = props.match.params;
  const user = useContext(UserStateContext);
  const [data, setData] = useState(null);

  const [isWordOpen, setWordOpen] = useState(false);
  const [isTagOpen, setTagOpen] = useState(false);

  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    db.collection('menues')
      .doc(id)
      .get()
      .then(doc => setData(doc.data()))
      .catch(err => console.log(err));
  }, [db, id]);

  const onChangeInput = e => {
    setData({
      ...data,
      [e.target.name]:
        e.target.name === 'price' || e.target.name === 'index'
          ? parseInt(e.target.value)
          : e.target.value
    });
  };

  const onEdit = async _ => {
    if (isLoading) return null;
    setIsLoading(true);

    let newData = { ...data };
    if (file) {
      const uploaded = await uploadFile(`menus/${id}`, file, 'photo');
      newData.photo = uploaded.url;
    }

    db.collection('menues')
      .doc(id)
      .update({ ...newData, editedAt: new Date() })
      .then(() => {
        toast('업데이트 되었습니다.');
        props.history.push(`/store/menu/${data.store_id}`);
      });
  };

  const isAdmin = useMemo(() => {
    if (user && user.authLevel === 'ROOT') return true;
    return false;
  }, [user]);

  return (
    <>
      {isLoading && <Loader />}
      <Container
        title="배달업체관리"
        navigator="배달업체목록 > 업체정보수정 > 메뉴수정"
        buttons={
          <Button to={`/store/option/${id}`} type="normal">
            옵션관리
          </Button>
        }
      >
        {!isAdmin && (
          <Notice>
            협력사는 기본가격만 수정 가능합니다. 그 외 수정요청은 게시판에 문의주세요.
          </Notice>
        )}
        {user && data ? (
          <Content>
            <Input
              title={'메뉴명 (한국어)'}
              defaultValue={data && data.realName}
              onChange={onChangeInput}
              name="realName"
              disabled={!isAdmin}
            />

            <Input
              value={data && data.name_id && `${data.name} (${data.name_id})`}
              title={'메뉴매칭'}
              name="name"
              disabled
            >
              {isAdmin && (
                <Button
                  type="primary"
                  onClick={() => {
                    setWordOpen(true);
                  }}
                >
                  DB검색
                </Button>
              )}
            </Input>

            <Input
              defaultValue={data.price}
              title={'가격'}
              onChange={onChangeInput}
              name="price"
              placeholder={`단위(원)`}
            />
            <Input
              title={'메뉴분류'}
              defaultValue={data.category}
              onChange={onChangeInput}
              name="category"
              disabled
            />

            <Div title={'대표사진'}>
              <div style={{ marginBottom: '0.5rem' }}>
                <div
                  className="photo"
                  style={{
                    backgroundColor: '#dfdfdf',
                    backgroundImage: `url(${data && data.photo})`
                  }}
                >
                  {!data.photo && '사진 없음'}
                </div>
              </div>
              {isAdmin && (
                <>
                  <FileInput
                    onChange={e => {
                      const file = e.target.files[0];
                      if (!file) return;
                      if (file.type.split('/')[0] !== 'image')
                        return alert('이미지파일만 업로드 가능합니다.');
                      let reader = new FileReader();
                      reader.readAsDataURL(file);
                      reader.onload = e => {
                        setData({ ...data, photo: e.target.result });
                        setFile(file);
                      };
                    }}
                  />

                  {data.photo && (
                    <Button
                      type="normal"
                      onClick={() => {
                        setData({ ...data, photo: '' });
                      }}
                    >
                      사진삭제
                    </Button>
                  )}
                </>
              )}
            </Div>
            <Notice style={{ marginLeft: '120px' }}>
              사진 업로드 안내 <br />
              5:3 비율을 권장합니다. <br />
              비율이 안맞을 경우 자동으로 중앙정렬해서 보여집니다. <br /> 권장 해상도: 480x288, 권장
              파일 사이즈: 100KB 미만
            </Notice>

            <Div title={'태그(설명)'}>
              {data.tags && data.tags.map(tag => <Tag id={tag} key={uniqueId()} />)}
            </Div>
            <span />
            {isAdmin && (
              <Button
                style={{
                  marginLeft: '120px',
                  background: '#339af0'
                }}
                onClick={() => {
                  setTagOpen(true);
                }}
              >
                태그등록
              </Button>
            )}

            <div className="flex" style={{ marginTop: '2rem' }}>
              <Button
                onClick={onEdit}
                style={{
                  background: '#339af0'
                }}
              >
                수정
              </Button>
              <Button
                onClick={() => {
                  props.history.goBack();
                }}
                style={{ marginLeft: '0.5rem' }}
                type="normal"
              >
                뒤로
              </Button>
            </div>
          </Content>
        ) : (
          <Loader />
        )}

        {/* 단어입력용 모달 */}
        {isWordOpen && (
          <Modal
            onClose={() => {
              setWordOpen(false);
            }}
          >
            <DbListModal
              register={(name_id, name, photo, tags) => {
                setData({
                  ...data,
                  name_id,
                  name,
                  photo,
                  tags
                });
                setWordOpen(false);
              }}
            />
          </Modal>
        )}
        {/* 태그입력용 */}
        {isTagOpen && (
          <Modal
            onClose={() => {
              setTagOpen(false);
            }}
          >
            <SelectTag
              current={data.tags}
              register={tags => {
                setData({
                  ...data,
                  tags
                });
                setTagOpen(false);
              }}
            />
          </Modal>
        )}
      </Container>
    </>
  );
};

export default withRouter(EditMenu);
