import React, { useContext, useState } from 'react';
import { FirebaseContext } from '../../firebase';
import styled from 'styled-components';
import { Input, Button, Toast as toast } from '../../components';

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 70vh;
`;

function ChangePassword(props) {
  const { auth } = useContext(FirebaseContext);
  const [data, setData] = useState();

  const doChange = () => {
    if (data.pw !== data.pw2) return;
    const user = auth.currentUser;
    user
      .updatePassword(data.pw)
      .then(function() {
        toast('변경되었습니다.');
        props.history.push('/home');
      })
      .catch(function(error) {
        if (error.code === 'auth/requires-recent-login') {
          alert('로그인 유효 세션이 만료되었습니다. 로그아웃 후 다시 시도해보세요.');
        }
      });
  };

  return (
    <StyledDiv>
      <h3>비밀번호 변경</h3>
      <Input
        onChange={e => setData({ ...data, pw: e.target.value })}
        placeholder="변경할 비밀번호"
        style={{ width: '280px', marginBottom: '0.5rem' }}
        type="password"
        name="pw"
      />
      <Input
        onChange={e => setData({ ...data, pw2: e.target.value })}
        placeholder="비빌번호 확인"
        style={{
          width: '280px',
          marginBottom: '0.25rem',
          boxShadow: data && data.pw2 && data.pw !== data.pw2 && '0 0 4px 1px #ff3250'
        }}
        type="password"
        name="pw2"
      />
      {data && data.pw2 && data.pw !== data.pw2 && (
        <span style={{ fontSize: '12px', color: '#ff3250' }}>비밀번호가 일치하지 않습니다.</span>
      )}

      <Button
        onClick={doChange}
        style={{ width: '280px', background: '#ff3250', marginTop: '1.5rem' }}
      >
        비밀번호 변경
      </Button>
    </StyledDiv>
  );
}

export default ChangePassword;
