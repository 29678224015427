import React, { useEffect, useState, useContext, useCallback } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import Container from '../../components/Container';
import { FirebaseContext } from '../../firebase';
import { formatPrice } from '../../common/format';
import Button from '../../components/Button';
import Dropdown from '../../components/Dropdown';
import { UserStateContext } from '../../contexts/UserContext';
import { find } from 'lodash';
import { Loader } from '../../components';

const Section = styled.div`
  table {
    margin-top: 1rem;
  }
  td {
    padding: 0.25em;
  }
`;

const getEachTotal = (cart) => {
  if (!cart) return null;
  let optionPrice = 0;
  cart.options.map((option) => (optionPrice += Number(option?.price ?? 0)));
  let totalPrice = 0;
  totalPrice += optionPrice + parseInt(cart.price);
  return totalPrice;
};

export const getAllTotal = (carts, deliveryFee = 0) => {
  if (!carts) return null;
  let totalPrice = 0;
  carts.forEach((item) => {
    totalPrice += getEachTotal(item);
  });

  return totalPrice + Number(deliveryFee);
};

const formatTenPercentCommission = (number) => {
  return Math.round((number * 1.1) / 100) * 100;
};

const OrderDetail = (props) => {
  const user = useContext(UserStateContext);
  const { id } = props.match.params;
  const { db } = useContext(FirebaseContext);
  const [data, setData] = useState();
  const [status, setStatus] = useState();

  const loadHotelData = useCallback(
    (id) => {
      return db
        .collection('hotels')
        .doc(id)
        .get()
        .then((doc) => doc.data());
    },
    [db]
  );

  const loadOrderData = useCallback(() => {
    return db
      .collection('orders')
      .doc(id)
      .get()
      .then((doc) => doc.data());
  }, [db, id]);

  const loadData = useCallback(async () => {
    const data = await loadOrderData();
    console.log(data);
    const { hotel_id } = data;
    const hotelData = await loadHotelData(hotel_id);
    setData({ ...data, hotelPhone: hotelData.phone, hotelName: hotelData.name });
  }, [loadOrderData, loadHotelData]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const updateStatus = () => {
    if (status) {
      let result = window.confirm(
        '정말 상태변경하시겠습니까? (주문취소의경우 잠시후 자동 환불처리됩니다.)'
      );
      if (!result) return null;
      db.collection('orders')
        .doc(id)
        .update({
          status: parseInt(status),
        })
        .then(() => {
          alert('상태가 변경되었습니다.');
        });
    } else {
      alert('입력란이 비어있거나 변경 사항이 없습니다.');
    }
  };

  const statusOptions = [
    { value: 1, text: '접수대기' },
    { value: 2, text: '접수완료' },
    { value: 3, text: '배달중' },
    { value: 4, text: '배달완료' },
    { value: -1, text: '주문취소' },
  ];

  return (
    <Container title="주문/정산관리" navigator="주문/정산목록 > 주문상세">
      {data ? (
        <Section>
          <table>
            <tbody>
              <TableRow title="주문번호" value={data.orderNumber} />
              <TableRow title="주문일시" value={data.date && data.date.toDate().toLocaleString()} />
              <tr>
                <td>
                  <b>주문상태</b>
                </td>
                <td style={{ display: 'flex', alignItems: 'center' }}>
                  {user && user.authLevel === 'ROOT' ? (
                    <>
                      <Dropdown
                        name="type"
                        defaultValue={data.status}
                        onChange={(e) => {
                          setStatus(e.target.value);
                        }}
                        options={statusOptions}
                      />
                      <Button style={{ marginLeft: '0.5rem' }} type="normal" onClick={updateStatus}>
                        변경하기
                      </Button>
                    </>
                  ) : (
                    data.status && find(statusOptions, (item) => item.value === data.status).text
                  )}
                  <span style={{ color: '#888', marginLeft: '0.5rem', fontSize: '12px' }}>
                    (변경된시간:&nbsp;
                    {data.lastStatusChanged && typeof data.lastStatusChanged === 'number'
                      ? new Date(data.lastStatusChanged).toLocaleString()
                      : data.lastStatusChanged && data.lastStatusChanged.toDate().toLocaleString()}
                    )
                  </span>
                </td>
              </tr>

              <TableRow title="주소" value={data.address} />
              <TableRow title="숙박 업체" value={data.hotelName} />
              <TableRow title="숙박 업체전화번호" value={data.hotelPhone} />

              {/* 선택 입력 정보 */}
              <br />
              <TableRow
                title="고객 전화번호"
                value={`(+${data?.countryCode ?? ''}) ${data?.phoneNo ?? ''}`}
              />

              {/* 룸서비스 */}
              <TableRow
                title="In-Room 예약정보"
                value={`${data?.reservationDate ?? ''}  ${data.reservationTime ?? ''}`}
              />
              <br />

              <TableRow title="기본요청사항" value={data.defaultMessage} />
              <TableRow title="배달 업체" value={data.storeName} />
              <TableRow title="배달업체 등록상태" value={data.storeStatus} />
              <TableRow title="결제총계" value={formatPrice(data.total) + '원'} />
              <TableRow title="결제수단" value={data.pay} />
              <TableRow title="배달방법" value={data.deliveryMethod} />
              <TableRow title="취소코드" value={data.reason} />
              <TableRow title="환불유무" value={data.payment_cancel_response ? 'Y' : 'N'} />
              <TableRow title="문자전송유무" value={data.smsResult === 1 ? 'Y' : 'N'} />
              <TableRow
                title="정산현황"
                value={
                  <>
                    {data.calc ? '정산완료' : data.status === 4 ? '정산대기' : '미정산'}
                    <span style={{ color: '#888', marginLeft: '0.5rem', fontSize: '12px' }}>
                      &nbsp; {data.calcDate && data.calcDate.toDate().toLocaleString()}
                    </span>
                  </>
                }
              />
            </tbody>
          </table>
        </Section>
      ) : (
        <Loader />
      )}
      <br />

      {data && (
        <table
          style={{
            textAlign: 'center',
            maxWidth: '480px',
            border: '1px solid #ccc',
            borderCollapse: 'collapse',
          }}
        >
          <thead>
            <tr>
              <th colSpan={3} style={{ padding: '0.5rem 0', borderBottom: '1px solid #ccc' }}>
                상세주문내역
              </th>
            </tr>
            <tr>
              <th>상품명</th>
              <th>실제메뉴가격</th>
              <th>고객결제가격(+10%)</th>
            </tr>
          </thead>
          <tbody>
            {data &&
              data.cart &&
              data.cart.map((item, index) => (
                <React.Fragment key={index}>
                  <tr>
                    <td>{item.name}</td>
                    <td>{`${formatPrice(item.price)}`}</td>
                    <td>{formatPrice(formatTenPercentCommission(item.price))}</td>
                  </tr>
                  {item.options &&
                    item.options.map((option, index) => (
                      <tr key={index} style={{ color: '#aaa', fontSize: '13px' }}>
                        <td>{option.name}</td>
                        <td>+{formatPrice(option.price)}</td>
                        <td>{formatPrice(formatTenPercentCommission(option.price))}</td>
                      </tr>
                    ))}
                </React.Fragment>
              ))}
            <tr>
              <td style={{ borderTop: '1px solid #ccc' }}>배달비</td>
              <td style={{ borderTop: '1px solid #ccc' }}>{formatPrice(data.deliveryFee)}</td>
              <td style={{ borderTop: '1px solid #ccc' }}>{formatPrice(data.deliveryFee)}</td>
            </tr>
            <tr>
              <td>서비스비</td>
              <td></td>
              <td>{formatPrice(data.serviceFee)}</td>
            </tr>
            <tr style={{ background: '#efefef', padding: '0.5rem 0' }}>
              <th style={{ padding: '0.5rem 0' }}>합계</th>
              <th>{`${formatPrice(getAllTotal(data.cart, +data.deliveryFee))}원`}</th>
              <th>{`${formatPrice(data.total)}원`}</th>
            </tr>
          </tbody>
        </table>
      )}
    </Container>
  );
};

export default withRouter(OrderDetail);

const TableRow = ({ title, value }) => {
  return (
    <tr>
      <td style={{ padding: '.25rem' }}>
        <b>{title}</b>
      </td>
      <td style={{ padding: '.25rem' }}>{value}</td>
    </tr>
  );
};
