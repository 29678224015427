import React, { useContext, useState } from 'react';
import csv2json from 'csvjson-csv2json';
import Container from '../../components/Container';
import { FirebaseContext } from '../../firebase';
import toast from '../../components/Toast';
import Button, { StyledButton } from '../../components/Button';
import Notice from '../../components/Notice';
import FileInput from '../../components/FileInput';
import { randomId } from '../../common/format';
import Dropdown from '../../components/Dropdown';

const AddStoreMenuCsv = () => {
  const { db } = useContext(FirebaseContext);
  const [batchData, setBatchData] = useState(null);
  const isWindows = window.navigator.userAgent.indexOf('Windows') !== -1;
  const [encoding, setEncoding] = useState(isWindows ? 'euc-kr' : 'utf-8');
  const [isLoading, setLoading] = useState(false);

  const fileUpload = e => {
    const file = e.target.files[0];
    if (file && file.type !== 'text/csv' && file.name.split('.')[1] !== 'csv') {
      alert('.csv 파일만 업로드 가능합니다.');
      return null;
    }

    const readFileContent = file => {
      const reader = new FileReader();
      return new Promise((resolve, reject) => {
        reader.onload = e => resolve(e.target.result);
        reader.onerror = err => reject(err);
        reader.readAsText(file, encoding);
      });
    };
    let data = [];
    let options = [];
    setLoading(true);

    readFileContent(file).then(async csv => {
      csv2json(csv).forEach((item, menuIndex) => {
        const id =
          randomId(16, 'aA0') + Math.ceil(Math.random() * 10) * new Date().getMilliseconds();
        const result = item.options.split(',');
        const [name, name_id, index, radio] = result;
        let optionData = {
          groupName: name,
          name_id,
          index: index && parseInt(index),
          radio: radio && radio.toUpperCase() === 'Y' ? true : false,
          menu_id: id
        };
        let list = [];
        result.forEach((option, index) => {
          if (index > 3) {
            const [name, name_id, price, isDefault] = option.split('/');
            list.push({
              realName: name,
              name: name,
              name_id,
              price,
              default: isDefault && isDefault.toUpperCase() === 'Y' ? true : false,
              must: radio && radio.toUpperCase() === 'Y' ? true : false
            });
          }
        });
        optionData.list = list;
        if (optionData.groupName !== '' && optionData.groupName !== undefined) {
          options.push(optionData);
        }
        let tags = item.tags !== '' ? item.tags.split('/') : [];
        let photo = '';

        data.push({
          id,
          realName: item.realName,
          category: item.category,
          name_id: item.name_id !== '#N/A' ? item.name_id : null,
          price: Number.isNaN(parseInt(item.price)) ? 0 : parseInt(item.price),
          tags,
          store_id: item.store_id !== '#N/A' ? item.store_id : null,
          index: menuIndex,
          photo
        });
      });
      data.shift();
      options.shift();
      // console.log(data);
      console.log(options);

      const promises = data.map(
        item =>
          new Promise(resolve => {
            if (!item.name_id) return resolve(null);
            if (item.name_id === '#N/A') return resolve(null);
            return db
              .collection('menudb')
              .doc(item.name_id)
              .get()
              .then(
                doc =>
                  doc.exists &&
                  resolve({
                    id: item.name_id,
                    tags: doc.data().tags || [],
                    photo: doc.data().photo || '',
                    name: doc.data().kr
                  })
              )
              .catch(_ => alert('오류가 발생하였습니다.'));
          })
      );

      const defaultInfo = await Promise.all(promises);

      const combineData = data.map(item => {
        const filtered = defaultInfo.filter(info => info && info.id === item.name_id);
        if (filtered.length > 0) {
          return {
            ...item,
            tags: filtered[0].tags,
            photo: filtered[0].photo,
            name: filtered[0].name
          };
        }
        return item;
      });
      console.log(combineData);
      setLoading(false);

      setBatchData({ data: combineData, options });
    });
  };

  const submitData = () => {
    if (!batchData) return alert('파일을 입력해주세요.');
    const { data, options } = batchData;
    const batch = db.batch();
    data.map(item => {
      const ref = db.collection('menues').doc(item.id);
      return batch.set(ref, item);
    });
    options.map(item => {
      const ref = db.collection('options').doc();
      return batch.set(ref, item);
    });

    batch
      .commit()
      .then(() => {
        toast('업데이트 되었습니다.');
      })
      .catch(err => {
        console.log(err);
        toast('failed');
      });
  };

  return (
    <Container
      title={`업체메뉴 엑셀업로드`}
      buttons={
        <a
          href={`https://firebasestorage.googleapis.com/v0/b/deliquick-93b6b.appspot.com/o/csv_sample%2F%E1%84%83%E1%85%A6%E1%86%AF%E1%84%85%E1%85%B5%E1%84%8F%E1%85%B1%E1%86%A8_%E1%84%86%E1%85%A6%E1%84%82%E1%85%B2%E1%84%8B%E1%85%A5%E1%86%B8%E1%84%85%E1%85%A9%E1%84%83%E1%85%B3_%E1%84%89%E1%85%A2%E1%86%B7%E1%84%91%E1%85%B3%E1%86%AF.csv?alt=media&token=1a90b5cb-b033-4a05-bda0-daf4f892bee9`}
          download
        >
          <StyledButton type="normal">샘플파일다운로드</StyledButton>
        </a>
      }
    >
      <Notice>
        <b>.csv</b> 파일 이용하여 업로드 가능
        <br />
        사용법 숙지 후 사용할것!
        <br />
        <br />
        1. 각 번역코드는 빈칸일경우 입력되지않고 나중에 각각 편집해야합니다. <br />
        2. 입력한 카테고리는 해당카테고리명으로 분류만 되고 후에 카테고리 생성은 별도로 해야합니다.
        <br />
        3. 형식을 정확하게 일치시켜주세요. 띄어쓰기(공백) 주의!
        <br />
        4. 만약 다른운영체제에서 작성된 파일일 경우 인코딩선택으로 작성된 운영체제를 지정해주세요.
      </Notice>

      <div>
        <span>인코딩선택</span>
        <Dropdown
          name="encoding"
          onChange={e => {
            setEncoding(e.target.value);
          }}
          defaultValue={encoding}
          options={[
            { text: 'Windows (euc-kr)', value: 'euc-kr' },
            { text: 'Mac OS (utf-8)', value: 'utf-8' }
          ]}
        />
      </div>

      <FileInput type="file" name="xlsx" onChange={fileUpload} />

      <Button
        onClick={submitData}
        style={{
          backgroundColor: batchData && !isLoading ? '#339af0' : '#aaa',
          width: 'fit-content'
        }}
      >
        등록
      </Button>
      <br />
      {isLoading && '로딩중...'}
    </Container>
  );
};

export default AddStoreMenuCsv;
