import React, { useContext, useState, useEffect } from 'react';
import { FirebaseContext } from '../../firebase';
import {
  Button,
  Container,
  Div,
  Tag,
  Form,
  Modal,
  FileInput,
  Toast as toast,
  Input,
  Loader,
  Notice,
  Gap,
} from '../../components';
import SelectTag from '../7_Tags/SelectTag';
import { uploadFile } from '../../common/fetch';
import { generateKeywords } from '../../common/utils';

function EditImage(props) {
  const { id } = props.match.params;
  const { db } = useContext(FirebaseContext);
  const [data, setData] = useState(null);
  const [isTagOpen, setTagOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const onEdit = async () => {
    if (!data.photo || data.photo === '') return alert('사진을 추가하세요.');
    setIsLoading(true);
    const photo = await uploadFile('images', file, 'photo');
    db.collection('images')
      .doc(id)
      .update({
        ...data,
        photo: file ? photo.url : data.photo,
        keywords: generateKeywords(data.desc),
      })
      .then(() => {
        setIsLoading(false);
        toast('업데이트 되었습니다.');
        props.history.push('/images');
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const onLoad = () => {
      db.collection('images')
        .doc(id)
        .get()
        .then((doc) => setData(doc.data()));
    };
    onLoad();
  }, [db, id]);

  const onInputChange = ({ target }) => {
    const { name, value } = target;
    setData({ ...data, [name]: value });
  };

  const onDelete = () => {
    if (!window.confirm('정말 삭제하시겠습니까?')) return;
    db.collection('images')
      .doc(id)
      .delete()
      .then(() => {
        toast('정상적으로 삭제 되었습니다.');
        props.history.push('/images');
      });
  };

  return (
    <>
      {isLoading && <Loader />}
      <Container
        title="이미지 수정"
        navigator="이미지관리 > 이미지수정"
        buttons={
          <Button type="delete" onClick={onDelete}>
            삭제
          </Button>
        }
      >
        {data && (
          <Form onSubmit={onEdit} btn="수정">
            <Div title={'이미지'} required>
              <div style={{ marginBottom: '0.5rem' }}>
                <div
                  className="photo"
                  style={{
                    backgroundColor: '#dfdfdf',
                    backgroundImage: `url(${data && data.photo})`,
                  }}
                >
                  {!data.photo && '사진 없음'}
                </div>
              </div>
              <FileInput
                onChange={(e) => {
                  const file = e.target.files[0];
                  if (!file) return;
                  if (file.type.split('/')[0] !== 'image')
                    return alert('이미지파일만 업로드 가능합니다.');
                  let reader = new FileReader();
                  reader.readAsDataURL(file);
                  reader.onload = (e) => {
                    setData({ ...data, photo: e.target.result });
                    setFile(file);
                  };
                }}
              />
            </Div>
            <Gap>
              <Input
                name="desc"
                title="이미지 설명"
                onChange={onInputChange}
                defaultValue={data.desc}
              />
            </Gap>

            <Notice style={{ marginLeft: '120px' }}>
              띄어쓰기 단위로 키워드 생성되어 검색이 가능합니다.
            </Notice>
            <Div title={'태그'} required>
              {data.tags && data.tags.map((tag, key) => <Tag key={key} id={tag} />)}
            </Div>
            <span />
            <Button
              style={{
                marginLeft: '120px',
                background: '#339af0',
              }}
              onClick={() => {
                setTagOpen(true);
              }}
            >
              태그등록
            </Button>
          </Form>
        )}
        {!data && <Loader />}

        {/* 태그입력용 */}
        {data && isTagOpen && (
          <Modal
            onClose={() => {
              setTagOpen(false);
            }}
          >
            <SelectTag
              current={[...data.tags]}
              register={(tags) => {
                setData({
                  ...data,
                  tags,
                });
                setTagOpen(false);
              }}
            />
          </Modal>
        )}
      </Container>
    </>
  );
}

export default EditImage;
