import React, { useState, useEffect, useContext, useCallback } from 'react';
import styled, { css } from 'styled-components';
import logo from '../images/logo.svg';
import { Link, withRouter } from 'react-router-dom';
import { FirebaseContext } from '../firebase';
import { UserStateContext, UserDispatchContext } from '../contexts/UserContext';
import UserMenu from './UserMenu';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: solid 1px #e6e6e6;
  height: 47px;
  position: sticky;
  top: 0;
  background: white;
  z-index: 300;
  user-select: none;
  font-size: 14px;
  @media (max-width: 480px) {
    font-size: 12px;
    font-weight: normal;
  }
  .logo {
    width: 96px;
    height: 24px;
    @media (max-width: 480px) {
      width: 80px;
    }
  }
`;

const ScrollMenu = styled.div`
  background: white;
  height: 38px;
  justify-content: flex-start;
  z-index: 200;
  position: sticky;
  top: 48px;
  overflow-x: scroll;
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: solid 1px #e6e6e6;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none !important;
  }
`;

const Item = styled.span`
  display: flex;
  padding: 0 0.5rem;
  align-items: center;
  font-size: 14px;
  ${({ active }) => css`
    color: ${active ? '#ff3250' : '#aaaaaa'};
    height: ${active ? '37px' : '40px'};
    border-bottom: ${active && '3px solid #ff3250'};
  `}
  transition: 0.1s all ease-out;
  white-space: nowrap;
  &:hover {
    color: #ff3250;
  }
`;

const Header = ({ location }) => {
  const path = location.pathname.split('/')[1];
  const [tab, setTab] = useState(path);
  const { auth } = useContext(FirebaseContext);
  const user = useContext(UserStateContext);
  const dispatch = useContext(UserDispatchContext);

  const clearUser = useCallback(
    (_) => {
      dispatch({ type: 'CLEAR_USER' });
    },
    [dispatch]
  );

  useEffect(() => {
    setTab(path);
  }, [path]);

  return (
    <>
      <Container>
        <Link
          to="/"
          style={{
            marginLeft: '5%',
            color: '#000',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <img src={logo} className="logo" alt="" id="header-logo" />
          <span
            style={{ marginLeft: '0.3rem', fontSize: '14px', marginTop: '5px', fontWeight: 500 }}
          >
            관리자시스템
          </span>
        </Link>
        {path !== 'login' && (
          <UserMenu
            onLogout={() => {
              auth.signOut();
              clearUser();
            }}
          />
        )}
      </Container>
      {user && path !== 'login' && (
        <ScrollMenu>
          <Link to={`/partner`} style={{ marginLeft: '3%' }}>
            <Item active={tab === 'partner'}>협력사관리</Item>
          </Link>
          <Link to={`/store`}>
            <Item active={tab === 'store'}>배달업체관리</Item>
          </Link>
          <Link to={`/hotel`}>
            <Item active={tab === 'hotel'}>숙박업체관리</Item>
          </Link>
          <Link to={`/order`}>
            <Item active={tab === 'order'}>주문/정산관리</Item>
          </Link>
          {/* 관리자전용 */}
          {user && user.authLevel === 'ROOT' && (
            <>
              <Link to={`/user`}>
                <Item active={tab === 'user'}>회원관리</Item>
              </Link>
              <Link to={`/tags`}>
                <Item active={tab === 'tags'}>태그관리</Item>
              </Link>
              <Link to={`/translation`}>
                <Item active={tab === 'translation'}>번역관리</Item>
              </Link>
              <Link to={`/words`}>
                <Item active={tab === 'words'}>시스템메뉴관리</Item>
              </Link>
              <Link to={`/contents`}>
                <Item active={tab === 'contents'}>컨텐츠관리</Item>
              </Link>
              <Link to={`/images`}>
                <Item active={tab === 'images'}>이미지관리</Item>
              </Link>
              <Link to={`/dictionary`}>
                <Item active={tab === 'dictionary'}>음식용어사전</Item>
              </Link>
              <Link to={`/coupons`}>
                <Item active={tab === 'coupons'}>쿠폰관리</Item>
              </Link>
            </>
          )}

          <Link to={`/board`}>
            <Item active={tab === 'board'}>요청/문의</Item>
          </Link>
          <Link to={`/notice`}>
            <Item active={tab === 'notice'}>공지사항</Item>
          </Link>
        </ScrollMenu>
      )}
    </>
  );
};

export default withRouter(Header);
