import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckSquare,
  faEdit,
  faTrash,
  faAlignCenter,
  faAlignLeft,
  faAlignRight,
  faImage
} from '@fortawesome/free-solid-svg-icons';
import { useOnClickOutside } from '../../common/hooks';

const ItemContainer = styled.li`
  list-style: none;
  margin-top: 8px;
  border: 1.5px solid #aaa;
  border-radius: 8px;
  padding: 1rem 1rem;
  font-size: 14px;
  width: 300px;
  box-shadow: ${({ isEdit }) => isEdit && '0px 0px 3px 1px #66d9e8'};
  &:active {
    background: ${({ isEdit }) => !isEdit && 'rgba(100, 100, 100, 0.1)'};
    box-shadow: ${({ isEdit }) => !isEdit && '0px 0px 3px 1px #66d9e8'};
  }
  input,
  textarea {
    outline: none;
    border: 1px solid #aaa;
    border-radius: 8px;
    padding: 0.2rem 0.4rem;
    font-size: 14px;
    width: 90%;
    margin: 0.25rem 0;
  }
  .input-label {
    outline: none;
    background: #aaa;
    font-weight: bold;
    color: white;
    border-radius: 8px;
    padding: 0.3rem 0.5rem;
    font-size: 14px;
    width: fit-content;
    cursor: pointer;
    &:hover {
      opacity: 0.9;
    }
  }
`;

const typeStyles = {
  photo: {
    border: '1.5px solid #f06595'
  },
  sectionTitle: {
    border: '1.5px solid #228be6'
  },
  text: {
    border: '1.5px solid #ffd43b'
  }
};

const EditItem = ({ data, setData, onDelete }) => {
  const [isEdit, setIsEdit] = useState(false);

  const [fileInfo, setFileInfo] = useState(null);
  const [title, setTitle] = useState((data.title && data.title.kr) || '');

  const [style, setStyle] = useState(data.style || { textAlign: 'left' });
  const [titleStyle, setTitleStyle] = useState(data.titleStyle || { textAlign: 'left' });

  const { id, type } = data;

  const [value, setValue] = useState('');

  const ref = useRef();

  useEffect(() => {
    let value = '';
    if (type === 'photo') {
      value = data.photo;
    } else if (type === 'smallbar') {
      value = true;
    } else {
      value = data[type] && (data[type].kr || '');
    }
    setValue(value);
  }, [data, type]);

  useOnClickOutside(ref, () => saveData());

  const saveData = () => {
    setIsEdit(false);
    let newData = {
      id,
      type,
      style,
      titleStyle
    };
    if (type === 'photo') {
      newData.photo = value;
    } else if (type === 'smallbar') {
      newData.smallbar = true;
    } else {
      newData[type] = { ...data[type], kr: value };
    }

    if (fileInfo) newData.fileInfo = fileInfo;
    if (title) newData.title = { ...data.title, kr: title };
    console.log(newData);
    setData(newData);
  };

  if (isEdit) {
    // 수정중
    return (
      <ItemContainer data-id={id} style={typeStyles[type]} ref={ref} isEdit={isEdit}>
        {type === 'smallbar' && <b>분할바</b>}
        {type === 'text' && (
          <div>
            <AlignButtons setStyle={setTitleStyle} style={titleStyle} />
            <div>
              <b>소제목</b> <span style={{ color: '#aaa', fontSize: '10px' }}>(필수 아님)</span>
              <input
                defaultValue={data.title && (data.title.kr || '')}
                onChange={e => setTitle(e.target.value)}
              />
            </div>
            <AlignButtons setStyle={setStyle} style={style} />
            <div>
              <b>내용</b>
              <textarea
                defaultValue={data.text && data.text.kr && data.text.kr.replace(/<br\/>/g, `\r\n`)}
                style={{ height: '4rem' }}
                onChange={e => setValue(e.target.value.replace(/\n/g, '<br/>'))}
              />
            </div>
          </div>
        )}

        {type === 'sectionTitle' && (
          <>
            <b>중간제목</b>
            <AlignButtons setStyle={setStyle} style={style} />
            <input
              defaultValue={data.sectionTitle && data.sectionTitle.kr}
              onChange={e => setValue(e.target.value)}
            />
          </>
        )}
        {type === 'photo' && (
          <>
            <b>이미지</b>
            <div style={{ margin: '1rem 0' }}>
              <label
                className="input-label"
                htmlFor="input-image"
                style={{
                  background: fileInfo ? '#f06595' : '#aaa'
                }}
              >
                {fileInfo ? (
                  <span>
                    <FontAwesomeIcon
                      icon={faImage}
                      size="sm"
                      color="white"
                      style={{ marginRight: '0.25rem' }}
                    />
                    {fileInfo.name}
                  </span>
                ) : (
                  <span>
                    <FontAwesomeIcon
                      icon={faImage}
                      size="sm"
                      color="white"
                      style={{ marginRight: '0.25rem' }}
                    />
                    파일 선택
                  </span>
                )}
              </label>
              <input
                type="file"
                id="input-image"
                style={{ display: 'none', width: 0, height: 0 }}
                onChange={e => {
                  const file = e.target.files[0];
                  if (!file) return;
                  if (file.type.split('/')[0] !== 'image')
                    return alert('이미지파일만 업로드 가능합니다.');
                  let reader = new FileReader();
                  reader.readAsDataURL(file);
                  reader.onload = e => {
                    setValue(e.target.result);
                    setFileInfo(file);
                  };
                }}
              />
            </div>
          </>
        )}
        <div style={{ width: '100%', marginTop: '0.5rem' }}>
          <FontAwesomeIcon
            icon={faCheckSquare}
            color={'#aaa'}
            style={{ margin: '0 6px', cursor: 'pointer' }}
            size="lg"
            onClick={saveData}
          />

          <FontAwesomeIcon
            icon={faTrash}
            color="#aaa"
            style={{ margin: '0 6px', cursor: 'pointer' }}
            size="lg"
            onClick={() => {
              onDelete(data);
            }}
          />
        </div>
      </ItemContainer>
    );
  } else {
    return (
      <ItemContainer data-id={id} style={typeStyles[type]}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <b>
            {type === 'text' && '텍스트'}
            {type === 'sectionTitle' && '중간제목'}
            {type === 'photo' && '이미지'}
            {type === 'smallbar' && '분할바'}
          </b>
          <div
            style={{
              margin: '0 4px',
              maxWidth: '200px',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap'
            }}
          >
            {type !== 'photo'
              ? type === 'text'
                ? title
                : value
              : fileInfo && (
                  <div className="input-label" style={{ background: '#f06595', cursor: 'auto' }}>
                    <FontAwesomeIcon
                      icon={faImage}
                      size="sm"
                      color="white"
                      style={{ marginRight: '0.25rem' }}
                    />
                    {fileInfo.name}
                  </div>
                )}
          </div>

          <FontAwesomeIcon
            icon={faEdit}
            style={{ margin: '0 4px', cursor: 'pointer' }}
            color={isEdit ? '#ff3250' : '#aaa'}
            size="1x"
            onClick={() => {
              setIsEdit(!isEdit);
            }}
          />
        </div>
      </ItemContainer>
    );
  }
};

export default EditItem;

const AlignButtons = ({ style, setStyle }) => {
  const [current, setCurrent] = useState((style && style.textAlign) || 'left');
  const SELECT_COLOR = '#e6f4ea';

  return (
    <div style={{ display: 'flex', margin: '0.4rem 0' }}>
      <FontAwesomeIcon
        icon={faAlignLeft}
        style={{
          color: current === 'left' ? 'black' : '#aaa',
          margin: '0 2px',
          cursor: 'pointer',
          background: current === 'left' ? SELECT_COLOR : 'none',
          padding: '4px 4px',
          borderRadius: '4px'
        }}
        size="lg"
        onClick={() => {
          setCurrent('left');
          setStyle({ ...style, textAlign: 'left' });
        }}
      />
      <FontAwesomeIcon
        icon={faAlignCenter}
        style={{
          color: current === 'center' ? 'black' : '#aaa',
          margin: '0 2px',
          cursor: 'pointer',
          background: current === 'center' ? SELECT_COLOR : 'none',
          padding: '4px 4px',
          borderRadius: '4px'
        }}
        size="lg"
        onClick={() => {
          setCurrent('center');
          setStyle({ ...style, textAlign: 'center' });
        }}
      />
      <FontAwesomeIcon
        icon={faAlignRight}
        style={{
          color: current === 'right' ? 'black' : '#aaa',
          margin: '0 2px',
          cursor: 'pointer',
          background: current === 'right' ? SELECT_COLOR : 'none',
          padding: '4px 4px',
          borderRadius: '4px'
        }}
        size="lg"
        onClick={() => {
          setCurrent('right');
          setStyle({ ...style, textAlign: 'right' });
        }}
      />
    </div>
  );
};
