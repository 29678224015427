import React from 'react';
import styled from 'styled-components';
import Button from './Button';
import { withRouter } from 'react-router-dom';
import { MdSave } from 'react-icons/md';

const StyledLabel = styled.label`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  user-select: none;
  cursor: pointer;
  min-width: 180px;
  width: 30%;
  height: 34px;
  font-size: 14px;
  font-weight: bold;
  padding: 0 1rem;
  border-radius: 4px;
  text-align: center;
  transition: all 0.1s ease-in;
  color: #fff;
  background: #339af0;
  &:hover {
    opacity: 0.9;
  }
`;

function Form({ children, onSubmit, btn = '등록', requires, data, ...rest }) {
  const handleSubmit = e => {
    e.preventDefault();
    if (!requires) return onSubmit();
    const haveAllRequired = requires.map(item => !data[item] && true).includes(false);
    return haveAllRequired ? onSubmit() : alert('필수항목을 모두 작성해주세요.');
  };

  return (
    <form style={{ width: '100%' }} onSubmit={handleSubmit}>
      {children}
      <input type="submit" id="form-submit" style={{ display: 'none' }} />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          margin: '2.5rem 0'
        }}
      >
        <StyledLabel htmlFor="form-submit">
          <MdSave />
          &nbsp;
          {btn}
        </StyledLabel>
        <Button
          style={{ marginLeft: '0.25rem' }}
          type="normal"
          onClick={() => {
            rest.history.goBack();
          }}
        >
          뒤로
        </Button>
      </div>
    </form>
  );
}

export default withRouter(Form);
