import React, { ReactElement } from 'react';
import styled, { css, CSSProperties } from 'styled-components';

const StyledInput = styled.input`
  font-size: 14px;
  padding: 0.5rem 1rem;
  border: none;
  outline: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  min-width: 200px;
  @media (max-width: 480px) {
    min-width: 0;
    width: 40%;
  }
  ${({ disabled }) => css`
    color: ${disabled ? '#999' : '#333'};
    background: ${disabled ? '#efefef' : '#fff'};
  `}
  text-align: center;
  &:focus {
    text-align: left;
  }
  &:focus,
  :hover {
    box-shadow: 0 0 2px 0.5px #228be6;
  }
  &::placeholder {
    font-size: 12px;
    color: #ccc;
    text-align: center;
  }
`;

const TitleDiv = styled.div`
  min-width: 120px;
  @media (max-width: 480px) {
    min-width: 0;
    width: 40%;
  }
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0.25rem 0;
  width: 100%;
`;

type Props = {
  title?: string;
  placeholder?: string;
  defaultValue?: string;
  id?: string;
  type?: string;
  name?: string;
  value?: string;
  disabled?: boolean;
  required?: boolean;
  style?: CSSProperties;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent) => void;
  children: ReactElement;
};

const Input = ({
  title,
  placeholder,
  onChange,
  defaultValue,
  disabled,
  name,
  style,
  value,
  id,
  onKeyDown,
  type = 'text',
  required,
  children
}: Props) => {
  return title ? (
    <InputContainer>
      {title && (
        <TitleDiv>
          {title}
          {required && <span style={{ marginLeft: '0.25rem', color: '#ff3250' }}>*</span>}
        </TitleDiv>
      )}
      <StyledInput
        placeholder={placeholder}
        onChange={onChange}
        defaultValue={defaultValue}
        disabled={disabled}
        name={name}
        value={value}
        id={id}
        onKeyDown={onKeyDown}
        type={type}
        required={required}
        style={{ ...style }}
      />
      {children}
    </InputContainer>
  ) : (
    <StyledInput
      style={{ ...style }}
      placeholder={placeholder}
      onChange={onChange}
      defaultValue={defaultValue}
      disabled={disabled}
      name={name}
      value={value}
      id={id}
      onKeyDown={onKeyDown}
      type={type}
      required={required}
    />
  );
};

export default Input;
