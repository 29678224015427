import React, { useEffect, useContext, useState } from 'react';
import { FirebaseContext } from '../../firebase';
import { Button, Container, Loader, Table } from '../../components';
import { sortBy } from 'lodash';

const COUPON_INFO = {
  percent_10: '10% 할인',
  percent_15: '15% 할인',
  percent_30: '30% 할인',
  minus_1000: '1000원 할인',
  minus_3000: '3000원 할인',
};

const CouponList = () => {
  const { db } = useContext(FirebaseContext);
  const [data, setData] = useState(null);

  useEffect(() => {
    db.collection('coupons')
      .get()
      .then((snapshots) => {
        let result = [];
        snapshots.forEach((doc) => result.push({ ...doc.data(), id: doc.id }));
        setData(sortBy(result, ['type']));
      });
  }, [db]);

  return (
    <Container
      title="쿠폰관리"
      buttons={
        <>
          <Button type="normal" style={{ marginRight: '0.5rem' }} to="/coupons/add">
            +쿠폰추가
          </Button>
        </>
      }
    >
      {data ? (
        <Table
          data={data}
          perPage={30}
          index
          headers={[
            {
              title: '분류',
              value: 'type',
              render: ({ type }) => COUPON_INFO[type],
            },
            {
              title: '쿠폰번호',
              value: 'id',
            },
            {
              title: '사용여부',
              value: 'isUsed',
              render: ({ isUsed }) => (isUsed ? 'y' : 'n'),
            },
          ]}
          defaultPageSize={50}
        />
      ) : (
        <Loader />
      )}
    </Container>
  );
};

export default CouponList;
