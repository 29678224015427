import React, { useContext, useState, useEffect, useCallback } from 'react';
import Input from '../../components/Input';
import Button from '../../components/Button';
import { FirebaseContext } from '../../firebase';
import Div from '../../components/Div';
import Container from '../../components/Container';
import WordList from '../6_Word/WordListContainer';
import Modal from '../../components/Modal';
import toast from '../../components/Toast';
import Notice from '../../components/Notice';
import { sortBy } from 'lodash';
import { formatPrice } from '../../common/format';
import { UserStateContext } from '../../contexts/UserContext';

const EditOption = props => {
  const { id } = props.match.params;
  const { db } = useContext(FirebaseContext);
  const user = useContext(UserStateContext);
  const [data, setData] = useState(null);
  const [info, setInfo] = useState('');
  const [copyOpen, setCopyOpen] = useState(false);
  const [copyCode, setCopyCode] = useState();

  useEffect(() => {
    const optionRef = db.collection('options');
    db.collection('menues')
      .doc(id)
      .get()
      .then(doc => {
        const infoData = doc.data();

        db.collection('stores')
          .doc(infoData.store_id)
          .get()
          .then(storeDoc => {
            setInfo({ menuName: infoData.realName, storeName: storeDoc.data().realName });
          });
      })
      .catch(err => console.log(err));

    optionRef
      .where('menu_id', '==', id)
      .get()
      .then(snapshots => {
        let result = [];
        snapshots.forEach(doc => result.push({ ...doc.data(), id: doc.id }));
        setData(result);
      })
      .catch(err => console.log(err));
  }, [db, id]);

  const loadOptionData = useCallback(() => {
    const optionRef = db.collection('options');
    optionRef
      .where('menu_id', '==', id)
      .get()
      .then(snapshots => {
        let result = [];
        snapshots.forEach(doc => result.push({ ...doc.data(), id: doc.id }));
        setData(result);
      })
      .catch(err => console.log(err));
  }, [db, id]);

  const setRadio = newData => {
    const changeList = [];
    if (newData.radio) {
      const isHaveDefault = newData.list.map(item => item.default).includes(true);
      if (isHaveDefault) {
        newData.list.map(item => changeList.push({ ...item, must: true }));
      } else {
        alert('필수선택그룹은 반드시 기본선택 옵션 항목이 있어야합니다.');
        return null;
      }
    } else {
      newData.list.map(item => changeList.push({ ...item, must: false }));
    }
    return { ...newData, list: changeList };
  };

  const saveOption = newData => {
    const changedData = setRadio(newData);
    const optionRef = db.collection('options');
    if (changedData) {
      optionRef
        .doc(newData.id)
        .update(changedData)
        .then(() => {
          toast('업데이트 되었습니다.');
          setData(null);
          loadOptionData();
        });
    }
  };

  const deleteCurrentOption = optionId => {
    const result = window.confirm('정말 삭제하시겠습니까?');
    const optionRef = db.collection('options');
    if (result)
      optionRef
        .doc(optionId)
        .delete()
        .then(() => {
          toast('업데이트 되었습니다.');
          setData(data.filter(item => item.id !== optionId));
        });
  };

  const copyOtherMenuOptions = async () => {
    if (!copyCode) return alert('메뉴코드를 입력하세요.');
    const optionRef = db.collection('options');

    const toCopyData = await optionRef
      .where('menu_id', '==', copyCode)
      .get()
      .then(snapshots => {
        let result = [];
        snapshots.forEach(doc => result.push({ ...doc.data(), id: doc.id, menu_id: id }));
        return result;
      })
      .catch(err => console.log(err));
    const batch = db.batch();
    toCopyData.forEach(item => {
      batch.set(optionRef.doc(), item);
    });
    batch
      .commit()
      .then(() => {
        loadOptionData();
        toast('업데이트되었습니다.');
        setCopyOpen(false);
      })
      .catch(err => {
        console.log(err);
      });
  };

  return (
    <Container
      title="옵션관리"
      navigator="배달업체관리 > 업체정보수정 > 업체메뉴수정 > 옵션관리"
      buttons={
        user &&
        user.authLevel === 'ROOT' && (
          <>
            <Button type="normal" onClick={() => setCopyOpen(true)}>
              메뉴옵션복사
            </Button>
            <Button type="normal" to={`/store/addoption/${id}`}>
              +옵션그룹추가
            </Button>
          </>
        )
      }
    >
      {copyOpen && (
        <Modal small onClose={() => setCopyOpen(false)}>
          <Notice>복사할메뉴코드를입력하세요.</Notice>
          <Input onChange={e => setCopyCode(e.target.value)} />
          <Button
            onClick={() => {
              copyOtherMenuOptions();
            }}
          >
            실행
          </Button>
        </Modal>
      )}
      {info && (
        <>
          <Div title="업체명" style={{ margin: 0 }}>
            {info.storeName}
          </Div>
          <Div title="메뉴명" style={{ margin: 0 }}>
            <b>{info.menuName}</b> <span style={{ color: '#aaa' }}>[{id}]</span>
          </Div>
          <Notice>
            편집 및 추가 후 반드시 저장을 눌러야 수정한 정보가 반영됩니다. <br />
            옵션의 순서는 필수선택그룹이 먼저 노출 되고 옵션순서는 그 다음에 적용됩니다.
          </Notice>
        </>
      )}

      {data ? (
        <>
          {sortBy(data, 'index')
            .filter(option => option.radio === true)
            .map((option, index) => (
              <OptionItem
                key={`option-group-${index}`}
                data={option}
                saveOption={saveOption}
                deleteCurrentOption={deleteCurrentOption}
              />
            ))}
          {sortBy(data, 'index')
            .filter(option => option.radio === false)
            .map((option, index) => (
              <OptionItem
                key={`option-group-${index}`}
                data={option}
                saveOption={saveOption}
                deleteCurrentOption={deleteCurrentOption}
              />
            ))}
        </>
      ) : (
        <>loading...</>
      )}
      {data && data.length === 0 && '아직 옵션이 없습니다.'}
    </Container>
  );
};

export default EditOption;

const OptionItem = ({ data, saveOption, deleteCurrentOption }) => {
  const [onEdit, setOnEdit] = useState(false);
  const [editedData, setEditedData] = useState(data);
  const [wordOpen, setWordOpen] = useState(false);
  const [groupOpen, setGroupOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(false);
  const user = useContext(UserStateContext);

  const handleChange = e => {
    if (e.target.name === 'radio') {
      setEditedData({ ...editedData, [e.target.name]: e.target.checked });
    } else {
      setEditedData({ ...editedData, [e.target.name]: e.target.value });
    }
  };
  const handleListChange = (e, index) => {
    let editedList = [...editedData.list];
    editedList[index] = {
      ...editedList[index],
      [e.target.name]:
        e.target.name === 'price'
          ? parseInt(e.target.value)
          : e.target.name === 'default'
          ? e.target.checked
          : e.target.value
    };
    setEditedData({ ...editedData, list: editedList });
  };

  const handleListWordRegister = (name, name_id, index) => {
    let editedList = [...editedData.list];
    editedList[index] = {
      ...editedList[index],
      name,
      name_id
    };
    setEditedData({ ...editedData, list: editedList });
  };

  const addOption = () => {
    setEditedData({
      ...editedData,
      list: editedData.list.concat({
        name: '',
        price: ''
      })
    });
  };

  const deleteOption = deleteIndex => {
    setEditedData({ ...editedData, list: [] });
    setTimeout(() => {
      setEditedData({
        ...editedData,
        list: editedData.list.filter((item, index) => index !== deleteIndex)
      });
    }, 50);
  };

  const switchOption = (switchIndex, direction) => {
    let editedList = [...editedData.list];
    setEditedData({ ...editedData, list: [] });
    if (direction === 'up') {
      editedList[switchIndex] = editedList[switchIndex - 1];
      editedList[switchIndex - 1] = editedData.list[switchIndex];
    } else if (direction === 'down') {
      editedList[switchIndex] = editedList[switchIndex + 1];
      editedList[switchIndex + 1] = editedData.list[switchIndex];
    }
    setTimeout(() => {
      setEditedData({ ...editedData, list: editedList });
    }, 50);
  };

  return (
    <>
      <div
        style={{
          border: '1px solid #ccc',
          padding: '0.5rem 1rem',
          marginTop: '0.5rem',
          borderRadius: '4px'
        }}
      >
        <div
          style={{
            borderBottom: '1px solid #ccc',
            padding: '0.5em 0',
            marginBottom: '0.5em'
          }}
        >
          {user && user.authLevel === 'ROOT' && (
            <Button
              type="small"
              style={{ color: '#228be6' }}
              onClick={() => {
                setOnEdit(!onEdit);
                onEdit && saveOption(editedData);
              }}
            >
              {onEdit ? '저장' : '편집'}
            </Button>
          )}
          {onEdit && (
            <Button
              type="small"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setOnEdit(!onEdit);
              }}
            >
              취소
            </Button>
          )}
          {onEdit && (
            <Button
              type="small"
              style={{ color: 'red', cursor: 'pointer', marginLeft: '3rem' }}
              onClick={() => {
                setOnEdit(!onEdit);
                deleteCurrentOption(editedData.id);
              }}
            >
              삭제
            </Button>
          )}
        </div>

        {onEdit ? (
          <>
            <Input
              defaultValue={editedData.groupName}
              title={'옵션그룹명'}
              name="groupName"
              onChange={handleChange}
            />
            <span
              onClick={() => {
                setGroupOpen(true);
              }}
            >
              <Input value={editedData.groupName_id} title={'옵션그룹명번역'} disabled />
            </span>

            <Input
              defaultValue={editedData.index}
              title={'옵션순서 (임시 숫자만입력)'}
              name="index"
              onChange={e => {
                setEditedData({ ...editedData, [e.target.name]: parseInt(e.target.value) });
              }}
            />
            <Div title="필수여부">
              <input
                type="checkbox"
                defaultChecked={editedData.radio}
                name="radio"
                onChange={handleChange}
              />
            </Div>
            <table
              style={{
                width: '100%',
                borderCollapse: 'collapse',
                border: '1px solid #ccc',
                borderRadius: '10px',
                textAlign: 'center'
              }}
            >
              <thead>
                <tr
                  style={{
                    border: '1px solid #ccc',
                    background: '#e6e6e6'
                  }}
                >
                  <th>순서</th>
                  <th>옵션명</th>
                  <th>옵션번역</th>
                  <th>가격</th>
                  <th>기본선택</th>
                  <th> </th>
                </tr>
              </thead>
              <tbody>
                {editedData.list.map((item, index) => (
                  <>
                    <tr>
                      <td>{index + 1}</td>
                      <td>
                        <Input
                          defaultValue={item.realName}
                          name="realName"
                          onChange={e => {
                            handleListChange(e, index);
                          }}
                        />
                      </td>
                      <td
                        onClick={() => {
                          setSelectedIndex(index);
                          setWordOpen(true);
                        }}
                      >
                        <Input
                          value={item.name_id && `${item.name} (${item.name_id})`}
                          name="name"
                          disabled
                        />
                      </td>
                      <td>
                        <Input
                          defaultValue={item.price}
                          name="price"
                          onChange={e => {
                            handleListChange(e, index);
                          }}
                        />
                      </td>
                      <td>
                        {editedData.radio && (
                          <input
                            type="checkbox"
                            defaultChecked={item.default}
                            name="default"
                            style={{ marginLeft: '0.5rem' }}
                            onChange={e => {
                              handleListChange(e, index);
                            }}
                          />
                        )}
                      </td>
                      <td>
                        <div
                          style={{
                            width: '30%',
                            display: 'flex',
                            justifyContent: 'flex-start',
                            marginLeft: '0.5rem'
                          }}
                        >
                          <Button
                            type="small"
                            onClick={() => {
                              deleteOption(index);
                            }}
                          >
                            ―
                          </Button>
                          {index !== 0 && (
                            <Button
                              type="small"
                              onClick={() => {
                                switchOption(index, 'up');
                              }}
                            >
                              ↑
                            </Button>
                          )}
                          {index !== editedData.list.length - 1 && (
                            <Button
                              type="small"
                              onClick={() => {
                                switchOption(index, 'down');
                              }}
                            >
                              ↓
                            </Button>
                          )}
                        </div>
                      </td>
                    </tr>
                  </>
                ))}
              </tbody>
            </table>
            <br />
            <Button type="small" onClick={addOption}>
              +옵션추가
            </Button>
          </>
        ) : (
          <>
            <Div title={'옵션그룹명'} style={{ margin: 0, padding: 0 }}>
              {editedData.groupName}
            </Div>
            <Div title={'옵션그룹명번역'} style={{ margin: 0, padding: 0 }}>
              {editedData.groupName_id}
            </Div>
            <Div title={'순서'} style={{ margin: 0, padding: 0 }}>
              {editedData.index}
            </Div>
            <Div title="필수여부" style={{ margin: 0, padding: 0 }}>
              <b>{editedData.radio ? 'Y' : 'N'}</b>
            </Div>
            <table
              style={{
                width: '100%',
                borderCollapse: 'collapse',
                border: '1px solid #ccc',
                borderRadius: '10px',
                textAlign: 'center'
              }}
            >
              <thead>
                <tr
                  style={{
                    border: '1px solid #ccc',
                    background: '#e6e6e6'
                  }}
                >
                  <th>순서</th>
                  <th>옵션명</th>
                  <th>옵션번역</th>
                  <th>가격</th>
                  <th>기본선택</th>
                </tr>
              </thead>
              <tbody>
                {editedData.list.map((item, index) => (
                  <tr key={`list_${index}`} style={{ border: '1px solid #ccc' }}>
                    <td style={{ padding: '0.4em 0' }}>{index + 1}</td>
                    <td>{item.realName}</td>
                    <td>{item.name_id && `${item.name} (${item.name_id})`}</td>
                    <td>{formatPrice(item.price)}</td>
                    <td>{editedData.radio && item.default && 'Y'}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
      </div>
      {wordOpen && (
        <Modal
          onClose={() => {
            setWordOpen(false);
          }}
        >
          <WordList
            type="옵션"
            register={(name_id, name) => {
              handleListWordRegister(name, name_id, selectedIndex);
              setWordOpen(false);
            }}
          />
        </Modal>
      )}
      {groupOpen && (
        <Modal
          onClose={() => {
            setGroupOpen(false);
          }}
        >
          <WordList
            type="옵션"
            register={name_id => {
              setEditedData({ ...editedData, groupName_id: name_id });
              setGroupOpen(false);
            }}
          />
        </Modal>
      )}
    </>
  );
};
