import React from 'react';
import { useFirebase } from '../../common/fetch';
import { Link } from 'react-router-dom';
import Container from '../../components/Container';
import Loader from '../../components/Loader';
import Table from '../../components/Table/Table';
import { formatBn, formatPhone } from '../../common/format';
import { Status } from '../../components';
import { sortBy } from 'lodash';

const UserList = () => {
  const [{ data }] = useFirebase('users', null, null, ['level', '==', 'partner']);
  const columns = [
    {
      title: '구분',
      value: 'businessType',
      width: '8%',
    },
    {
      title: '사업자명',
      value: 'businessName',
      render: (row) => <Link to={`/user/edit/${row.id}`}>{row.businessName || '?'}</Link>,
    },
    {
      title: '이름',
      value: 'name',
    },
    {
      title: '연락처',
      value: 'phone',
      render: (row) => formatPhone(row.phone),
    },
    {
      title: '가입일',
      value: 'email',
    },
    {
      title: '사업자번호',
      value: 'bn',
      render: (row) => formatBn(row.bn),
    },
    {
      title: '가입일',
      value: 'createdAt',
      render: (row) => row.createdAt && row.createdAt.toDate().toLocaleDateString(),
    },
    {
      title: '관리자승인여부',
      accessor: 'isAdminValid',
      render: (row) =>
        row.isAdminValid ? <Status type="SUCCESS" msg="승인" /> : <Status type="WAIT" msg="대기" />,
      notSearch: true,
    },
  ];

  return (
    <>
      {data ? (
        <Container title="회원관리" navigator="회원목록">
          <Table
            index
            perPage={30}
            data={sortBy(data, [(item) => item.createdAt && item.createdAt.toDate()]).reverse()}
            headers={columns}
          />
        </Container>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default UserList;
