import React, { useEffect, useContext, useState } from 'react';
import { FirebaseContext } from '../../firebase';
import { Button, Container, Loader, Table } from '../../components';
import { sortBy } from 'lodash';
import { jsonToSheet } from '../../common/utils';

const TagList = () => {
  const { db } = useContext(FirebaseContext);
  const [data, setData] = useState(null);

  useEffect(() => {
    db.collection('tags')
      .get()
      .then(snapshots => {
        let result = [];
        snapshots.forEach(doc => result.push({ ...doc.data(), id: doc.id }));
        setData(sortBy(result, ['type']));
      });
  }, [db]);

  return (
    <Container
      title="태그관리"
      buttons={
        <>
          <Button
            style={{ background: '#0F9D58', color: 'white' }}
            onClick={() => {
              const arr = [];
              console.log(data);
              data.forEach(item => arr.push(item));
              jsonToSheet(arr, '등록된태그목록');
            }}
          >
            엑셀저장
          </Button>
          {/* <Button to={`/words/csv`} type="normal">
            일괄업로드
          </Button> */}
          <Button type="normal" style={{ marginRight: '0.5rem' }} to="/tags/add">
            +태그추가
          </Button>
        </>
      }
    >
      {data ? (
        <Table
          data={data}
          perPage={30}
          index
          headers={[
            {
              title: '분류',
              value: 'type',
              id: 'type'
            },
            {
              title: '한국어',
              value: 'kr'
            },
            {
              title: '영어',
              value: 'en'
            },
            {
              title: '중국어',
              value: 'cn'
            },
            {
              title: '일본어',
              value: 'jp'
            },
            {
              title: '태국어',
              value: 'th'
            },
            {
              title: '',
              value: 'id',
              width: '5%',
              notSearch: true,
              render: row => (
                <Button to={`/tags/edit/${row.id}`} type="small">
                  수정
                </Button>
              )
            }
          ]}
          defaultPageSize={50}
        />
      ) : (
        <Loader />
      )}
    </Container>
  );
};

export default TagList;
