import React, { useState, useContext, useRef } from 'react';
import styled from 'styled-components';
import { MdAccountCircle, MdKeyboardArrowDown } from 'react-icons/md';
import { UserStateContext } from '../contexts/UserContext';
import { useOnClickOutside } from '../common/hooks';
import { Link } from 'react-router-dom';

const Top = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px;
  font-size: 14px;
  color: #888;
  cursor: pointer;
  @media (max-width: 480px) {
    width: 160px;
    font-size: 12px;
  }
`;

const VerticalMenu = styled.div`
  display: flex;
  flex-direction: column;
  color: #aaa;
  font-size: 14px;
  background: #fff;
  position: absolute;
  top: 47px;
  right: 0px;
  border: 1px solid #e6e6e6;
  height: 0;
  border-top: none;
  padding: 0 0.5rem;
  overflow: hidden;
  font-weight: bold;
  box-shadow: 2px 6px 6px 0px rgba(0, 0, 0, 0.2);
  opacity: ${({ open }) => (open ? 1 : 0)};
  transition: all 0.2s ease-out;
  -webkit-transition: all 0.2s ease-out;

  .item {
    cursor: pointer;
    display: flex;
    min-height: 38px;
    width: 180px;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #e6e6e6;
    color: #aaa;
    a {
      color: inherit;
    }
    &:last-child {
      border: none;
    }
    &:hover,
    :active {
      color: #ff3250;
    }
  }
`;

function UserMenu({ onLogout }) {
  const [open, setOpen] = useState(false);
  const user = useContext(UserStateContext);
  const ref = useRef();

  useOnClickOutside(ref, () => {
    setOpen(false);
    animateOpen(true);
  });

  const animateOpen = open => {
    const height = 38;
    const ref = document.getElementById('section');
    ref.style.height = !open ? height * 3 + 'px' : '0px';
  };

  return (
    <div ref={ref}>
      {user && (
        <Top
          open={open}
          onClick={() => {
            setOpen(!open);
            animateOpen(open);
          }}
        >
          <MdAccountCircle />{' '}
          <span style={{ fontWeight: 'bold', margin: '0 0.25rem' }}>{user.name}</span>
          <span style={{ fontSize: '12px' }}>{user.companyName}</span>
          <MdKeyboardArrowDown
            style={{
              transform: open ? `rotate(180deg) translateY(1px)` : 'rotate(0)',
              transition: 'all 0.2s ease-out'
            }}
          />
        </Top>
      )}
      <VerticalMenu open={open} id="section">
        {
          <>
            <Link to={`/partner/edit/${user && user.id}`} className="item">
              내정보
            </Link>
            <Link to="/change" className="item">
              비밀번호변경
            </Link>
            <div className="item" onClick={onLogout}>
              로그아웃
            </div>
          </>
        }
      </VerticalMenu>
    </div>
  );
}

export default UserMenu;
