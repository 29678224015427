import React, { useState, useContext, useRef, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { FirebaseContext } from '../../firebase';
import { Link } from 'react-router-dom';
import logo from '../../images/logo.svg';
import Spinner from '../../components/Spinner';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 70vh;
`;

const Input = styled.input`
  text-align: left;
  border: none;
  padding: 1rem;
  font-size: 16px;
  max-width: 400px;
  width: 80%;
  border-radius: 24px;
  background: #f8f8f8;
  color: #55607d;
  font-weight: bold;
  line-height: 1.13;
  margin-top: 8px;
  &::placeholder {
    color: #aaa;
    font-weight: normal;
  }
  &:focus {
    outline: none;
    box-shadow: 0 0 4px 0px #ff3250;
  }
`;

const Button = styled.div`
  margin-top: 8px;
  padding: 0.8rem;
  border-radius: 24px;
  background: ${props => (props.active ? '#ff3250' : '#aaa')};
  color: white;
  font-size: 18px;
  max-width: 400px;
  width: 80%;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
  min-height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover,
  :active {
    opacity: 0.7;
  }
`;

const Login = props => {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [pw, setPw] = useState('');
  const { auth } = useContext(FirebaseContext);
  const [error, setError] = useState('');
  const pwRef = useRef();

  const onBNInput = e => {
    setEmail(e.target.value);
  };

  const onLogin = useCallback(
    (email, pw) => {
      setIsLoading(true);
      setError('');
      auth.setPersistence('local').then(() =>
        auth
          .signInWithEmailAndPassword(email, pw)
          .then(_ => {
            setIsLoading(false);
            props.history.push('/home');
          })
          .catch(err => {
            setIsLoading(false);
            switch (err && err.code) {
              case 'auth/user-not-found':
                return setError('이메일이 존재하지 않습니다.');
              case 'auth/invalid-email':
                return setError('이메일 형식이 올바르지 않습니다.');
              case 'auth/wrong-password':
                return setError('비밀번호가 일치하지 않습니다.');
              default:
                setError(err.message);
            }
          })
      );
    },
    [auth, props.history]
  );

  useEffect(() => {
    //
  }, []);

  return (
    <Container>
      <img src={logo} alt="" style={{ width: '170px' }} />
      <div style={{ margin: '0.5rem 0 2rem 0', fontSize: '20px', fontWeight: 'bold' }}>
        델리퀵 관리자시스템
      </div>
      <Input placeholder="이메일주소" onChange={onBNInput} value={email} />

      <Input
        placeholder="비밀번호"
        type="password"
        style={{ marginTop: '1.5rem' }}
        ref={pwRef}
        onKeyDown={e => {
          if (e.keyCode === 13) {
            onLogin(email, pw);
          }
        }}
        onChange={e => {
          setPw(e.target.value);
        }}
      />
      <div
        style={{
          padding: '0.2rem',
          height: '15px',
          color: '#fa5252',
          fontSize: '12px',
          marginBottom: '0.2rem'
        }}
      >
        {error}
      </div>
      <Button
        style={{ marginTop: '1rem' }}
        active
        onClick={() => {
          onLogin(email, pw);
        }}
      >
        {isLoading ? <Spinner /> : '로그인'}
      </Button>
      <div style={{ marginTop: '2rem', fontSize: '12px', color: '#ff3250' }}>
        <Link to="/login/find">비밀번호를 분실하였습니다.</Link>
      </div>
    </Container>
  );
};

export default Login;
