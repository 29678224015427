import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { FirebaseContext } from '../../firebase';
import Container from '../../components/Container';
import Input from '../../components/Input';
import FileInput from '../../components/FileInput';
import Div from '../../components/Div';
import Button from '../../components/Button';
import toast from '../../components/Toast';
import { uploadFile } from '../../common/fetch';
import { UserStateContext } from '../../contexts/UserContext';
import Dropdown from '../../components/Dropdown';
import Notice from '../../components/Notice';
import Loader from '../../components/Loader';

const StyledTextarea = styled.textarea`
  width: 100%;
  height: 300px;
  border: 1px solid #ccc;
  resize: none;
  margin-top: 1rem;
  border-radius: 4px;
  font-size: 14px;
  outline-color: #228be6;
  line-height: 1.6;
`;

function AddNotice(props) {
  const { db } = useContext(FirebaseContext);
  const user = useContext(UserStateContext);
  const [data, setData] = useState({ type: '메뉴수정', status: '미처리' });
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const getNewId = () => {
    const boards = db.collection('boards');
    return boards
      .orderBy('createdAt', 'desc')
      .limit(1)
      .get()
      .then(snapshots => (snapshots.empty ? 1 : parseInt(snapshots.docs[0].id) + 1));
  };

  const onChangeInput = e => setData({ ...data, [e.target.name]: e.target.value });
  const onChangeContent = e =>
    setData({ ...data, contents: e.target.value.replace(/\n/g, '<br/>') });

  const onRegister = async () => {
    if (!data.type) return alert('분류를 선택하세요.');
    setIsLoading(true);
    const id = await getNewId();
    const newRef = db.collection('boards').doc(`${id}`);

    let newData = {
      ...data,
      user: {
        name: user.name,
        userId: user.id,
        companyName: user.companyName || ''
      },
      createdAt: new Date(),
      viewCount: 0,
      commentsCount: 0,
      notice: user.authLevel === 'ROOT' ? true : false
    };

    if (file) {
      const uploaded = await uploadFile(`notices/${id}`, file, 'fileUrl');
      newData.file = { fileUrl: uploaded.url, fileInfo: { name: file.name, size: file.size } };
    }

    newRef.set(newData).then(_ => {
      toast('업데이트되었습니다.');
      setIsLoading(false);
      props.history.push(`/notice/view/${id}`);
    });
  };

  return (
    <Container title="공지사항" navigator="게시글 작성">
      {user && user.authLevel !== 'ROOT' && (
        <Div title="분류">
          <Dropdown
            name="type"
            onChange={onChangeInput}
            defaultValue="메뉴수정"
            options={[
              {
                text: '메뉴수정'
              },
              {
                text: '업체이관'
              },
              {
                text: '업체해지'
              },
              {
                text: '기타'
              }
            ]}
          />
        </Div>
      )}
      {user && user.authLevel === 'ROOT' && (
        <>
          <Input
            title="공지제목"
            name="title"
            onChange={onChangeInput}
            style={{ width: '250px' }}
          />
          <Notice>(관리자 전용) 공지사항으로 등록됩니다. </Notice>
        </>
      )}

      <StyledTextarea name="contents" onChange={onChangeContent} />

      <Div title="첨부파일">
        <FileInput
          onChange={e => {
            const file = e.target.files[0];
            if (!file) return;

            if (file.size > 10485760) {
              return alert('첨부파일은 10MB 미만으로 가능합니다.');
            }
            setFile(file);
          }}
        />
      </Div>
      {isLoading ? (
        <Loader />
      ) : (
        <Button
          onClick={() => {
            onRegister();
          }}
        >
          등록
        </Button>
      )}
    </Container>
  );
}

export default AddNotice;
