import axios from 'axios';
import { format } from 'date-fns';

const BASE_URL =
  process.env.NODE_ENV === 'production'
    ? `https://us-central1-deliquick-93b6b.cloudfunctions.net/api`
    : `http://localhost:5000/deliquick-93b6b/us-central1/api`;

export const sendPushMsg = ({
  store_id,
  storeName,
  orderNumber,
  hotelName,
  cart,
  address,
  storeStatus
}) => {
  return axios
    .post(`${BASE_URL}/order/sendkakao`, {
      id: store_id,
      storeName,
      orderNumber,
      hotelName,
      cart,
      address,
      storeStatus,
      dateString: new Date().toLocaleString('ko-KR')
    })
    .then(result => result)
    .catch(err => err);
};

export const getUniqueOrderNo = code => {
  const now = format(new Date(), 'yyMMdd');
  const orderNo = `D${code.substr(0, 1)}${now}_${randomId(5, 'aA0')}`;
  return orderNo;
};

const possibilities = {
  lowerCased: 'abcdefghijklmnopqrstuvwxyz',
  capitals: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
  numbers: '0123456789',
  special: "~!@#$%^&()_+-={}[];',"
};

export const randomId = (len, pattern) => {
  if (!len) len = 30;
  if (!pattern) pattern = 'aA0';

  let chars = '';

  pattern.split('').forEach(a => {
    if (!isNaN(parseInt(a))) {
      chars += possibilities.numbers;
    } else if (/[a-z]/.test(a)) {
      chars += possibilities.lowerCased;
    } else if (/[A-Z]/.test(a)) {
      chars += possibilities.capitals;
    } else {
      chars += possibilities.special;
    }
  });

  let result = '';

  for (let i = 0; i < len; i++) {
    result += chars.charAt(Math.floor(Math.random() * chars.length));
  }

  return result;
};

// const checkOrderNumberValid = orderNumber => {
//   return db
//     .collection('orders')
//     .doc(orderNumber)
//     .get()
//     .then(doc => !doc.exists);
// };
