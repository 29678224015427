import React, { useContext, useState } from 'react';
import { FirebaseContext } from '../../firebase';
import { generateKeywords } from '../../common/utils';
import { Button, Container, Input, Form, Tag, Div, Modal, FileInput } from '../../components';
import SelectTag from '../7_Tags/SelectTag';
import toast from '../../components/Toast';
import { uploadFile } from '../../common/fetch';

const AddMenuDB = props => {
  const { db } = useContext(FirebaseContext);
  const [data, setData] = useState(null);
  const [isTagOpen, setTagOpen] = useState(false);
  const [file, setFile] = useState(null);

  const onChangeInput = e => {
    setData({
      ...data,
      [e.target.name]: e.target.value
    });
  };

  const onEdit = async () => {
    const photo = await uploadFile('menus', file, 'photo');
    db.collection('menudb')
      .add({ ...data, photo: photo || '', keywords: generateKeywords(data.kr) })
      .then(() => {
        toast('업데이트 되었습니다.');
        props.history.push(`/words`);
      });
  };

  return (
    <>
      <Container title="시스템메뉴관리" navigator="시스템메뉴목록 > 시스템메뉴추가">
        <Form onSubmit={onEdit}>
          <Input title={'코드'} disabled placeholder="자동생성" />
          <Input title={'한국어'} onChange={onChangeInput} name="kr" required />
          <Input title={'로마자'} onChange={onChangeInput} name="roman" required />
          <Input title={'영어'} onChange={onChangeInput} name="en" required />
          <Input title={'중국어'} onChange={onChangeInput} name="cn" />
          <Input title={'일본어'} onChange={onChangeInput} name="jp" />
          <Input title={'태국어'} onChange={onChangeInput} name="th" />
          <Div title={'대표사진'}>
            <div style={{ marginBottom: '0.5rem' }}>
              <div
                className="photo"
                style={{
                  backgroundColor: '#dfdfdf',
                  backgroundImage: data && `url(${data.photo})`
                }}
              >
                {data && !data.photo && '사진 없음'}
              </div>
            </div>
            <FileInput
              onChange={e => {
                const file = e.target.files[0];
                if (!file) return;
                if (file.type.split('/')[0] !== 'image')
                  return alert('이미지파일만 업로드 가능합니다.');
                let reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = e => {
                  setData({ ...data, photo: e.target.result });
                  setFile(file);
                };
              }}
            />
            <span />
            <Button
              type="normal"
              onClick={() => {
                setData({ ...data, photo: '' });
              }}
            >
              사진삭제
            </Button>
          </Div>
          <Div title={'태그(설명)'}>
            {data && data.tags && data.tags.map(tag => <Tag id={tag} />)}
          </Div>
          <span />
          <Button
            style={{
              marginLeft: '120px',
              background: '#339af0'
            }}
            onClick={() => {
              setTagOpen(true);
            }}
          >
            태그등록
          </Button>
        </Form>

        {/* 태그입력용 */}
        {isTagOpen && (
          <Modal
            onClose={() => {
              setTagOpen(false);
            }}
          >
            <SelectTag
              current={[]}
              register={tags => {
                setData({
                  ...data,
                  tags
                });
                setTagOpen(false);
              }}
            />
          </Modal>
        )}
      </Container>
    </>
  );
};

export default AddMenuDB;
