import React, { useCallback, useContext } from 'react';
import NoticeBoard from '../../components/Board/NoticeBoard';
import Container from '../../components/Container';
import Button from '../../components/Button';
import { MdModeEdit } from 'react-icons/md';
import { useFirebase } from '../../common/fetch';
import Loader from '../../components/Loader';
import { UserStateContext } from '../../contexts/UserContext';

function NoticeList() {
  const [{ data, db }] = useFirebase('boards', null, null, ['notice', '==', true]);
  const user = useContext(UserStateContext);

  const countUp = useCallback(
    id => {
      const ref = db.collection('boards').doc(id);

      db.runTransaction(t => {
        return t.get(ref).then(doc => {
          let viewCount = doc.data().viewCount + 1;
          t.update(ref, { viewCount });
        });
      });
    },
    [db]
  );

  switch (user && user.authLevel) {
    case 'ROOT':
      return (
        <Container
          title="공지사항"
          navigator="게시판"
          buttons={
            <Button type="normal" to="/notice/add">
              <MdModeEdit /> 글쓰기
            </Button>
          }
        >
          {data && (
            <NoticeBoard
              data={data.map(item => ({ ...item, createdAt: item.createdAt.toDate() }))}
              perPage={15}
              countUp={countUp}
              themeColor="#ff3250"
              notice
            />
          )}
        </Container>
      );
    case 'NORMAL':
      return (
        <Container title="공지사항" navigator="게시판">
          {data && (
            <NoticeBoard
              data={data
                .filter(item => item.notice)
                .concat(data.filter(item => item.user.userId === user.id))
                .map(item => ({ ...item, createdAt: item.createdAt.toDate() }))}
              perPage={15}
              countUp={countUp}
              themeColor="#ff3250"
              notice
            />
          )}
        </Container>
      );

    default:
      return <Loader />;
  }
}

export default NoticeList;
