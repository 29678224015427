import React, { useState, useContext, useMemo, useEffect } from 'react';
import { FirebaseContext } from '../../firebase';
import Container from '../../components/Container';
import Input from '../../components/Input';
import Div from '../../components/Div';
import Button from '../../components/Button';
import Form from '../../components/Form';
import Modal from '../../components/Modal';
import Address from '../../components/Address';
import FileInput from '../../components/FileInput';
import { uploadFile } from '../../common/fetch';
import Loader from '../../components/Loader';
import styled from 'styled-components';
import { useChangeLog } from '../../common/hooks';
import LogList from '../../components/LogList';

const Gap = styled.div`
  margin: 1.5rem 0;
`;

function EditPartner(props) {
  const { id } = props.match.params;
  const { db } = useContext(FirebaseContext);

  const [data, setData] = useState();

  const [addressOpen, setAddressOpen] = useState(false);
  const [files, setFiles] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const [{ getChangeLog, onChangeDataToLog }] = useChangeLog(data, true);

  useEffect(() => {
    const loadUserData = () => {
      return db
        .collection('users')
        .doc(id)
        .get()
        .then(doc => doc.data());
    };

    const loadOneUser = docId => {
      return db
        .collection('users')
        .doc(docId)
        .get()
        .then(doc => doc.data());
    };

    const loadData = async () => {
      const result = await loadUserData();
      if (result.group) {
        const promises = result.group.map(id => loadOneUser(id));
        const [depth_1, depth_2] = await Promise.all(promises);
        setData({
          ...result,
          depth_1: depth_1 && depth_1.companyName,
          depth_2: depth_2 && depth_2.companyName
        });
      }
      setData(result);
    };

    loadData();
  }, [db, id]);

  const showAddress = useMemo(() => {
    return data && data.address && `${data.address.road || ''} ${data.address.detail || ''}`;
  }, [data]);

  const onInputChange = e => {
    const { name, value } = e.target;
    name === 'depth'
      ? setData({ ...data, [name]: parseInt(value) })
      : setData({ ...data, [name]: value });
    onChangeDataToLog(name, name === 'depth' ? parseInt(value) : value);
  };

  const onRegist = async () => {
    setIsLoading(true);
    let newData = { ...data, logs: [...(data.logs || []), getChangeLog()] };
    delete newData.pw;

    // 파일 업로드 처리
    let fileArray;
    if (files) {
      fileArray = [];
      for (const name in files) {
        fileArray.push({ name, file: files[name] });
      }
    }

    if (fileArray) {
      const promises = fileArray.map(item => uploadFile(`users/${id}`, item.file, item.name));
      const uploadResult = await Promise.all(promises);
      uploadResult.forEach(item => {
        newData[item.id] = item.url;
      });
    }
    db.collection('users')
      .doc(id)
      .update({
        ...newData,
        editedAt: new Date()
      })
      .then(_ => {
        props.history.push(`/partner`);
      })
      .catch(err => {
        console.log(err);
      });

    setIsLoading(false);
  };

  const onFileChange = e => {
    const file = e.target.files[0];
    if (!file) return;
    if (file.size > 10 * 1024 * 1024) {
      alert('10MB 이하의 파일만 업로드 가능합니다.');
      return null;
    }
    setFiles({ ...files, [e.target.name]: file });
  };

  const depthArray = ['본사', '총판', '대리점', '영업사원'];

  return (
    <Container title="협력사관리" navigator="협력사목록 > 협력사 상세/수정">
      {data ? (
        <>
          <Form
            btn="저장"
            onSubmit={_ => {
              if (isLoading) return;
              onRegist();
            }}
          >
            <Input title="등급" defaultValue={depthArray[data.depth]} disabled />
            {data.depth > 1 && <Input title="총판" defaultValue={data.depth_1} disabled />}
            {data.depth > 2 && <Input title="대리점" defaultValue={data.depth_2} disabled />}

            <Gap />

            <Input
              title="이메일"
              defaultValue={data.email}
              type="email"
              name="email"
              disabled
              required
            />
            <Input
              title="협력사명"
              defaultValue={data.companyName}
              onChange={onInputChange}
              name="companyName"
              required
              disabled={data.depth === 3}
            />
            <Input
              title="대표자명"
              defaultValue={data.name}
              onChange={onInputChange}
              name="name"
              required
            />
            <Input
              title="전화번호"
              defaultValue={data.phone}
              onChange={onInputChange}
              name="phone"
              placeholder={`'-' 포함한 전체 번호`}
              required
              type="tel"
            />

            <Gap />

            <Input title="사업자번호" onChange={onInputChange} defaultValue={data.bn} name="bn" />

            <Input
              value={showAddress && showAddress}
              title={'주소'}
              name="address"
              style={{ width: '350px' }}
              disabled
            >
              <Button
                style={{
                  marginLeft: '1rem',
                  background: '#339af0'
                }}
                onClick={() => {
                  setAddressOpen(true);
                }}
              >
                주소검색
              </Button>
            </Input>

            <Gap />

            <Input
              title="은행"
              onChange={onInputChange}
              defaultValue={data.bankName}
              name="bankName"
            />
            <Input
              title="계좌번호"
              onChange={onInputChange}
              defaultValue={data.account}
              name="account"
            />

            <Gap />

            <Div title="사업자등록증">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {data[`file-1`] ? (
                  <a href={data[`file-1`]} download target="_blank" rel="noopener noreferrer">
                    확인
                  </a>
                ) : (
                  <span>파일없음</span>
                )}
                <FileInput name="file-1" onChange={onFileChange} style={{ marginLeft: '0.5rem' }} />
              </div>
            </Div>
            <Div title="통장사본">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {data[`file-2`] ? (
                  <a href={data[`file-2`]} download target="_blank" rel="noopener noreferrer">
                    확인
                  </a>
                ) : (
                  <span>파일없음</span>
                )}
                <FileInput name="file-2" onChange={onFileChange} style={{ marginLeft: '0.5rem' }} />
              </div>
            </Div>
          </Form>
          <LogList data={data.logs} />
        </>
      ) : (
        <Loader />
      )}
      {isLoading && <Loader msg="잠시만 기다려주세요..." />}

      {addressOpen && (
        <Modal
          onClose={() => {
            setAddressOpen(false);
          }}
        >
          <Address setData={setData} setAddressOpen={setAddressOpen} />
        </Modal>
      )}
    </Container>
  );
}

export default EditPartner;
