import React, { useEffect, useState, useContext } from 'react';
import { FirebaseContext } from '../../firebase';
import {
  Gap,
  Loader,
  Form,
  Notice,
  Div,
  FileInput,
  Container,
  Button,
  Input
} from '../../components';

const EditUser = props => {
  const [data, setData] = useState();
  const { db, storage } = useContext(FirebaseContext);
  const [files, setFiles] = useState([]);
  const { id } = props.match.params;
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    db.collection('users')
      .doc(id)
      .get()
      .then(doc => setData(doc.data()))
      .catch(err => console.log(err));
  }, [db, id]);

  const onChangeInput = e => {
    setData({
      ...data,
      [e.target.name]: e.target.value
    });
  };

  const onFileChange = e => {
    if (e.target.files[0].size > 10 * 1024 * 1024) {
      alert('10MB 이하의 파일만 업로드 가능합니다.');
      return null;
    }
    setData({
      ...data,
      [e.target.name]: e.target.files[0].name
    });
    setFiles(
      files.concat({
        name: e.target.name,
        file: e.target.files[0]
      })
    );
  };

  const onEdit = async () => {
    setIsLoading(true);
    let newData = { ...data };
    if (files && files.length > 0) {
      const promises = files.map(
        file =>
          new Promise(resolve => {
            storage
              .ref()
              .child(`users/${id}/` + file.file.name + '_' + new Date().getMilliseconds())
              .put(file.file)
              .then(result => {
                result.ref.getDownloadURL().then(url => {
                  resolve({ name: file.name, url });
                });
              })
              .catch(err => console.log(err));
          })
      );
      const result = await Promise.all(promises);

      result.forEach(item => Object.assign(newData, { [item.name]: item.url }));
      db.collection('users')
        .doc(id)
        .update(newData)
        .then(() => {
          alert('수정되었습니다.');
          props.history.push('/user');
        });
    } else {
      db.collection('users')
        .doc(id)
        .update(newData)
        .then(() => {
          alert('수정되었습니다.');
          props.history.push('/user');
        });
    }
    setIsLoading(false);
  };

  const onConfirm = () => {
    if (data && !data.isAdminValid) {
      db.collection('users')
        .doc(id)
        .update({
          ...data,
          isAdminValid: true
        })
        .then(() => {
          alert('가입 승인 처리 되었습니다.');
          props.history.push('/user');
        });
    } else {
      db.collection('users')
        .doc(id)
        .update({
          ...data,
          isAdminValid: true
        })
        .then(() => {
          alert('가입 비활성화 처리 되었습니다.');
          props.history.push('/user');
        });
    }
  };

  return (
    <Container title="회원관리" navigator="회원목록 > 회원정보수정">
      {isLoading && <Loader />}
      {data ? (
        <Form onSubmit={onEdit} btn="저장">
          <Input name="email" defaultValue={data.email} title="이메일" disabled />
          <Input
            placeholder="이름"
            name="name"
            defaultValue={data.name}
            title="이름"
            onChange={onChangeInput}
          />
          <Input
            placeholder="핸드폰번호(숫자만 입력)"
            name="phone"
            defaultValue={data.phone}
            title="핸드폰번호"
            onChange={onChangeInput}
          />
          {data.businessType === '배달' && (
            <Input
              placeholder="+알림톡추가번호(숫자만 입력)"
              name="phone2"
              defaultValue={data.phone2}
              title="+알림톡추가번호"
              onChange={onChangeInput}
            />
          )}

          <br />
          <b style={{ fontSize: '14px' }}>업종분류</b>
          <div
            style={{
              fontSize: '14px',
              paddingTop: '0.5rem',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center'
            }}
          >
            <input
              type="radio"
              checked={data.businessType === '숙박'}
              name="businessType"
              value="숙박"
              onChange={onChangeInput}
            />
            <span style={{ marginLeft: '0.2rem', marginRight: '0.5rem' }}>숙박업체</span>
            <input
              type="radio"
              checked={data.businessType === '배달'}
              name="businessType"
              value="배달"
              onChange={onChangeInput}
            />
            <span style={{ marginLeft: '0.2rem' }}>배달업체</span>
          </div>
          <Input
            placeholder="사업자 등록증 기재된 상호"
            name="businessName"
            defaultValue={data.businessName}
            title="사업자명"
            onChange={onChangeInput}
          />
          <Input
            placeholder="사업자등록번호(숫자만 입력)"
            name="bn"
            defaultValue={data.bn}
            title="사업자등록번호"
            onChange={onChangeInput}
          />
          <Input
            placeholder="생년월일(숫자만 입력)"
            name="birth"
            defaultValue={data.birth}
            title="생년월일"
            onChange={onChangeInput}
          />
          {data.businessType === '배달' && (
            <Input
              placeholder="부릉상점아이디"
              name="vroong_id"
              defaultValue={data.vroong_id}
              title="부릉상점아이디"
              onChange={onChangeInput}
            />
          )}
          <br />
          <div
            style={{
              fontSize: '14px',
              paddingTop: '0.5rem',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center'
            }}
          >
            <input
              type="radio"
              name="bankSort"
              checked={data.bankSort === '개인'}
              value="개인"
              onChange={onChangeInput}
            />
            <span style={{ marginLeft: '0.2rem', marginRight: '0.5rem' }}>개인</span>
            <input
              type="radio"
              name="bankSort"
              value="법인"
              checked={data.bankSort === '법인'}
              onChange={onChangeInput}
            />
            <span style={{ marginLeft: '0.2rem' }}>법인</span>
          </div>
          <Input
            placeholder="은행"
            title="은행"
            value={data.bankName}
            name="bankName"
            onChange={onChangeInput}
          />
          <Input
            placeholder="계좌번호"
            title="계좌번호"
            name="account"
            defaultValue={data.account}
            onChange={onChangeInput}
          />
          <Input
            placeholder="예금주"
            title="예금주"
            defaultValue={data.accountOwner}
            name="accountOwner"
            onChange={onChangeInput}
          />
          <br />
          <Div title="사업자등록증">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {data[`file-1`] && (
                <a
                  href={data[`file-1`]}
                  style={{ marginRight: '0.5rem' }}
                  download
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  확인
                </a>
              )}
              <FileInput accept=".jpg, .png, .pdf" name="file-1" onChange={onFileChange} />
            </div>
          </Div>
          <Div title="통장사본">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {data[`file-2`] && (
                <a
                  href={data[`file-2`]}
                  style={{ marginRight: '0.5rem' }}
                  download
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  확인
                </a>
              )}
              <FileInput accept=".jpg, .png, .pdf" name="file-2" onChange={onFileChange} />
            </div>
          </Div>
          <Div title="신청서(계약서)">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {data[`file-3`] && (
                <a
                  href={data[`file-3`]}
                  style={{ marginRight: '0.5rem' }}
                  download
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  확인
                </a>
              )}
              <FileInput accept=".jpg, .png, .pdf" name="file-3" onChange={onFileChange} />
            </div>
          </Div>

          <Notice>10MB이하의 jpg, png, pdf 파일만 가능합니다.</Notice>
          <Gap>
            <span style={{ color: 'red' }}>
              * 위 사업자등록증, 통장사본, 신청서 파일을 확인하여 입력한 정보가 일치하는지 확인 후
              가입 승인 처리하기 바랍니다.
            </span>
            <Div title="가입상태">
              <Button
                active={true}
                style={{
                  width: 'fit-content',
                  background: '#f76707',
                  marginTop: '0.25rem'
                }}
                onClick={() => {
                  onConfirm();
                }}
              >
                {data.isAdminValid ? '계정 비활성화' : '가입승인'}
              </Button>
            </Div>
          </Gap>

          <Gap>
            <Input
              defaultValue={(data && data.group && data.group.toString()) || ''}
              style={{ width: '80%', textAlign: 'left' }}
              title="업체코드매칭"
              onChange={e => {
                const value = e.target.value.replace(/(\s*)/g, '');
                setData({
                  ...data,
                  group: value.split(',')
                });
              }}
            />

            <Notice style={{ marginLeft: '120px' }}>
              입력 예시: 코드1,코드2 <br />
              공백없이 "," (콤마)로만 구분, 대소문자 주의
            </Notice>
          </Gap>
        </Form>
      ) : (
        <Loader />
      )}
    </Container>
  );
};

export default EditUser;
