/* eslint-disable no-new-wrappers */
import React, { useContext, useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { uniqueId } from 'lodash';
import { uploadFile } from '../../common/fetch';
import { FirebaseContext } from '../../firebase';
import {
  Input,
  Button,
  Modal,
  Loader,
  Div,
  Tag,
  Address,
  Notice,
  Container,
  Dropdown,
  FileInput,
  Gap,
  Form,
  Textarea
} from '../../components';
import SelectTag from '../7_Tags/SelectTag';
import toast from '../../components/Toast';
import WordListContainer from '../6_Word/WordListContainer';
import { UserStateContext } from '../../contexts/UserContext';
import { useChangeLog } from '../../common/hooks';
import LogList from '../../components/LogList';
import SendPush from './SendPush';
import MakeQrCode from '../../components/MakeQrCode';
import SelectImage from '../9.5_Image/SelectImage';

const Content = styled.div`
  font-size: 14px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  .title {
    margin-right: 1rem;
    font-weight: 400;
    color: black;
  }
  .main-title {
    color: black;
    font-weight: 600;
    margin-bottom: 1rem;
  }
  .flex {
    width: 100%;
    display: flex;
    align-items: center;
  }
  .photo {
    width: 200px;
    height: 125px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 4px;
  }
  .media-flex {
    display: flex;
    align-items: center;
    @media (max-width: 600px) {
      flex-direction: column;
    }
  }
`;

const EditStore = props => {
  const { id } = props.match.params;
  const { db } = useContext(FirebaseContext);
  const user = useContext(UserStateContext);
  const [data, setData] = useState(null);

  const [isAddressOpen, setAddressOpen] = useState(false);
  const [isWordOpen, setWordOpen] = useState(false);
  const [isTagOpen, setTagOpen] = useState(false);
  const [isTestOpen, setTestOpen] = useState(false);

  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [{ getChangeLog, onChangeDataToLog }] = useChangeLog(data, true); // 로그용

  const [time, setTime] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!user) return;
    db.collection('stores')
      .doc(id)
      .get()
      .then(doc => {
        if (!doc.exists) return null;
        const { group, timeData } = doc.data();
        if (user.authLevel !== 'ROOT' && !group.includes(user.id)) {
          alert('잘못된 접속 경로입니다.');
          return props.history.goBack();
        }
        setTime(timeData);
        setData({
          ...doc.data()
        });
      })
      .catch(err => console.log(err));
  }, [db, id, props.history, user]);

  const onEdit = async _ => {
    if (isLoading) return null;
    setIsLoading(true);

    let newData = {
      ...data,
      editedAt: new Date(),
      time: `${time.open_hour}:${time.open_minute === 0 ? '00' : time.open_minute}~${
        time.close_hour
      }:${time.close_minute === 0 ? '00' : time.close_minute}`,
      timeData: time,
      logs: [...(data.logs || []), getChangeLog()]
    };

    if (files && files.length > 0) {
      const promises = files.map(({ file, name }) => uploadFile(`stores/${id}`, file, name));
      const urls = await Promise.all(promises);
      urls.forEach(item => (newData[item.id] = item.url));
    }

    db.collection('stores')
      .doc(id)
      .update(newData)
      .then(() => {
        toast('업데이트 되었습니다.');
        setIsLoading(false);
        props.history.goBack();
      });
  };

  const showAddress = useMemo(() => {
    return data && data.address && `${data.address.road || ''} ${data.address.detail || ''}`;
  }, [data]);

  const onChangeInput = e => {
    setData({
      ...data,
      [e.target.name]: e.target.value
    });

    onChangeDataToLog(e.target.name, e.target.value);
  };

  const onChangeNumber = e => {
    const value = e.target.value === '' ? '' : parseInt(e.target.value);
    if (Number.isNaN(value) && value !== '') {
      e.target.value = null;
      return alert('숫자만 입력가능합니다.');
    }
    setData({
      ...data,
      [e.target.name]: value
    });
    onChangeDataToLog(e.target.name, value);
  };

  const changeHolidays = e => {
    const { value, checked } = e.target;
    const newData = data.holidays || [];

    if (checked) {
      setData({
        ...data,
        holidays: Array.from(new Set(newData.concat(parseInt(value))))
      });
      onChangeDataToLog('holidays', Array.from(new Set(newData.concat(parseInt(value)))));
    } else {
      // 삭제
      setData({
        ...data,
        holidays: Array.from(new Set(newData.filter(item => item !== parseInt(value))))
      });
      onChangeDataToLog(
        'holidays',
        Array.from(new Set(newData.filter(item => item !== parseInt(value))))
      );
    }
  };

  const checkMatch = () => {
    db.collection('users')
      .where('group', 'array-contains', id)
      .get()
      .then(snapshots => alert(`${snapshots.size}건 매칭됨`));
  };

  const onChangeTime = e => {
    setTime({
      ...time,
      [e.target.name]: parseInt(e.target.value)
    });
  };

  return (
    <>
      {isLoading && <Loader />}
      <Container
        title="배달업체관리"
        navigator="배달업체목록 > 업체정보수정"
        buttons={
          <>
            {user && user.authLevel === 'ROOT' && (
              <Button type="normal" onClick={() => setTestOpen(true)}>
                주문테스트
              </Button>
            )}
            <Button
              type="normal"
              onClick={() => {
                window.open(
                  `https://app.deliquick.co.kr/preview/${id}`,
                  '델리퀵 - 미리보기',
                  'width=375,height=667'
                );
                return false;
              }}
            >
              업체미리보기
            </Button>
            <Button type="normal" to={`/store/menu/${id}`}>
              메뉴관리
            </Button>
          </>
        }
      >
        {data ? (
          <Content>
            <Form onSubmit={onEdit} btn="저장" requires={['address']} data={data}>
              <Input defaultValue={id} title={'업체코드'} disabled />
              <Input
                disabled
                title={'협력사명'}
                name="companyName"
                defaultValue={data.companyName}
                onChange={onChangeInput}
              />

              <Input
                title={'관리담당자'}
                defaultValue={data.chargePerson}
                onChange={onChangeInput}
                name="chargePerson"
              />
              <Gap>
                <Input
                  title={'업체명(한국어)'}
                  defaultValue={data.realName}
                  onChange={onChangeInput}
                  name="realName"
                  placeholder="지점명포함 예시: 00치킨(강남점)"
                  required
                />

                {user && user.authLevel === 'ROOT' && (
                  <Input
                    value={data && data.name_id && `${data.name} (${data.name_id})`}
                    title={'선택된번역'}
                    onChange={onChangeInput}
                    name="name"
                    disabled
                    style={{ width: '60%' }}
                  >
                    <Button
                      type="primary"
                      onClick={() => {
                        setWordOpen(true);
                      }}
                    >
                      단어검색
                    </Button>
                  </Input>
                )}
              </Gap>
              <Input
                required
                defaultValue={data.phone}
                title={'전화번호'}
                onChange={onChangeInput}
                name="phone"
              />
              <Input
                title={'핸드폰번호'}
                onChange={onChangeInput}
                name="mobile"
                defaultValue={data.mobile}
              />
              <Input
                title={'대표자명'}
                onChange={onChangeInput}
                defaultValue={data.owner}
                name="owner"
              />
              <Input
                defaultValue={data.bn}
                title={'사업자번호'}
                placeholder={`'-' 포함한 전체 번호`}
                onChange={onChangeInput}
                name="bn"
              />
              <Gap>
                <Input
                  required
                  value={showAddress}
                  title={'주소'}
                  name="address"
                  style={{ width: '60%' }}
                  disabled
                  onChange={onChangeInput}
                >
                  <Button
                    type="primary"
                    onClick={() => {
                      setAddressOpen(true);
                    }}
                  >
                    주소검색
                  </Button>
                </Input>

                <Input
                  value={data && data.address && data.address.en}
                  title={'영문주소'}
                  name="address"
                  style={{ width: '60%' }}
                  disabled
                />
              </Gap>

              <Div title={'대표사진'}>
                <div style={{ marginBottom: '0.5rem' }}>
                  <div
                    className="photo"
                    style={{ backgroundColor: '#dfdfdf', backgroundImage: `url(${data.photo})` }}
                  />
                </div>
                <FileInput
                  onChange={e => {
                    const file = e.target.files[0];
                    if (!file) return;
                    if (file.type.split('/')[0] !== 'image')
                      return alert('이미지파일만 업로드 가능합니다.');
                    let reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = e => {
                      setData({ ...data, photo: e.target.result });
                      setFiles(
                        files.concat({
                          name: 'photo',
                          file
                        })
                      );
                    };
                  }}
                />
                <span />
                <SelectImage callback={photo => setData({ ...data, photo })} />

                {data.photo && (
                  <Button
                    type="normal"
                    onClick={() => {
                      setData({ ...data, photo: '' });
                    }}
                  >
                    사진삭제
                  </Button>
                )}
              </Div>
              <Notice style={{ marginLeft: '120px' }}>
                <b>사진 업로드 안내</b> <br />
                5:3 비율을 권장합니다. <br />
                비율이 안맞을 경우 자동으로 중앙정렬해서 보여집니다. <br /> 권장 해상도: 480x288,
                권장 파일 사이즈: 100KB 미만
              </Notice>
              <Gap>
                <Div title={'태그(카테고리)'}>
                  {data.tags && data.tags.map(tag => <Tag id={tag} key={uniqueId()} />)}
                </Div>
                <span />
                <Button
                  type="primary"
                  style={{
                    marginLeft: '120px'
                  }}
                  onClick={() => {
                    setTagOpen(true);
                  }}
                >
                  태그등록
                </Button>
                <Notice
                  style={{
                    marginLeft: '120px'
                  }}
                >
                  첫번째 선택한 태그가 메인 카테고리로 등록됩니다.
                </Notice>
              </Gap>

              <Input
                defaultValue={data.minprice}
                title={'최소주문금액'}
                onChange={onChangeNumber}
                name="minprice"
              />

              <Input
                defaultValue={data.deliveryFee}
                title={'배달비'}
                onChange={onChangeNumber}
                name="deliveryFee"
                placeholder="단위(원)"
              />

              <Input
                defaultValue={data.averageTime}
                title={'평균배달시간'}
                onChange={onChangeInput}
                name="averageTime"
                placeholder={`단위(분)`}
              />
              <Div title="휴무일" style={{ marginTop: '1rem' }}>
                {['일', '월', '화', '수', '목', '금', '토'].map((item, index) => (
                  <React.Fragment key={index}>
                    <input
                      type="checkbox"
                      value={index}
                      defaultChecked={data && data.holidays && data.holidays.includes(index)}
                      onChange={changeHolidays}
                    />
                    <span style={{ marginRight: '0.8rem' }}>{item}</span>
                  </React.Fragment>
                ))}
              </Div>

              <Gap>
                <Div title="영업시간" required>
                  <div className="media-flex">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Dropdown
                        options={timeArray.map(item => ({
                          text: item
                        }))}
                        className="small"
                        name="open_hour"
                        onChange={onChangeTime}
                        defaultValue={time.open_hour}
                      />

                      <Dropdown
                        options={[0, 15, 30, 45].map(item => ({
                          text: item
                        }))}
                        name="open_minute"
                        className="small"
                        onChange={onChangeTime}
                        defaultValue={time.open_minute}
                      />
                    </div>
                    <span style={{ margin: '0 0.5rem', fontSize: '18px', fontWeight: 'bold' }}>
                      ~
                    </span>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Dropdown
                        options={timeArray.map(item => ({
                          text: item
                        }))}
                        className="small"
                        name="close_hour"
                        onChange={onChangeTime}
                        defaultValue={time.close_hour}
                      />
                      <Dropdown
                        options={[0, 15, 30, 45].map(item => ({
                          text: item
                        }))}
                        className="small"
                        name="close_minute"
                        onChange={onChangeTime}
                        defaultValue={time.close_minute}
                      />
                    </div>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center', marginTop: '1rem' }}>
                    <input
                      type="checkbox"
                      onChange={e => {
                        setTime({ ...time, isAllDayOpen: e.target.checked });
                      }}
                      defaultChecked={time.isAllDayOpen}
                    />
                    <div>24시간영업</div>
                  </div>
                </Div>
              </Gap>

              <Gap>
                <Div title="영업상태">
                  <Dropdown
                    options={[
                      { text: '영업중', value: 'true' },
                      { text: '영업종료', value: 'false' }
                    ]}
                    defaultValue={data.active}
                    name="active"
                    onChange={e => setData({ ...data, active: e.target.value === 'true' })}
                  />
                </Div>
                {user && user.authLevel === 'ROOT' && (
                  <>
                    <Div title="등록상태">
                      <Dropdown
                        options={[
                          { text: '대기' },
                          { text: '번역중' },
                          { text: '번역완료' },
                          { text: '테스트' },
                          { text: '등록' },
                          { text: '해지' }
                        ]}
                        defaultValue={data.status}
                        name="status"
                        onChange={onChangeInput}
                      />
                    </Div>
                    <Button onClick={checkMatch}>계약매칭확인 (임시)</Button>
                    <Notice style={{ marginLeft: '120px' }}>
                      상태변경사항은 저장 후에 반영됩니다.
                    </Notice>
                  </>
                )}
              </Gap>

              <Gap>
                <Div title="메뉴판 사진">
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {data[`file-1`] && (
                      <a
                        href={data[`file-1`]}
                        style={{ marginRight: '0.5rem' }}
                        download
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        확인
                      </a>
                    )}
                    <FileInput
                      accept=".jpg, .png, .pdf, .jpeg, .gif"
                      name="file-1"
                      onChange={e => {
                        const file = e.target.files[0];
                        if (!file) return;
                        if (file.size > 10 * 1024 * 1024) {
                          alert('10MB 이하의 파일만 업로드 가능합니다.');
                          return null;
                        }
                        setData({ ...data, [e.target.name]: file.name });
                        setFiles([
                          ...files,
                          {
                            name: e.target.name,
                            file
                          }
                        ]);
                      }}
                    />
                  </div>
                </Div>
              </Gap>
              <Gap>
                <Textarea
                  title="메모"
                  onChange={e => {
                    setData({ ...data, [e.target.name]: e.target.value.replace(/\n/g, '<br/>') });
                  }}
                  name="memo"
                  defaultValue={data.memo}
                  placeholder="등록시 전달사항이 있으면 남겨주세요."
                />
              </Gap>
            </Form>
            <LogList data={data.logs} />
            {data && user && user.authLevel === 'ROOT' && <MakeQrCode id={id} make_type="store" />}
          </Content>
        ) : (
          <Loader />
        )}

        {/* 여기부터 주소입력용 모달 */}
        {isAddressOpen && (
          <Modal
            onClose={() => {
              setAddressOpen(false);
            }}
          >
            <Address setData={setData} setAddressOpen={setAddressOpen} />
          </Modal>
        )}
        {/* 단어입력용 모달 */}
        {isWordOpen && (
          <Modal
            onClose={() => {
              setWordOpen(false);
            }}
          >
            <WordListContainer
              type="업체명"
              register={(name_id, name) => {
                setData({
                  ...data,
                  name_id,
                  name
                });
                setWordOpen(false);
              }}
            />
          </Modal>
        )}
        {/* 태그입력용 */}
        {isTagOpen && (
          <Modal
            style={{ paddingTop: 0 }}
            onClose={() => {
              setTagOpen(false);
            }}
          >
            <SelectTag
              current={data.tags}
              register={tags => {
                setData({
                  ...data,
                  tags
                });
                setTagOpen(false);
              }}
            />
          </Modal>
        )}
        {isTestOpen && <SendPush onClose={() => setTestOpen(false)} storeData={{ ...data, id }} />}
      </Container>
    </>
  );
};

export default EditStore;

const timeArray = [
  0,
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23
];
