import React, { useState, useEffect, useCallback } from 'react';
import styled, { css } from 'styled-components';
import queryString from 'query-string';
import { cloneDeep } from 'lodash';
import { MdSearch, MdClose, MdAutorenew } from 'react-icons/md';
import Button from '../Button';

const Container = styled.div`
  width: 100%;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  .grid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 0.5rem;
    margin-bottom: 1rem;
    @media screen and (max-width: 800px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
`;

const StyledInput = styled.input`
  outline-style: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: auto;
  line-height: normal;
  border: none;
  width: 90%;
  border: 1px solid #ccc;
  padding: 0.5rem 5%;
  border-radius: 8px;
  ${({ active, theme }) =>
    active &&
    css`
      box-shadow: 0 0 3px 1px ${theme.mainColor};
    `}
`;

const Search = ({
  headers,
  data,
  setData,
  resetData,
  page,
  setPage,
  searchStartOpen,
  themeColor
}) => {
  const [keywords, setKeywords] = useState({});
  const [open, setOpen] = useState(searchStartOpen || false);

  const [lastQuery] = useState(queryString.parse(window.location.search));

  const addQuery = useCallback(
    keywords => {
      const query = queryString.stringify({ page, ...keywords });
      window.history.replaceState(
        { data: 'replace' },
        'Query searching data',
        `${window.location.pathname}?${query}`
      );
    },
    [page]
  );

  const doSearch = keywords => {
    if (!keywords) return;
    addQuery(keywords);

    let result = [...data];
    Object.keys(keywords).forEach(condition => {
      result = result.filter(
        item => item[condition] && item[condition].includes(keywords[condition])
      );
    });
    setData(result);
  };

  // 처음 로딩시 퀴리에 있는 값으로 변경
  useEffect(() => {
    let { page, ...copiedQuery } = cloneDeep(lastQuery);
    if (Object.keys(copiedQuery).length > 0) {
      setOpen(true);
    }
    setKeywords(copiedQuery);
    doSearch(copiedQuery);
    if (lastQuery.page) setPage(parseInt(lastQuery.page));
  }, [data]); // eslint-disable-line

  useEffect(() => {
    addQuery(keywords);
  }, [page, addQuery, keywords]);

  const onChange = useCallback(e => {
    const { name, value } = e.target;
    setKeywords(keywords => ({
      ...keywords,
      [name]: value
    }));
  }, []);

  return (
    <>
      {!open && (
        <Container>
          <Button
            type="normal"
            onClick={() => {
              setOpen(true);
            }}
          >
            <MdSearch color="#aaa" size="20px" />
            검색
          </Button>
        </Container>
      )}
      {open && (
        <Container>
          <div
            style={{
              width: '100%',
              textAlign: 'right',
              padding: '0.5rem 0'
            }}
          >
            <MdClose
              color="#aaa"
              size="20px"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setOpen(false);
              }}
            />
          </div>
          <div className="grid">
            {headers.map((header, index) => {
              if (!header.excludeSearch)
                return (
                  <div key={index}>
                    <StyledInput
                      className="keywords-search"
                      type="text"
                      name={header.value}
                      placeholder={header.title}
                      defaultValue={keywords[header.value] && keywords[header.value]}
                      active={keywords[header.value] && true}
                      onKeyDown={e => {
                        if (e.keyCode === 13) {
                          doSearch(keywords);
                        }
                      }}
                      onChange={onChange}
                    />
                  </div>
                );
              return null;
            })}
          </div>
          <div
            style={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'flex-end'
            }}
          >
            <Button
              type="search"
              onClick={() => {
                doSearch(keywords);
              }}
            ></Button>
            <Button
              onClick={() => {
                resetData();
                setKeywords({});
                const inputs = document.getElementsByClassName('keywords-search');
                [...inputs].forEach(input => (input.value = null));
              }}
            >
              <MdAutorenew size="20px" />
            </Button>
          </div>
        </Container>
      )}
    </>
  );
};

export default Search;
