import React, { useContext, useState } from 'react';
import Input from '../../components/Input';
import Button from '../../components/Button';
import { FirebaseContext } from '../../firebase';
import Div from '../../components/Div';
import Container from '../../components/Container';
import WordList from '../6_Word/WordListContainer';
import Modal from '../../components/Modal';
import toast from '../../components/Toast';

const AddOption = props => {
  const { db } = useContext(FirebaseContext);
  const { id } = props.match.params;
  const [editedData, setEditedData] = useState({
    groupName: '',
    radio: false,
    list: [{ name: '', price: '' }]
  });
  const [wordOpen, setWordOpen] = useState(false);
  const [groupOpen, setGroupOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);

  const optionRef = db.collection('options');

  const setRadio = newData => {
    const changeList = [];
    if (newData.radio) {
      const isHaveDefault = newData.list.map(item => item.default).includes(true);
      if (isHaveDefault) {
        newData.list.map(item => changeList.push({ ...item, must: true }));
      } else {
        alert('필수선택그룹은 반드시 기본선택 옵션 항목이 있어야합니다.');
        return null;
      }
    } else {
      newData.list.map(item => changeList.push({ ...item, must: false }));
    }
    return { ...newData, list: changeList };
  };

  const addNewOption = newData => {
    const changedData = setRadio(newData);
    if (changedData) {
      optionRef
        .doc()
        .set({ ...changedData, menu_id: id })
        .then(() => {
          toast('업데이트 되었습니다.');
          props.history.push(`/store/option/${id}`);
        });
    }
  };

  const handleChange = e => {
    if (e.target.name === 'radio') {
      setEditedData({ ...editedData, [e.target.name]: e.target.checked });
    } else {
      setEditedData({ ...editedData, [e.target.name]: e.target.value });
    }
  };
  const handleListChange = (e, index) => {
    let editedList = [...editedData.list];
    editedList[index] = {
      ...editedList[index],
      [e.target.name]:
        e.target.name === 'price'
          ? parseInt(e.target.value)
          : e.target.name === 'default'
          ? e.target.checked
          : e.target.value
    };
    setEditedData({ ...editedData, list: editedList });
  };

  const handleListWordRegister = (name, name_id, index) => {
    let editedList = [...editedData.list];
    editedList[index] = {
      ...editedList[index],
      name,
      name_id
    };
    setEditedData({ ...editedData, list: editedList });
  };

  const addListOption = () => {
    setEditedData({
      ...editedData,
      list: editedData.list.concat({
        name: '',
        price: 0
      })
    });
  };

  const deleteOption = deleteIndex => {
    setEditedData({ ...editedData, list: [] });
    setTimeout(() => {
      setEditedData({
        ...editedData,
        list: editedData.list.filter((item, index) => index !== deleteIndex)
      });
    }, 50);
  };

  const switchOption = (switchIndex, direction) => {
    let editedList = [...editedData.list];
    setEditedData({ ...editedData, list: [] });
    if (direction === 'up') {
      editedList[switchIndex] = editedList[switchIndex - 1];
      editedList[switchIndex - 1] = editedData.list[switchIndex];
    } else if (direction === 'down') {
      editedList[switchIndex] = editedList[switchIndex + 1];
      editedList[switchIndex + 1] = editedData.list[switchIndex];
    }
    setTimeout(() => {
      setEditedData({ ...editedData, list: editedList });
    }, 50);
  };

  return (
    <Container>
      <div
        style={{
          border: '1px solid #ccc',
          padding: '0.5rem 1rem',
          marginTop: '0.5rem',
          borderRadius: '4px'
        }}
      >
        <span
          style={{ color: '#ff3250', cursor: 'pointer' }}
          onClick={() => {
            editedData.groupName !== '' && addNewOption(editedData);
          }}
        >
          추가
        </span>
        <span
          style={{ cursor: 'pointer', marginLeft: '0.5rem' }}
          onClick={() => {
            props.history.goBack();
          }}
        >
          닫기
        </span>
        <>
          <Input
            defaultValue={editedData.groupName}
            title={'옵션그룹명'}
            name="groupName"
            onChange={handleChange}
          />
          <div>
            <span
              onClick={() => {
                setGroupOpen(true);
              }}
            >
              <Input value={editedData.groupName_id} title={'옵션그룹명번역'} disabled />
            </span>
          </div>

          <Div title="필수여부">
            <input
              type="checkbox"
              defaultChecked={editedData.radio}
              name="radio"
              onChange={handleChange}
            />
          </Div>
          <table>
            <thead>
              <tr>
                <th>순서</th>
                <th>옵션명</th>
                <th>옵션번역</th>
                <th>가격</th>
                <th>기본선택</th>
                <th> </th>
              </tr>
            </thead>
            <tbody>
              {editedData.list.map((item, index) => (
                <tr>
                  <td>{index + 1}</td>
                  <td>
                    <Input
                      defaultValue={item.realName}
                      name="realName"
                      onChange={e => {
                        handleListChange(e, index);
                      }}
                    />
                  </td>
                  <td
                    onClick={() => {
                      setSelectedIndex(index);
                      setWordOpen(true);
                    }}
                  >
                    <Input
                      value={item.name_id && `${item.name} (${item.name_id})`}
                      name="name"
                      disabled
                    />
                  </td>
                  <td>
                    <Input
                      defaultValue={0}
                      name="price"
                      onChange={e => {
                        handleListChange(e, index);
                      }}
                    />
                  </td>
                  <td>
                    {editedData.radio && (
                      <input
                        type="checkbox"
                        defaultChecked={item.default}
                        name="default"
                        style={{ marginLeft: '0.5rem' }}
                        onChange={e => {
                          handleListChange(e, index);
                        }}
                      />
                    )}
                  </td>
                  <td>
                    <div
                      style={{
                        width: '30%',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        marginLeft: '0.5rem'
                      }}
                    >
                      <Button
                        type="small"
                        onClick={() => {
                          deleteOption(index);
                        }}
                      >
                        -
                      </Button>
                      {index !== 0 && (
                        <Button
                          type="small"
                          onClick={() => {
                            switchOption(index, 'up');
                          }}
                        >
                          ↑
                        </Button>
                      )}
                      {index !== editedData.list.length - 1 && (
                        <Button
                          type="small"
                          onClick={() => {
                            switchOption(index, 'down');
                          }}
                        >
                          ↓
                        </Button>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <Button type="normal" onClick={addListOption} style={{ width: '90%' }}>
            +옵션추가
          </Button>
        </>
      </div>
      {wordOpen && (
        <Modal
          onClose={() => {
            setWordOpen(false);
          }}
        >
          <WordList
            type="옵션"
            register={(name_id, name) => {
              handleListWordRegister(name, name_id, selectedIndex);
              setWordOpen(false);
            }}
          />
        </Modal>
      )}
      {groupOpen && (
        <Modal
          onClose={() => {
            setGroupOpen(false);
          }}
        >
          <WordList
            type="옵션"
            register={name_id => {
              setEditedData({ ...editedData, groupName_id: name_id });
              setGroupOpen(false);
            }}
          />
        </Modal>
      )}
    </Container>
  );
};

export default AddOption;
