import React, { useContext, useState, useEffect } from 'react';
import { FirebaseContext } from '../../firebase';
import Button from '../../components/Button';
import Container from '../../components/Container';
import { Dropdown, Div } from '../../components';
import { koreaDistrict } from '../../common/korea';
import { formatPhone } from '../../common/format';

const HotelMapView = () => {
  const { db } = useContext(FirebaseContext);
  const [data, setData] = useState(null);
  const [sido, setSido] = useState('전체');
  const [gungu, setGungu] = useState();
  const [selected, setSelected] = useState();

  const loadData = async _ => {
    setSelected(null);
    if (sido === '전체') {
      db.collection('hotels')
        .get()
        .then(snapshots => {
          let result = [];
          snapshots.forEach(doc => result.push({ ...doc.data(), id: doc.id }));
          setData(result);
        });
    } else {
      if (sido && !gungu) {
        db.collection('hotels')
          .where('address.extras.region_1depth_name', '==', sido)
          .get()
          .then(snapshots => {
            let result = [];
            snapshots.forEach(doc => result.push({ ...doc.data(), id: doc.id }));
            setData(result);
          });
      } else if (sido && gungu) {
        db.collection('hotels')
          .where('address.extras.region_2depth_name', '==', gungu)
          .get()
          .then(snapshots => {
            let result = [];
            snapshots.forEach(doc => result.push({ ...doc.data(), id: doc.id }));
            setData(result);
          });
      }
    }
  };

  useEffect(() => {
    const initGoogleMap = _ => {
      const google = window.google;
      const location = selected ? selected.position.geopoint : data[0].position.geopoint;
      const map = new google.maps.Map(document.getElementById('map'), {
        zoom: gungu ? (selected ? 16 : 14) : selected ? 16 : 12,
        center: { lat: location.latitude, lng: location.longitude }
      });

      if (selected) {
        const selectMarker = new google.maps.Marker({
          position: {
            lat: parseFloat(selected.position.geopoint.latitude),
            lng: parseFloat(selected.position.geopoint.longitude)
          },
          title: selected.realName,
          map: map,
          zIndex: 5
        });
        const selectInfo = new google.maps.InfoWindow({
          content: `<div style={{lineHeight:'2'}}><b>${selected.name}</b> [${selected.id}]</div>
          <div>${formatPhone(selected.phone)}</div>
          <div>${selected.type}</div>
          <div>${selected.status}</div>
        <div><a target='_blank' href='/hotel/edit/${selected.code}'>상세보기</a></div>
        `
        });

        selectMarker.addListener('click', function() {
          selectInfo.open(map, selectMarker);
        });
      }

      data
        .filter(item => (selected ? selected.id !== item.id : true))
        .map(item => {
          const { latitude, longitude } = item.position.geopoint;

          const marker = new google.maps.Marker({
            position: {
              lat: parseFloat(latitude),
              lng: parseFloat(longitude)
            },
            icon: {
              url:
                !item.status || item.status === '미계약'
                  ? 'http://maps.google.com/mapfiles/ms/micons/blue.png'
                  : 'http://maps.google.com/mapfiles/ms/icons/pink-dot.png'
            },
            title: item.realName,
            map: map
          });
          const infowindow = new google.maps.InfoWindow({
            content: `<div style={{lineHeight:'2'}}><b>${item.name}</b> [${item.id}]</div>
            <div>${formatPhone(item.phone)}</div>
            <div>${item.type}</div>
            <div>${item.status ? item.status : ''}</div>
          <div><a target='_blank' href='/hotel/edit/${item.code}'>상세보기</a></div>
          `
          });

          marker.addListener('click', function() {
            infowindow.open(map, marker);
          });

          return null;
        });
    };
    if (data && data.length > 0) initGoogleMap();
  }, [data, gungu, selected]);

  return (
    <Container title="숙박업체관리" navigator="숙박업체지도조회">
      <Div title="지역선택">
        <div style={{ display: 'flex' }}>
          <Dropdown
            options={[{ text: '전체', value: '전체' }].concat(
              Object.keys(koreaDistrict).map(text => ({ text, value: text }))
            )}
            defaultValue="전체"
            onChange={e => {
              setSido(e.target.value);
            }}
          />

          <Dropdown
            style={{ marginLeft: '0.25rem' }}
            options={
              sido && sido !== '전체'
                ? koreaDistrict[sido].map(text => ({ text, value: text }))
                : []
            }
            onChange={e => setGungu(e.target.value)}
          />

          <Button
            onClick={() => {
              loadData();
            }}
          >
            검색
          </Button>
        </div>
      </Div>
      {data && data.length > 0 && (
        <div style={{ display: 'flex' }}>
          <div
            style={{ width: '25%', margin: '0.5rem 2.5%', overflowY: 'scroll', height: '600px' }}
          >
            <div style={{ margin: '1rem 0' }}>
              검색결과 <b>{data && data.length}</b>건
            </div>
            {data.map((item, index) => (
              <div
                key={index}
                onClick={() => setSelected(item)}
                style={{
                  marginBottom: '0.25rem',
                  borderBottom: '1px solid #ccc',
                  paddingBottom: '0.25rem',
                  display: 'flex',
                  cursor: 'pointer'
                }}
              >
                <div style={{ textAlign: 'center', width: '30px', color: '#666' }}>{index + 1}</div>
                <div>
                  <div>
                    <b>{item.name}</b>
                  </div>
                  <div style={{ color: '#666', fontSize: '12px' }}>
                    {item.address && (item.address.jibun || item.address.road)}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div style={{ width: '65%', margin: '0.5rem 2.5%' }}>
            <div id="map" style={{ minHeight: '600px' }} />
          </div>
        </div>
      )}
    </Container>
  );
};

export default HotelMapView;
