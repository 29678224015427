import React, { useCallback, useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { FirebaseContext } from '../firebase';

const Container = styled.span`
  border: 1px solid #74c0fc;
  color: #228be6;
  border-radius: 6px;
  font-size: 12px;
  padding: 0.2rem 0.4rem;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  background: #e7f5ff;
  user-select: none;
  &:hover {
    opacity: 0.8;
  }
`;

const Tag = ({ id, style, onClick, deleted }) => {
  const { db } = useContext(FirebaseContext);
  const [data, setData] = useState('');

  const loadData = useCallback(() => {
    if (!id) return;
    db.collection('tags')
      .doc(id)
      .get()
      .then(doc => {
        !doc.exists
          ? setData({ kr: '삭제된태그', id: doc.id })
          : setData({ ...doc.data(), id: doc.id });
      });
  }, [db, id]);

  const loadLocal = useCallback(() => {
    const loaded = JSON.parse(sessionStorage.getItem(id));
    if (loaded) {
      setData(loaded);
      return true;
    } else {
      return false;
    }
  }, [id]);

  useEffect(() => {
    if (!loadLocal()) {
      loadData();
    }
  }, [loadData, loadLocal]);

  useEffect(() => {
    data.id && sessionStorage.setItem(data.id, JSON.stringify(data));
  }, [data]);

  return (
    data && (
      <Container onClick={onClick} style={{ ...style }}>
        #{data.kr}
        {deleted && <span style={{ color: 'gray', fontSize: '13px' }}> ×</span>}
      </Container>
    )
  );
};

export default Tag;
