import React from 'react';
import styled, { CSSProperties } from 'styled-components';
import { Link } from 'react-router-dom';
import { MdSearch, MdEdit, MdDelete } from 'react-icons/md';

export const StyledButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  user-select: none;
  border: 1px solid #ccc;
  cursor: pointer;
  color: #888;
  width: fit-content;
  font-size: 14px;
  font-weight: bold;
  background: white;
  padding: 0 1rem;
  border-radius: 4px;
  text-align: center;
  transition: all 0.1s ease-in;
  height: 34px;
  &:hover {
    opacity: 0.8;
  }
  a {
    color: inherit;
  }
  @media (max-width: 480px) {
    font-size: 12px;
    font-weight: normal;
    padding: 0;
  }
`;

const StyledLink = styled(Link)`
  width: fit-content;
  & + & {
    margin-left: 0.25rem;
  }
  input + & {
    margin-left: 0.25rem;
  }
  div + & {
    margin-left: 0.25rem;
  }
`;

type ButtonProps = {
  children: React.ReactNode;
  style: CSSProperties;
  type?: string;
  to?: string;
  onClick?: () => void;
};

const Button = ({ children, style, type, to, ...rest }: ButtonProps) => {
  switch (type) {
    case 'normal':
      return (
        <StyledLink to={({ pathname }) => (to ? to : pathname)}>
          <StyledButton style={{ ...style }} {...rest}>
            {children}
          </StyledButton>
        </StyledLink>
      );
    case 'primary':
      return (
        <StyledLink to={({ pathname }) => (to ? to : pathname)}>
          <StyledButton
            style={{ background: '#339af0', color: '#fff', border: 'none', ...style }}
            {...rest}
          >
            {children}
          </StyledButton>
        </StyledLink>
      );
    case 'small':
      return (
        <StyledLink to={({ pathname }) => (to ? to : pathname)}>
          <StyledButton
            style={{ padding: '0 0.4em', height: '20px', fontSize: '11px', ...style }}
            {...rest}
          >
            {children}
          </StyledButton>
        </StyledLink>
      );
    case 'search':
      return (
        <StyledLink to={({ pathname }) => (to ? to : pathname)}>
          <StyledButton
            style={{ background: '#339af0', color: '#fff', border: 'none', ...style }}
            {...rest}
          >
            <MdSearch size="20px" />
            {children}
          </StyledButton>
        </StyledLink>
      );
    case 'edit':
      return (
        <StyledLink to={({ pathname }) => (to ? to : pathname)}>
          <StyledButton style={{ ...style }} {...rest}>
            <MdEdit /> {children}
          </StyledButton>
        </StyledLink>
      );
    case 'delete':
      return (
        <StyledLink to={({ pathname }) => (to ? to : pathname)}>
          <StyledButton style={{ ...style }} {...rest} className="delete">
            <MdDelete /> {children}
          </StyledButton>
        </StyledLink>
      );
    default:
      return (
        <StyledLink to={({ pathname }) => (to ? to : pathname)}>
          <StyledButton
            style={{ background: '#339af0', color: '#fff', border: 'none', ...style }}
            {...rest}
          >
            {children}
          </StyledButton>
        </StyledLink>
      );
  }
};

export default Button;
