import { useState, useEffect, useContext } from 'react';
import { FirebaseContext } from '../firebase';

export const uploadFile = (folderName, file, id) => {
  if (!file) return;
  const { storage } = window.firebase;
  const now = new Date();
  const fileName = file.name.replace(/[()]/gi, '_'); // 괄호제거
  return storage
    .ref()
    .child(folderName + '/' + fileName + '_' + now.getMilliseconds())
    .put(file)
    .then(function(snapshot) {
      return snapshot.ref.getDownloadURL().then(url => {
        return { id, url };
      });
    });
};

export const getNewCode = collection => {
  if (!collection) return;
  const { db } = window.firebase;
  return db
    .collection(collection)
    .orderBy('createdAt', 'desc')
    .limit(1)
    .get()
    .then(snapshots => {
      const firstKey = collection.substr(0, 1);
      if (!snapshots.empty) {
        const number = parseInt(snapshots.docs[0].id.split(firstKey)[1]);
        if (Number.isNaN(number)) {
          return firstKey + '10000';
        }
        return firstKey + (number + 1);
      }
      return firstKey + '10000';
    });
};

export const useFirebase = (initialCollection, initialData, docId, initialWhere) => {
  const [data, setData] = useState(initialData);
  const { db } = useContext(FirebaseContext);
  const [collection, setCollection] = useState(initialCollection);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [reload, setReload] = useState(false); // reload 토글 될 때 마다 useEffect 호출
  const [docRef] = useState(docId && db.collection(collection).doc(docId));
  const [where] = useState(initialWhere);

  useEffect(() => {
    const loadOneData = docId =>
      db
        .collection(collection)
        .doc(docId)
        .get()
        .then(doc => ({ ...doc.data(), id: doc.id }));

    const loadDatas = _ =>
      db
        .collection(collection)
        .get()
        .then(snapshots => {
          let result = [];
          snapshots.forEach(doc => {
            result.push({ ...doc.data(), id: doc.id });
          });
          return result;
        });

    const loadWhereDatas = _ => {
      return db
        .collection(collection)
        .where(...where) // ex. [["active"], ["=="], ["true"]]
        .get()
        .then(snapshots => {
          let result = [];
          snapshots.forEach(doc => {
            result.push({ ...doc.data(), id: doc.id });
          });
          return result;
        });
    };

    const fetchData = async _ => {
      setIsError(false);
      setIsLoading(true);

      try {
        const result = docId
          ? await loadOneData(docId)
          : where
          ? await loadWhereDatas()
          : await loadDatas();
        setData(result);
      } catch (error) {
        setIsError(true);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [collection, docId, db, where, reload]);

  return [
    { data, isLoading, isError, db, docRef, reload: () => setReload(reload => !reload), setData },
    setCollection
  ];
};

export const getPromiseArray = async (arr, collection) => {
  const { db } = window.firebase;

  const promises = arr.map(
    id =>
      new Promise((resolve, reject) =>
        db
          .collection(collection)
          .doc(id)
          .get()
          .then(doc => resolve({ ...doc.data(), id: doc.id }))
          .catch(err => reject(new Error(err)))
      )
  );
  return await Promise.all(promises);
};
