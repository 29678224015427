import React, { useState, useMemo } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { MdAttachFile } from 'react-icons/md';
import { sortBy } from 'lodash';
import Pagination from '../../components/Pagination';
import { format, isSameDay, isSameYear } from 'date-fns';
import { Link } from 'react-router-dom';
import Status from '../Status';

const StyledTable = styled.table`
  width: 100%;
  font-size: 14px;
  border-collapse: collapse;
  border: 1px solid #ccc;
  text-align: center;
  min-width: 400px;
  background: #fff;
  color: #666;
  margin-top: 1rem;
  td,
  th {
    padding: 0.6em 1em;
    border-bottom: 1px solid #dfdfdf;
  }
  th {
    font-size: 12px;
    font-weight: normal;
    padding: 1em;
    color: #000;
  }
  .hover-line {
    &:hover {
      .underline {
        text-decoration: underline;
      }
    }
  }
  .number {
    font-size: 12px;
  }
  .index {
    font-size: 12px;
    text-align: center;
    width: 47px;
    max-width: 47px;
  }
  .right {
    text-align: right;
  }
  .title {
    text-align: left;
    text-overflow: nowrap;
    overflow: hidden;
    min-width: 100px;
  }
  a {
    color: inherit;
  }
`;

type TrProps = {
  isNew?: boolean;
};

const StyledTr = styled.tr<TrProps>`
  color: ${({ isNew, theme }) => isNew && theme.mainColor};
`;

type User = {
  name: string;
  userId: string;
  companyName?: string;
};

type BoardProps = {
  name: string;
  title?: string;
  type?: string;
  createdAt: Date;
  file?: object;
  notice?: boolean;
  commentsCount: number;
  id: string;
  viewCount: number;
  status?: string;
  user: User;
};

type Props = {
  data: BoardProps[];
  themeColor?: string;
  perPage: number;
  countUp: (id: string) => void;
  notice?: boolean;
};

export const NewMark = styled.span`
  background: #ff4947;
  color: #fff;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  font-size: 8px;
  font-weight: bold;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 4px;
  &::before {
    content: 'N';
  }
`;

export const NoticeMark = styled.span`
  background: #ff6d72;
  color: #fff;
  font-weight: bold;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 4px;
  width: 42px;
  height: 20px;
  border-radius: 4px;
  &::before {
    content: '공지';
  }
`;

function Board({ data, themeColor, perPage, countUp, notice }: Props) {
  const [page, setPage] = useState(1);
  const [sortData] = useState(sortBy(data, 'createdAt').reverse()); // 전체 정렬용
  // const [sort, setSort] = useState(true); // 상단 오름, 내림차순 정렬 선택
  const THEME_COLOR = themeColor || '#228be6';
  const LAST_PAGE = Math.ceil(sortData.length / perPage);
  const [today] = useState(new Date());

  const viewData = useMemo(() => {
    // 페이지수마다 잘라서 보여줄 데이터
    return sortData.slice((page - 1) * perPage, (page - 1) * perPage + perPage);
  }, [page, perPage, sortData]);

  const dateFormat = (date: Date) => {
    if (isSameDay(date, today)) {
      return format(date, 'HH:mm');
    }
    if (isSameYear(date, today)) {
      return format(date, 'yyyy.MM.dd.');
    }
    return format(date, 'yyyy.MM.dd.');
  };

  return (
    <ThemeProvider theme={{ mainColor: THEME_COLOR }}>
      <StyledTable>
        <thead>
          <tr>
            <th className="index" />
            <th>제목</th>
            <th style={{ width: 100 }}>작성자</th>
            <th style={{ width: 60 }}>작성일시</th>
            <th style={{ width: 40 }}>조회수</th>
            <th style={{ width: 60 }}>처리상태</th>
          </tr>
        </thead>
        <tbody>
          {/* 문의글 */}
          {!notice &&
            viewData
              .filter(item => !item.notice)
              .map((item, dataIndex) => (
                <StyledTr key={dataIndex}>
                  <td className="index">{item.id}</td>
                  <td className="hover-line title" onClick={_ => countUp(item.id)}>
                    <Link to={`/board/view/${item.id}`} className="underline">
                      {item.type}
                    </Link>
                    {item.file && (
                      <MdAttachFile
                        color={THEME_COLOR}
                        style={{ marginBottom: '-2px', marginLeft: '4px' }}
                      />
                    )}
                    {item.commentsCount > 0 && (
                      <span
                        style={{ color: THEME_COLOR, fontSize: '12px', fontWeight: 'bold' }}
                      >{` [${item.commentsCount}]`}</span>
                    )}
                    {isSameDay(item.createdAt, today) && <NewMark />}
                  </td>
                  <td>{item.user.companyName}</td>
                  <td className="number">{dateFormat(item.createdAt)}</td>
                  <td className="number">{item.viewCount}</td>
                  <td>
                    <Status
                      msg={item.status}
                      type={
                        item.status === '처리완료' ? 'SUCCESS' : item.status === '반려' && 'FAIL'
                      }
                    />
                  </td>
                </StyledTr>
              ))}
        </tbody>
      </StyledTable>
      <Pagination
        page={page}
        lastPage={LAST_PAGE}
        onClickPage={num => {
          setPage(num);
        }}
        onPrev={() => {
          page !== 1 && setPage(page - 1);
        }}
        onNext={() => {
          LAST_PAGE !== 0 && page !== LAST_PAGE && setPage(page + 1);
        }}
      />
    </ThemeProvider>
  );
}

export default Board;
