import React, { useState, useContext } from 'react';
import { FirebaseContext } from '../firebase';
import csv2json from 'csvjson-csv2json';
import { Container, FileInput, Button } from '../components';
import axios from 'axios';
import jsonpAdapter from 'axios-jsonp';
import { find } from 'lodash';
import * as geofirex from 'geofirex';
import toast from '../components/Toast';

const JUSO_KEY = process.env.REACT_APP_JUSO_KEY;
const KAKAO_KEY = process.env.REACT_APP_KAKAO_APP_KEY;

const Sample = () => {
  const { db, app } = useContext(FirebaseContext);
  const [uploadData, setUploadData] = useState([]);

  const getAddressData = async (keyword, id) => {
    const geo = geofirex.init(app);
    const enData = await axios({
      method: 'get',
      url: `https://www.juso.go.kr/addrlink/addrLinkApiJsonp.do?confmKey=${JUSO_KEY}=&keyword=${keyword}&resultType=json&countPerPage=20`,
      adapter: jsonpAdapter
    }).then(result => {
      const arr = result.data.results.juso.map(juso => juso.engAddr);
      return arr.length > 0 && arr[0];
    });

    const addressData = await axios({
      method: 'get',
      url: `https://dapi.kakao.com/v2/local/search/address.json?query=${keyword}&size=30`,
      headers: {
        Authorization: `KakaoAK ${KAKAO_KEY}`
      }
    }).then(result => {
      const { documents } = result.data;
      return documents.length > 0 && documents[0];
    });

    const extras = addressData.address || addressData.road_address;
    return {
      id,
      address: extras
        ? {
            road: (addressData.road_address && addressData.road_address.address_name) || '',
            jibun: addressData.address && addressData.address.address_name,
            extras,
            en: enData
          }
        : { road: '', jibun: '' },
      position: extras ? geo.point(parseFloat(extras.y), parseFloat(extras.x)).data : ''
    };
  };

  const fileUpload = e => {
    const file = e.target.files[0];
    if (file && file.type !== 'text/csv') {
      alert('.csv 파일만 업로드 가능합니다.');
      return null;
    }

    const readFileContent = file => {
      const reader = new FileReader();
      return new Promise((resolve, reject) => {
        reader.onload = e => resolve(e.target.result);
        reader.onerror = err => reject(err);
        reader.readAsText(file);
      });
    };

    let data = [];
    readFileContent(file).then(async csv => {
      csv2json(csv).forEach(item => {
        const address = item.road.replace(/\(/gi, '').replace(/\)/gi, '');
        const splited = address.split(',');
        const keyword = splited && splited.length > 1 ? splited[0].split(' ') : address.split(' ');

        data.push({
          realName: item.realName,
          phone: item.phone,
          minprice: 18000,
          deliveryFee: 2000,
          memo: item.road.replace(/\(/gi, '').replace(/\)/gi, ''),
          code: item.code,
          timeData: {
            openIsNoon: '오후',
            open_hour: 12,
            open_minute: 0,
            closeIsNoon: '오전',
            close_hour: 12,
            close_minute: 0,
            isAllDayOpen: false
          },
          keyword:
            (keyword.length > 2 ? keyword[keyword.length - 3] + ' ' : '') +
            keyword[keyword.length - 2] +
            ' ' +
            keyword[keyword.length - 1]
        });
      });

      const promises = data.map((item, index) => getAddressData(item.keyword, index));
      const result = await Promise.all(promises);

      const final = data
        .map((item, index) => ({
          ...item,
          ...find(result, data => data.id === index)
        }))
        .map(({ id, keyword, ...rest }) => ({
          ...rest,
          createdAt: new Date(),
          group: ['LmXTnR4eJASijq9svwIJcfTovZW2']
        }));
      console.log(final);
      setUploadData(final);
    });
  };

  const submitData = () => {
    const batch = db.batch();
    uploadData.forEach(item => {
      const ref = db.collection('stores').doc(item.code);
      return batch.set(ref, item);
    });
    batch
      .commit()
      .then(() => toast('업데이트 되었습니다.'))
      .catch(err => {
        console.log(err);
        toast('failed');
      });
  };

  const loadAllData = async () => {
    const result = await db
      .collection('stores')
      .orderBy('code')
      .get()
      .then(snapshots => {
        let result = [];
        snapshots.forEach(doc => result.push(doc.data()));
        return result.filter(item => !item.createdAt);
      });
    console.log(result);
    const batch = db.batch();
    result.forEach(item => {
      const ref = db.collection('stores').doc(item.code);
      return batch.update(ref, { createdAt: new Date() });
    });
    batch
      .commit()
      .then(() => toast('업데이트 되었습니다.'))
      .catch(err => {
        console.log(err);
        toast('failed');
      });
  };

  return (
    <Container>
      <b>업체일괄 업로드</b>
      <p>.csv 파일 이용하여 업로드 가능</p>
      <div>사용법 숙지 후 사용할것!</div>
      <FileInput onChange={fileUpload} name="xlsx" />
      <Button onClick={submitData} type="primary">
        업로드하기
      </Button>
      <button
        onClick={() => {
          loadAllData();
        }}
      >
        테스트
      </button>
    </Container>
  );
};

export default Sample;
