import React from 'react';
import styled from 'styled-components';

const Normal = styled.div`
  font-size: 14px;
  display: flex;
  flex-direction: column;
  width: 100%;
  .photo {
    width: 200px;
    height: 125px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 4px;
  }
  .container-title {
    font-size: 18px;
    font-weight: 800;
    @media (max-width: 480px) {
      font-size: 16px;
      font-weight: 600;
    }
  }
  @media (max-width: 480px) {
    font-size: 12px;
  }
  .navigator {
    font-size: 12px;
    color: #aaa;
    min-height: 20px;
    margin-top: 0.2rem;
    font-weight: 600;
    @media (max-width: 480px) {
      font-size: 10px;
      font-weight: 400;
    }
  }
  .media-flex {
    display: flex;
    align-items: center;
    @media (max-width: 600px) {
      flex-direction: column;
    }
  }
`;

const Small = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0.5rem;
  .title {
    padding: 0.5rem;
    font-weight: bold;
    font-size: 16px;
  }
  .photo {
    width: 200px;
    height: 125px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 4px;
  }
`;

const Container = ({ children, title, buttons, navigator, type, ...rest }) => {
  switch (type) {
    case 'small':
      return (
        <Small {...rest}>
          <div className="title">{title}</div>
          {children}
        </Small>
      );
    default:
      return (
        <Normal {...rest}>
          <div
            style={{
              borderBottom: '1.5px solid #ccc',
              padding: '0.5rem 0',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
              margin: '1rem 0'
            }}
          >
            <div>
              <span className="container-title">{title}</span>
              <div className="navigator">{navigator}</div>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              {buttons}
            </div>
          </div>
          {children}
        </Normal>
      );
  }
};

export default Container;
