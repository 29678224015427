import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import logo from '../../images/logo.svg';
import { useOnClickOutside } from '../../common/hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/free-solid-svg-icons';

const Container = styled.div`
  min-width: 320px;
  max-width: 320px;
  height: 620px;
  border-radius: 36px;
  border: 8px solid rgba(0, 0, 0, 0.6);
  box-shadow: 2px 4px 8px 4px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const MainImage = styled.div`
  width: 100%;
  max-height: ${window.innerWidth * 1.25 + 'px'};
  min-height: 480px;
  background: ${({ src }) =>
    src ? `url(${src})` : 'linear-gradient(to bottom, rgba(0,0,0,0.6), rgba(0,0,0,0.2))'};
  font-size: 12px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: white;
  .subtitle {
    color: rgba(255, 255, 255, 0.7);
    font-size: 16px;
    font-weight: bold;
  }
  .title {
    text-shadow: 0 0 16px rgba(0, 0, 0, 0.5);
    font-size: 24px;
    font-weight: bold;
    line-height: 1.17;
    padding: 0 2rem;
  }
  .comment {
    font-size: 14px;
    line-height: 1.23;
    color: rgba(255, 255, 255, 0.9);
    text-align: left;
    margin-bottom: 1.5rem;
    padding: 0 5%;
  }
`;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: solid 1px #e6e6e6;
  min-height: 47px;
  position: sticky;
  top: 0;
  background: white;
  .logo {
    margin-left: 5%;
    width: 96px;
    height: 24px;
    transition: opacity 0.8s ease-out;
    -webkit-transition: opacity 0.8s ease-out;
  }
`;

const View = ({ data, setData }) => {
  return (
    <Container id="view-scroll">
      <Header>
        <img src={logo} alt="" className="logo" />
      </Header>
      <MainImage src={data.bg_img}>
        <label
          style={{
            marginTop: '10px',
            cursor: 'pointer',
            textShadow: '2px 2px 6px rgba(0,0,0,0.7)',
            fontWeight: 'bold'
          }}
          htmlFor="main-image"
        >
          {!data.bg_img ? (
            <>
              <FontAwesomeIcon icon={faImage} size="lg" color="white" />
              &nbsp;<span> 여기를 클릭하여 메인 이미지를 선택하세요.</span>
            </>
          ) : (
            <>
              <FontAwesomeIcon icon={faImage} size="lg" color="white" />
              &nbsp;<span> 수정하기</span>
            </>
          )}
        </label>
        <input
          type="file"
          id="main-image"
          style={{ width: 0, height: 0, visibility: 'hidden' }}
          onChange={e => {
            const file = e.target.files[0];
            if (!file) return null;
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = e => {
              setData({ ...data, bg_img: e.target.result, bg_img_file: file });
            };
          }}
        />
        <div style={{ textAlign: 'center' }}>
          <EditableInput
            value={data.subtitle && data.subtitle.kr}
            className="subtitle"
            placeholder="부제목을 입력하세요"
            onChange={e => {
              setData({ ...data, subtitle: { kr: e.target.value } });
            }}
          />
          <EditableInput
            value={data.title && data.title.kr}
            className="title"
            placeholder="제목을 입력하세요"
            onChange={e => {
              setData({ ...data, title: { kr: e.target.value } });
            }}
          />
        </div>
        <EditableInput
          value={data.comment && data.comment.kr}
          className="comment"
          placeholder="설명을 입력하세요"
          onChange={e => {
            setData({ ...data, comment: { kr: e.target.value } });
          }}
        />
      </MainImage>

      {data.contents.length === 0 && (
        <div
          style={{
            textAlign: 'center',
            marginTop: '0.5rem',
            userSelect: 'none'
          }}
        >
          우측에서 편집 박스를 추가하세요.
        </div>
      )}
      {data.contents.map((item, index) => (
        <ContentItem data={item} key={`view-${index}`} id={`view-${item.id}`} />
      ))}
    </Container>
  );
};

export default View;

const EditableInput = ({ onChange, value, className, placeholder }) => {
  const [isEdit, setIsEdit] = useState(false);
  const ref = useRef();
  useOnClickOutside(ref, () => setIsEdit(false));

  return isEdit ? (
    <input
      style={{
        outline: 'none',
        border: 'none',
        borderRadius: '4px',
        background: 'none',
        color: 'white',
        textAlign: 'center',
        minWidth: '280px'
      }}
      autoFocus
      className={className}
      ref={ref}
      defaultValue={value}
      type="text"
      onChange={onChange}
      onKeyDown={e => {
        if (e.keyCode === 13) {
          setIsEdit(false);
        }
      }}
    />
  ) : value ? (
    <div
      className={className}
      onClick={() => {
        setIsEdit(true);
      }}
    >
      {value}
    </div>
  ) : (
    <div
      style={{
        background: 'rgba(0,0,0,0.1)',
        borderRadius: '4px',
        marginTop: '3px',
        boxShadow: '0 0 2px 0.5px white',
        minWidth: '280px',
        minHeight: '16px',
        padding: '0 0.5rem',
        textAlign: 'center'
      }}
      className={className}
      onClick={() => {
        setIsEdit(true);
      }}
    >
      {placeholder}
    </div>
  );
};

const Image = styled.div`
  background-image: ${props => `url(${props.img})`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  margin-bottom: 1rem;
  border-radius: 4px;
  min-width: 100%;
  min-height: 180px;
`;

const ContentItem = ({ data, id }) => {
  return (
    <>
      <div style={{ width: '90%', margin: '32px 5% 0 5%' }} id={id}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          {data.smallbar && (
            <div
              style={{
                width: '12.5%',
                height: '2px',
                borderTop: '1px solid #e6e6e6',
                marginBottom: '40px',
                ...data.style
              }}
            />
          )}
          {data.sectionTitle && (
            <div
              style={{
                width: '100%',
                textAlign: 'center',
                color: '#55607d',
                fontSize: '16px',
                lineHeight: 1.25,
                fontWeight: 'bold',
                ...data.style
              }}
            >
              {data.sectionTitle.kr}
            </div>
          )}
        </div>

        {data.photo && <Image img={data.photo} />}
        {data.title && (
          <div
            style={{
              marginBottom: '0.5rem',
              fontSize: '18px',
              fontWeight: 'bold',
              ...data.titleStyle
            }}
          >
            {data.title.kr}
          </div>
        )}

        <div style={data.style}>
          {data.text &&
            data.text.kr &&
            data.text.kr.split('<br/>').map((line, index) => (
              <span key={`line-${index}`}>
                {line}
                <br />
              </span>
            ))}
        </div>
      </div>
    </>
  );
};
