import React, { useState, useContext, useEffect } from 'react';
import { FirebaseContext } from '../../firebase';
import { find } from 'lodash';
import { Container, Loader, Button, Input, Notice } from '../../components';
import toast from '../../components/Toast';

function CopyStore(props) {
  const { id } = props.match.params;
  const [code, setCode] = useState();
  const { db } = useContext(FirebaseContext);
  // const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [storeName, setStoreName] = useState();

  useEffect(() => {
    db.collection('stores')
      .doc(id)
      .get()
      .then(doc => setStoreName(doc.data().realName));
  }, [db, id]);

  const getLastCopiedId = () => {
    return db
      .collection('menues')
      .where('isCopied', '==', true)
      .orderBy('createdAt', 'desc')
      .limit(1)
      .get()
      .then(snapshots => {
        const id = snapshots.docs[0].id;
        return id;
      });
  };

  const loadOptions = menuId => {
    return db
      .collection('options')
      .where('menu_id', '==', menuId)
      .get()
      .then(snapshots => {
        let result = [];
        snapshots.forEach(doc => result.push({ ...doc.data(), id: doc.id }));
        return result;
      });
  };

  const loadMenu = code =>
    db
      .collection('menues')
      .where('store_id', '==', code)
      .get()
      .then(async snapshots => {
        const lastCopiedId = await getLastCopiedId();
        let data = [];
        snapshots.forEach(doc => {
          data.push({ ...doc.data(), id: doc.id, store_id: id });
        });
        const optionPromises = data.map(item => loadOptions(item.id));
        const optionData = await Promise.all(optionPromises).then(result => result.flat());

        const result = data.map(item => {
          const options = find(optionData, option => option.menu_id === item.id);
          return { ...item, options };
        });

        return result.map((item, index) => {
          let newData = {
            ...item,
            id: `copied_${parseInt(lastCopiedId.split('_')[1]) + index + 1}`
          };

          if (item.options) newData.options = { ...item.options, menu_id: newData.id };
          return newData;
        });
      });

  const loadExistMenu = code =>
    db
      .collection('menues')
      .where('store_id', '==', code)
      .get()
      .then(async snapshots => {
        let data = [];
        snapshots.forEach(doc => {
          data.push({ ...doc.data(), id: doc.id, store_id: id });
        });
        const optionPromises = data.map(item => loadOptions(item.id));
        const optionData = await Promise.all(optionPromises).then(result => result.flat());

        const result = data.map(item => {
          const options = find(optionData, option => option.menu_id === item.id);
          return { ...item, options };
        });

        return result.map(item => {
          let newData = {
            ...item
          };

          if (item.options) newData.options = { ...item.options };
          return newData;
        });
      });

  const loadStoreData = () => {
    return db
      .collection('stores')
      .doc(code)
      .get()
      .then(doc => doc.data());
  };

  const copyData = async () => {
    if (!code) return alert('복사할 업체 코드를 입력하세요.');
    const batch = db.batch();

    const existMenu = await loadExistMenu(id);

    const existOptions = existMenu
      .map(({ options, ...item }) => options)
      .filter(option => option !== undefined);

    // 기존메뉴삭제
    existMenu.forEach(({ id }) => {
      const ref = db.collection('menues').doc(id);
      batch.delete(ref);
    });

    // 기존옵션삭제
    existOptions.forEach(({ id }) => {
      const ref = db.collection('options').doc(id);
      batch.delete(ref);
    });

    setIsLoading(true);
    const storeData = await loadStoreData();

    if (!storeData) {
      setIsLoading(false);
      return alert('해당코드의 업체가 검색 되지 않습니다.');
    }
    let result = window.confirm(`[${storeData.realName}]의 데이터를 복사하시겠습니까?`);
    if (!result) {
      return setIsLoading(false);
    }
    const { menuCategories, name_id, name, photo, tags } = storeData;
    const menus = await loadMenu(code);
    console.log(menus);
    console.log(menuCategories);

    const newMenus = menus.map(({ options, ...item }) => ({
      ...item,
      isCopied: true,
      createdAt: new Date(),
      editedAt: new Date()
    }));
    const newOptions = menus
      .map(({ options, ...item }) => options)
      .filter(option => option !== undefined)
      .map(option => ({ ...option, store_id: id }));

    console.log(newMenus);
    console.log(newOptions);

    // 이 store에 menuCategories update
    batch.update(db.collection('stores').doc(id), { menuCategories, name_id, name, photo, tags });

    // 메뉴 복사
    newMenus.forEach(item => {
      const ref = db.collection('menues').doc(item.id);
      batch.set(ref, item);
    });

    // 옵션 복사
    newOptions.forEach(item => {
      const ref = db.collection('options').doc();
      batch.set(ref, item);
    });

    batch.commit().then(_ => {
      toast('업데이트 되었습니다.');
      setIsLoading(false);
      props.history.push(`/store/menu/${id}`);
    });
  };

  return (
    <Container title={storeName && storeName} navigator="배달업체수정 > 메뉴관리 > 메뉴복사">
      {isLoading && <Loader />}
      <Input
        title="복사할업체코드"
        onChange={e => {
          setCode(e.target.value);
        }}
      />
      <Button onClick={copyData}>복사실행</Button>
      <Notice>
        메뉴, 옵션, 메뉴 카테고리, 태그, 사진 및 번역이 복사됩니다. <br />
        복사 작업시 기존 메뉴는 전부 삭제됩니다.
      </Notice>
    </Container>
  );
}

export default CopyStore;
