import React, { useContext, useState, useMemo } from 'react';
import { FirebaseContext } from '../../firebase';
import { getNewCode } from '../../common/fetch';
import {
  Form,
  Gap,
  Loader,
  Address,
  Dropdown,
  Modal,
  Div,
  Button,
  Input,
  Toast as toast,
  Container,
  Textarea,
} from '../../components';
import { UserStateContext } from '../../contexts/UserContext';
import { Logger } from '../../common/hooks';
import { categoryList } from '../../common/category';

const AddHotel = (props) => {
  const { db } = useContext(FirebaseContext);
  const user = useContext(UserStateContext);
  const [data, setData] = useState({
    category: [0, 1, 2, 3, 4, 5, 6, 7],
    status: '미계약',
  });
  const [time, setTime] = useState({
    isAllDayOpen: false,
    open_hour: 0,
    open_minute: 0,
    close_hour: 0,
    close_minute: 0,
  });
  const [isAddressOpen, setAddressOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onChangeInput = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const onChangeNumber = (e) => {
    const value = e.target.value === '' ? '' : parseInt(e.target.value);
    if (Number.isNaN(value) && value !== '') {
      e.target.value = null;
      return alert('숫자만 입력가능합니다.');
    }
    setData({
      ...data,
      [e.target.name]: value,
    });
  };

  const onAdd = async () => {
    setIsLoading(true);
    const code = await getNewCode('hotels');
    const hotelRef = db.collection('hotels').doc(code);
    let newData = {
      ...data,
      chargePerson: data.chargePerson || user.name,
      companyName: user.companyName,
      time: `${time.open_hour}:${time.open_minute === 0 ? '00' : time.open_minute}~${
        time.close_hour
      }:${time.close_minute === 0 ? '00' : time.close_minute}`,
      timeData: time,
      createdAt: new Date(),
      group: [...(user.group || []), user.id],
      logs: [new Logger(user, false).toObject()],
    };

    hotelRef.get().then((result) => {
      if (result.exists) {
        alert('등록시 에러가 발생했습니다. 다시 시도해주세요.');
        setIsLoading(false);
      } else {
        result.ref.set(newData).then(() => {
          toast('업데이트 되었습니다.');
          setIsLoading(false);
          props.history.push('/hotel');
        });
      }
    });
  };

  const showAddress = useMemo(() => {
    return data && data.address && `${data.address.road || ''} ${data.address.detail || ''}`;
  }, [data]);

  const changeCategory = (e) => {
    const { value, checked } = e.target;
    const newData = data.category || [];

    if (checked) {
      setData({
        ...data,
        category: Array.from(new Set(newData.concat(parseInt(value)))),
      });
    } else {
      // 삭제
      setData({
        ...data,
        category: Array.from(new Set(newData.filter((item) => item !== parseInt(value)))),
      });
    }
  };

  const onChangeTime = (e) => {
    setTime({ ...time, [e.target.name]: parseInt(e.target.value) });
  };

  return (
    <>
      <Container title="숙박업체관리" navigator="숙박업체목록 > 신규업체추가">
        <div>
          <Form onSubmit={onAdd} requires={['address']} data={data}>
            {user && (
              <>
                <Input defaultValue={user.companyName} title={'협력사명'} name="name" disabled />
                <Input
                  defaultValue={user.name}
                  title="관리담당자명"
                  onChange={onChangeInput}
                  name="chargePerson"
                />
              </>
            )}
            <Gap />
            <Div title="업종분류" required>
              <Dropdown
                name="type"
                onChange={onChangeInput}
                required
                options={[
                  { text: '호텔' },
                  { text: '모텔' },
                  { text: '게스트하우스' },
                  { text: '펜션' },
                ]}
              />
            </Div>
            <Input required title={'업체명'} onChange={onChangeInput} name="name" />
            <Input title={'업체명(영문)'} onChange={onChangeInput} name="name_en" />

            <Input
              required
              title={'전화번호'}
              onChange={onChangeInput}
              name="phone"
              placeholder={`'-' 포함한 전체 번호`}
            />
            <Gap>
              <Input
                value={showAddress}
                title={'주소'}
                name="address"
                style={{ width: '60%' }}
                disabled
                required
              >
                <Button
                  type="primary"
                  onClick={() => {
                    setAddressOpen(true);
                  }}
                >
                  주소검색
                </Button>
              </Input>

              <Input
                value={data && data.address && data.address.en}
                title={'영문주소'}
                name="address"
                style={{ width: '60%' }}
                disabled
              />
            </Gap>

            <Input title={'대표자명'} onChange={onChangeInput} name="owner" />
            <Input
              title={
                <>
                  쉐어금액
                  <br />
                  (계약시 입력)
                </>
              }
              onChange={onChangeNumber}
              name="serviceFee"
              placeholder="단위(원)(숫자만입력)"
            />

            <Input
              style={{ width: '450px' }}
              title={'주문시 요청사항 (기본설정)'}
              onChange={onChangeInput}
              name="defaultMessage"
            />

            <Gap>
              <Div title="영업시간" required>
                <div className="media-flex">
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Dropdown
                      options={timeArray.map((item) => ({
                        text: item,
                      }))}
                      className="small"
                      name="open_hour"
                      onChange={onChangeTime}
                      defaultValue={time.open_hour}
                    />

                    <Dropdown
                      options={[0, 15, 30, 45].map((item) => ({
                        text: item,
                      }))}
                      name="open_minute"
                      className="small"
                      onChange={onChangeTime}
                      defaultValue={time.open_minute}
                    />
                  </div>
                  <span style={{ margin: '0 0.5rem', fontSize: '18px', fontWeight: 'bold' }}>
                    ~
                  </span>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Dropdown
                      options={timeArray.map((item) => ({
                        text: item,
                      }))}
                      className="small"
                      name="close_hour"
                      onChange={onChangeTime}
                      defaultValue={time.close_hour}
                    />
                    <Dropdown
                      options={[0, 15, 30, 45].map((item) => ({
                        text: item,
                      }))}
                      className="small"
                      name="close_minute"
                      onChange={onChangeTime}
                      defaultValue={time.close_minute}
                    />
                  </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginTop: '1rem' }}>
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      setTime({ ...time, isAllDayOpen: e.target.checked });
                    }}
                    defaultChecked={time.isAllDayOpen}
                  />
                  <div>24시간영업</div>
                </div>
              </Div>
            </Gap>

            <Div title="사용카테고리">
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {categoryList.map((item, index) => (
                  <React.Fragment key={index}>
                    <input
                      type="checkbox"
                      value={index}
                      defaultChecked={true}
                      onChange={changeCategory}
                    />
                    <span style={{ marginRight: '0.5rem' }}>{item}</span>
                  </React.Fragment>
                ))}
              </div>
            </Div>
            <Gap>
              <Textarea
                title="메모"
                onChange={(e) => {
                  setData({ ...data, [e.target.name]: e.target.value.replace(/\n/g, '<br/>') });
                }}
                name="memo"
                placeholder="등록시 전달사항이 있으면 남겨주세요."
              />
            </Gap>
          </Form>
        </div>
        {isLoading && <Loader />}
        {/* 주소입력용 모달 */}
        {isAddressOpen && (
          <Modal
            onClose={() => {
              setAddressOpen(false);
            }}
          >
            <Address setData={setData} setAddressOpen={setAddressOpen} />
          </Modal>
        )}
      </Container>
    </>
  );
};

export default AddHotel;

const timeArray = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23,
];
