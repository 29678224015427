import React, { useContext, useState } from 'react';
import {
  Input,
  Modal,
  Div,
  Button,
  Tag,
  Container,
  Toast as toast,
  Notice,
  FileInput,
  Loader
} from '../../components';
import { FirebaseContext } from '../../firebase';
import SelectTag from '../7_Tags/SelectTag';
import DbListModal from '../8_SystemMenu/SystemMenuListModal';
import { uploadFile } from '../../common/fetch';
import { UserStateContext } from '../../contexts/UserContext';

const AddMenu = props => {
  const { id } = props.match.params;
  const { db } = useContext(FirebaseContext);
  const user = useContext(UserStateContext);
  const [data, setData] = useState(null);

  const [isWordOpen, setWordOpen] = useState(false);
  const [isTagOpen, setTagOpen] = useState(false);
  const [status] = useState('');

  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const onChangeInput = e => {
    setData({
      ...data,
      [e.target.name]: e.target.name === 'price' ? parseInt(e.target.value) : e.target.value
    });
  };

  const onEdit = async _ => {
    if (isLoading) return null;
    setIsLoading(true);

    let newData = { ...data };
    if (file) {
      const uploaded = await uploadFile(`menus/${id}`, file, 'photo');
      newData.photo = uploaded.url;
    }

    db.collection('menues')
      .doc()
      .set({ ...newData, createdAt: new Date(), store_id: id })
      .then(() => {
        toast('업데이트 되었습니다.');
        props.history.push(`/store/menu/${id}`);
      });
  };

  return (
    <>
      <Container title="배달업체관리" navigator="배달업체목록 > 배달업체수정 > 메뉴관리 > 메뉴추가">
        {isLoading && <Loader />}
        <div>
          <Input
            title={'메뉴명 (한국어)'}
            defaultValue={data && data.realName}
            onChange={onChangeInput}
            name="realName"
          />
          {user && user.authLevel === 'ROOT' && (
            <Input
              value={data && data.name_id && `${data.name} (${data.name_id})`}
              title={'메뉴매칭'}
              name="name"
              disabled
            >
              <Button
                type="primary"
                onClick={() => {
                  setWordOpen(true);
                }}
              >
                DB검색
              </Button>
            </Input>
          )}
          <Input title={'가격'} onChange={onChangeInput} name="price" placeholder={`단위(원)`} />

          <Div title={'대표사진'}>
            <div style={{ marginBottom: '0.5rem' }}>
              <div
                className="photo"
                style={{
                  backgroundColor: '#dfdfdf',
                  backgroundImage: `url(${data && data.photo})`
                }}
              />
            </div>
            <FileInput
              onChange={e => {
                const file = e.target.files[0];
                if (!file) return;
                if (file.type.split('/')[0] !== 'image')
                  return alert('이미지파일만 업로드 가능합니다.');
                let reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = e => {
                  setData({ ...data, photo: e.target.result });
                  setFile(file);
                };
              }}
            />
          </Div>
          <Notice style={{ marginLeft: '120px' }}>
            사진 업로드 안내 <br />
            5:3 비율을 권장합니다. <br />
            비율이 안맞을 경우 자동으로 중앙정렬해서 보여집니다. <br /> 권장 해상도: 480x288, 권장
            파일 사이즈: 100KB 미만
          </Notice>

          {user && user.authLevel === 'ROOT' && (
            <>
              <Div title={'태그(설명)'}>
                {data && data.tags && data.tags.map(tag => <Tag id={tag} />)}
              </Div>
              <span />
              <Button
                style={{
                  marginLeft: '120px',
                  background: '#339af0'
                }}
                onClick={() => {
                  setTagOpen(true);
                }}
              >
                태그등록
              </Button>
            </>
          )}

          <div className="flex" style={{ marginTop: '2rem' }}>
            <Button
              width="50%"
              onClick={(status === '업로드완료' || status === '') && onEdit}
              style={{
                background: status === '업로드완료' || status === '' ? '#339af0' : 'gray'
              }}
            >
              등록
            </Button>
          </div>
        </div>

        {/* 단어입력용 모달 */}
        {isWordOpen && (
          <Modal
            onClose={() => {
              setWordOpen(false);
            }}
            style={{ width: '70%' }}
          >
            <DbListModal
              register={(name_id, name, photo, tags) => {
                setData({
                  ...data,
                  name_id,
                  name,
                  photo,
                  tags
                });
                setWordOpen(false);
              }}
            />
          </Modal>
        )}
        {/* 태그입력용 */}
        {isTagOpen && (
          <Modal
            onClose={() => {
              setTagOpen(false);
            }}
          >
            <SelectTag
              current={[]}
              register={tags => {
                setData({
                  ...data,
                  tags
                });
                setTagOpen(false);
              }}
            />
          </Modal>
        )}
      </Container>
    </>
  );
};

export default AddMenu;
