import React, { useContext, useEffect, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { find, map, sortBy } from 'lodash';
import { FirebaseContext } from '../../firebase';
import { useFirebase } from '../../common/fetch';
import { UserStateContext } from '../../contexts/UserContext';
import { jsonToSheet } from '../../common/utils';
import { formatPhone } from '../../common/format';
import { Container, Button, Status, Toast as toast, Table, Loader } from '../../components';

const depthArray = ['본사', '총판', '대리점', '영업사원'];

const headers = [
  {
    title: '구분',
    value: 'depth',
    width: '8%'
  },
  {
    title: '협력사명',
    value: 'companyName',
    render: row => (
      <Link to={`/partner/edit/${row.id}`}>
        {row.companyName}
        {row.depth === '영업사원' && ` - ${row.name}`}
      </Link>
    )
  },
  {
    title: '이름',
    value: 'name',
    align: 'center'
  },
  {
    title: '연락처',
    value: 'phone',
    render: row => formatPhone(row.phone)
  },
  {
    title: '배달업체수',
    value: 'storeCount',
    align: 'center',
    width: '10%'
  },
  {
    title: '숙박업체수',
    value: 'hotelCount',
    align: 'center',
    width: '10%'
  },
  {
    title: '상태',
    value: 'status',
    align: 'center',
    width: '10%',
    render: row =>
      row.status ? <Status type="SUCCESS" msg="등록" /> : <Status type="FAIL" msg="영업정지" />
  }
];

const downloadExcel = data => {
  let arr = [];

  data.forEach(item => {
    arr.push({
      이름: item.name,
      협력사명: item.companyName,
      핸드폰: item.phone,
      이메일: item.email,
      사업자번호: item.bn,
      주소: item.address && (item.address.road || item.address.jibun) + ' ' + item.address.detail,
      배달업체수: item.storeCount,
      숙박업체수: item.hotelCount,
      생성일: item.createdAt && item.createdAt.toDate().toLocaleDateString(),
      상태: item.status ? '등록' : '영업정지'
    });
  });
  jsonToSheet(arr, `델리퀵_협력사목록_${new Date().toLocaleDateString()}`);
};

function PartnerList(props) {
  const user = useContext(UserStateContext);
  const { db } = useContext(FirebaseContext);

  const getCount = useCallback(
    async (ids, collection) => {
      const ref = db.collection(collection);
      const promises = ids.map(id =>
        ref
          .where('group', 'array-contains', id)
          .get()
          .then(snapshots => ({ id, size: snapshots.size }))
      );
      return await Promise.all(promises);
    },
    [db]
  );

  switch (user && user.authLevel) {
    case 'ROOT':
      return <AdminList getCount={getCount} />;
    case 'NORMAL':
      return <NormalList history={props.history} getCount={getCount} />;
    default:
      return <Loader />;
  }
}

const AdminList = ({ getCount }) => {
  const [{ data, db, reload }] = useFirebase('users', null, null, ['level', '==', 'admin']);
  const [viewData, setViewData] = useState();
  const [checkedList, setCheckedList] = useState([]);

  useEffect(() => {
    if (!data) return;
    const loadData = async () => {
      const storeCountsArr = await getCount(
        map(data, item => item.id),
        'stores'
      );
      const hotelCountsArr = await getCount(
        map(data, item => item.id),
        'hotels'
      );

      setViewData(
        map(sortBy(data, 'depth'), item => ({
          ...item,
          depth: depthArray[item.depth],
          storeCount: find(storeCountsArr, { id: item.id }).size,
          hotelCount: find(hotelCountsArr, { id: item.id }).size
        }))
      );
    };
    loadData();
  }, [data, getCount]);

  const toggleActive = active => {
    if (checkedList.length === 0) return alert('선택항목이 없습니다.');
    let batch = db.batch();

    checkedList.forEach(item => {
      const ref = db.collection('users').doc(item.id);
      batch.update(ref, { status: active });
    });
    batch
      .commit()
      .then(() => {
        toast('업데이트되었습니다.');
        reload();
      })
      .catch(err => {
        console.log(err);
      });
  };

  return (
    <>
      {viewData ? (
        <Container
          title="협력사관리"
          navigator="협력사목록"
          buttons={
            <>
              <Button
                onClick={() => downloadExcel(viewData)}
                style={{ background: '#0F9D58', color: 'white' }}
              >
                엑셀저장
              </Button>
              <Button type="normal" onClick={() => toggleActive(false)}>
                정지
              </Button>
              <Button type="normal" onClick={() => toggleActive(true)}>
                활성화
              </Button>
              <Button to="/partner/add" type="normal">
                +협력사등록
              </Button>
            </>
          }
        >
          <Table
            index
            perPage={30}
            data={viewData}
            headers={headers}
            checkbox
            setCheckedList={setCheckedList}
          />
        </Container>
      ) : (
        <Loader />
      )}
    </>
  );
};

const NormalList = ({ getCount, ...props }) => {
  const user = useContext(UserStateContext);
  const [{ data }] = useFirebase('users', null, null, ['group', 'array-contains', user.id]);

  const [viewData, setViewData] = useState();

  useEffect(() => {
    if (!data) return;
    const loadData = async () => {
      const storeCountsArr = await getCount(
        map([...data, { id: user.id }], item => item.id),
        'stores'
      );
      const hotelCountsArr = await getCount(
        map([...data, { id: user.id }], item => item.id),
        'hotels'
      );

      // 맨앞에 본인 데이터 추가
      setViewData([
        {
          ...user,
          depth: depthArray[user.depth],
          storeCount: find(storeCountsArr, { id: user.id }).size,
          hotelCount: find(hotelCountsArr, { id: user.id }).size
        },
        ...map(data, item => ({
          ...item,
          depth: depthArray[item.depth],
          storeCount: find(storeCountsArr, { id: item.id }).size,
          hotelCount: find(hotelCountsArr, { id: item.id }).size
        }))
      ]);
    };

    loadData();
  }, [data, getCount, user]);

  switch (user.depth) {
    case 3:
      return <>{props.history.push(`/partner/edit/${user.id}`)}</>;
    default:
      return (
        <>
          {viewData ? (
            <Container title="협력사관리" navigator="협력사목록">
              <Table perPage={30} data={viewData} headers={headers} checkbox index />
            </Container>
          ) : (
            <Loader />
          )}
        </>
      );
  }
};

export default PartnerList;
