import React, { useContext, useState } from 'react';
import { FirebaseContext } from '../../firebase';
import { Modal, Button, Spinner } from '../../components';
import { getUniqueOrderNo, sendPushMsg } from './payment_test';
import toast from '../../components/Toast';
import { formatDeleteDash } from '../../common/format';

function SendPush({ onClose, storeData }) {
  const { db } = useContext(FirebaseContext);
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isComplete, setIsComplete] = useState(false);

  const checkOrderNumberValid = orderNumber => {
    return db
      .collection('orders')
      .doc(orderNumber)
      .get()
      .then(doc => !doc.exists);
  };

  const saveOrderData = data => {
    return db
      .collection('orders')
      .doc(data.orderNumber)
      .set(data)
      .then(() => {
        toast('테스트 주문이 전송되었습니다.');
        setIsComplete(true);
      });
  };

  const makeOrder = async () => {
    const orderNumber = getUniqueOrderNo(`GDS001`);
    const isValid = await checkOrderNumberValid(orderNumber);

    // 주문번호 중복검사후 중복이면 다시 호출
    if (!isValid) {
      return makeOrder();
    }
    let sampleOrder = {
      orderNumber,
      date: new Date(),
      cart: [
        {
          counter: 1,
          id: 'PoECBhaAsUGVpxTLax49',
          index: 2,
          language: 'en',
          name: '샘플메뉴',
          name_id: 'z3JW9F02FxTMRhvb5eBF',
          options: [
            {
              default: true,
              must: true,
              name: '옵션1',
              name_id: 'pbwQfMokvwmXvblblhzf',
              price: 0,
              realName: '옵션1'
            }
          ],
          price: 18900,
          realName: '샘플메뉴',
          store_id: 'YBZKi3ss0w66YQUJGInC',
          store_min_price: '15000',
          store_name_id: 'x3C7v4xiDnq6rXpleJqM',
          total: 18900
        }
      ],
      total: 24800,
      hotel_id: `GDS001`,
      hotelName: '서울호텔', // 숙박업체 이름
      hotel: `Seoul Hotel`,
      address: `서울시 강남구 테스트`,
      store_id: storeData.id,
      deliveryFee: 3000,
      storeName: storeData.realName,
      storeStatus: storeData.status,
      storePhone: formatDeleteDash(storeData.phone),
      pay: 'card',
      status: 1, // 주문상태 1: 주문대기
      serviceFee: 1000,
      hotelPhone: `021231234`,
      defaultMessage: `잘 부탁드립니다.`
    };
    setIsLoading(true);
    const result = await sendPushMsg(sampleOrder);

    if (result.data.error) {
      setIsComplete(true);
      setError(result.data.error);
      setIsLoading(false);
      return;
    }
    saveOrderData(sampleOrder);
    setIsLoading(false);
  };

  return (
    <Modal onClose={onClose} small>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '400px'
        }}
      >
        {!isComplete ? (
          <>
            {!isLoading ? (
              <>
                <h3>주문 테스트</h3>
                <div>이 업체와 연동되어있는 회원아이디에 샘플 주문 정보가 전송됩니다. </div>
                <Button onClick={makeOrder} style={{ width: '100%', marginTop: '1rem' }}>
                  테스트
                </Button>{' '}
              </>
            ) : (
              <>
                <Spinner />
                <div>전송중...</div>
              </>
            )}
          </>
        ) : !error ? (
          <>
            <h3>테스트주문이 완료되었습니다.</h3>
            <div>알림톡은 1분이내 전송됩니다.</div>
          </>
        ) : (
          <>
            <h3>전송실패</h3>
            <div>{error}</div>
          </>
        )}
      </div>
    </Modal>
  );
}

export default SendPush;
