import React, { useContext, useState, useEffect } from 'react';
import { FirebaseContext } from '../../firebase';
import Button from '../../components/Button';
import Loader from '../../components/Loader';
import Table from '../../components/Table/Table';
import Container from '../../components/Container';
import { jsonToSheet } from '../../common/utils';
import toast from '../../components/Toast';

const MenuDBList = () => {
  const { db } = useContext(FirebaseContext);
  const [data, setData] = useState(null);

  // console.log(data);

  useEffect(() => {
    db.collection('menudb')
      .orderBy('kr')
      .get()
      .then(snapshots => {
        let result = [];
        snapshots.forEach(doc =>
          result.push({
            ...doc.data(),
            id: doc.id,
            tagCount: (doc.data().tags && doc.data().tags.length) || 0
          })
        );
        setData(result);
      });
  }, [db]);

  return (
    <Container
      title="시스템메뉴관리"
      navigator="메뉴목록"
      buttons={
        <>
          <Button
            onClick={() => {
              let arr = [];
              data.forEach(item => {
                arr.push({
                  코드: item.id,
                  한국어: item.kr,
                  로마자: item.roman,
                  영어: item.en,
                  중국어: item.cn,
                  일본어: item.jp,
                  tags: item.tags && item.tags.toString()
                });
              });
              jsonToSheet(arr, `메뉴디비목록_${new Date().toLocaleDateString()}`);
            }}
            style={{ background: '#0F9D58', color: 'white' }}
          >
            엑셀저장
          </Button>
          <Button to={`/words/csv`} type="normal">
            일괄업로드
          </Button>
          <Button to={`/words/add`} type="normal">
            +시스템메뉴에 추가
          </Button>
        </>
      }
    >
      {data ? (
        <Table
          data={data}
          perPage={30}
          index
          headers={[
            { title: '한국어', value: 'kr' },
            { title: '로마자', value: 'roman' },
            { title: '영어', value: 'en' },
            { title: '중국어', value: 'cn' },
            { title: '일본어', value: 'jp' },
            { title: '태국어', value: 'th' },
            {
              title: '사진',
              value: 'photo',
              notSearch: true,
              width: '5%',
              render: row => (row.photo ? 'Y' : 'N')
            },
            {
              title: '태그수',
              value: 'tagCount',
              width: '10%'
            },
            {
              title: '',
              notSearch: true,
              render: row => (
                <div style={{ display: 'flex' }}>
                  <Button type="small" to={`/words/edit/${row.id}`}>
                    수정
                  </Button>
                  <Button
                    type="small"
                    onClick={() => {
                      navigator.clipboard.writeText(row.id);
                      toast('클립보드에 코드가 복사되었습니다.');
                    }}
                  >
                    코드복사
                  </Button>
                </div>
              )
            }
          ]}
          defaultPageSize={20}
        />
      ) : (
        <Loader />
      )}
    </Container>
  );
};

export default MenuDBList;
