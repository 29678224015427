import React, { useEffect, useContext, useState, useCallback } from 'react';
import Container from '../../components/Container';
import { Link } from 'react-router-dom';
import { FirebaseContext } from '../../firebase';
import Loader from '../../components/Loader';
import { formatPrice } from '../../common/format';
import DatePicker from 'react-datepicker';
import { ko } from 'date-fns/locale';
import 'react-datepicker/dist/react-datepicker.css';
import Table from '../../components/Table/Table';
import Button from '../../components/Button';
import { UserStateContext } from '../../contexts/UserContext';
import { getAllTotal } from './OrderDetail';

const adminHeaders = [
  {
    id: 'date',
    title: '주문일시',
    render: data => data.date.toDate().toLocaleString(),
    width: '12%'
  },
  {
    title: '주문번호',
    value: 'orderNumber',
    render: row => {
      return <Link to={`order/${row.id}`}>{row.orderNumber}</Link>;
    }
  },
  {
    title: '숙박업체코드',
    value: 'hotel',
    render: row => (
      <>
        <Link to={`/hotel/edit/${row.hotel_id}`}>
          {row.hotelName}
          <br />
        </Link>
        [{row.hotel_id}]
      </>
    )
  },
  {
    title: '배달업체',
    value: 'storeName',
    render: row => (
      <>
        <Link to={`/store/edit/${row.store_id}`}>
          {row.storeName}
          <br />
        </Link>
        [{row.store_id}]
      </>
    )
  },
  {
    title: '메뉴금액(+배달비)',
    value: 'total',
    render: row => formatPrice(getAllTotal(row.cart, +row.deliveryFee)) + '원'
  },
  {
    value: 'total',
    title: '고객결제금액',
    render: data => `${formatPrice(data.total)}원`
  },
  {
    title: '주문상태',
    value: 'status',
    render: row => <span>{row.status === -1 ? '주문취소' : statusList[row.status]}</span>
  },
  {
    title: '문자',
    value: 'smsResult',
    render: row => <span>{row.smsResult === 1 ? 'Y' : 'N'}</span>,
    align: 'center'
  },
  {
    title: '정산현황',
    value: 'calc',
    render: row => {
      return row.calc ? '정산완료' : row.status === 4 ? '정산대기' : '';
    }
  }
];

const normalHeaders = [
  {
    id: 'date',
    title: '주문일시',
    render: data => data.date.toDate().toLocaleString(),
    width: '12%'
  },
  {
    title: '주문번호',
    value: 'orderNumber',
    render: row => {
      return <Link to={`order/${row.id}`}>{row.orderNumber}</Link>;
    }
  },
  {
    title: '숙박업체코드',
    value: 'hotel',
    render: row => (
      <>
        {row.hotelName}
        <br />[{row.hotel_id}]
      </>
    )
  },
  {
    title: '배달업체',
    value: 'storeName',
    render: row => (
      <>
        {row.storeName}
        <br />[{row.store_id}]
      </>
    )
  },
  {
    title: '메뉴금액(+배달비)',
    value: 'total',
    render: row => formatPrice(getAllTotal(row.cart, +row.deliveryFee)) + '원'
  },
  {
    value: 'total',
    title: '고객결제가격',
    render: data => `${formatPrice(data.total)}원`
  },
  {
    title: '주문상태',
    value: 'status',
    render: row => <span>{row.status === -1 ? '주문취소' : statusList[row.status]}</span>
  },
  {
    title: '정산현황',
    value: 'calc',
    render: row => {
      return row.calc ? '정산완료' : row.status === 4 ? '정산대기' : '';
    }
  }
];

const statusList = ['', '접수대기', '접수완료', '배달중', '배달완료']; // -1: 주문취소

function OrderList() {
  const user = useContext(UserStateContext);
  switch (user && user.authLevel) {
    case 'ROOT':
      return <AdminList />;
    case 'NORMAL':
      return <NormalList />;
    default:
      return <Loader />;
  }
}

const AdminList = () => {
  const { db } = useContext(FirebaseContext);
  const [data, setData] = useState(null);
  const [checkArr, setCheckArr] = useState([]);
  const [today] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date(today.getFullYear(), today.getMonth(), 1));
  const [endDate, setEndDate] = useState(today);

  const loadData = useCallback(() => {
    db.collection('orders')
      .where('date', '>=', startDate)
      .where('date', '<=', endDate)
      .orderBy('date', 'desc')
      .get()
      .then(snapshots => {
        let result = [];
        snapshots.forEach(doc => {
          result.push({
            ...doc.data(),
            id: doc.id
          });
        });
        setData(result);
      });
  }, [db, endDate, startDate]);

  useEffect(() => {
    loadData();
  }, [db, loadData]);

  const setCalc = () => {
    var batch = db.batch();
    if (checkArr.length === 0) {
      alert('체크된 항목이 없습니다.');
    } else {
      checkArr.forEach(item => {
        let ref = db.collection('orders').doc(item.id);
        batch.update(ref, {
          calc: true,
          calcDate: new Date()
        });
      });
      batch.commit().then(() => {
        alert(`${checkArr.length}개 항목 정산처리되었습니다.`);
      });
    }
  };

  return (
    <>
      {data ? (
        <Container
          title="주문/정산 관리"
          navigator="주문/정산 목록"
          buttons={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Button
                style={{ width: 'fit-content', marginRight: '0.5rem' }}
                onClick={() => {
                  setCalc();
                }}
              >
                선택항목 정산처리
              </Button>
              <DatePicker
                selected={startDate}
                locale={ko}
                dateFormat="yyyy/MM/dd"
                onChange={d => {
                  setStartDate(d);
                }}
                customInput={<CustomDateButton />}
              />
              <span
                style={{ margin: '0 0.25rem', color: '#aaa', fontWeight: 'bold', fontSize: '16px' }}
              >
                ~
              </span>
              <DatePicker
                selected={endDate}
                locale={ko}
                dateFormat="yyyy/MM/dd"
                onChange={d => {
                  setEndDate(d);
                }}
                customInput={<CustomDateButton />}
              />
              <Button type="normal" onClick={loadData} style={{ marginLeft: '0.5rem' }}>
                불러오기
              </Button>
            </div>
          }
        >
          <Table
            checkbox
            toggleSelection={(key, data) => {
              const id = key.split('-')[1];
              if (data.status === 4 && !checkArr.includes(id)) {
                setCheckArr(Array.from(new Set([...checkArr, id])));
              } else {
                setCheckArr(checkArr.filter(item => item !== id));
              }
            }}
            data={data}
            headers={adminHeaders}
            perPage={10}
            setCheckedList={setCheckArr}
          />
        </Container>
      ) : (
        <Loader />
      )}
    </>
  );
};

const NormalList = () => {
  const { db } = useContext(FirebaseContext);
  const user = useContext(UserStateContext);
  const [data, setData] = useState(null);
  const [today] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date(today.getFullYear(), today.getMonth(), 1));
  const [endDate, setEndDate] = useState(today);

  const loadStoreList = useCallback(() => {
    return db
      .collection('stores')
      .where('group', 'array-contains', user.id)

      .get()
      .then(snapshots => {
        let result = [];
        snapshots.forEach(doc => {
          result.push(doc.id);
        });
        return result;
      });
  }, [db, user]);

  const loadHotelList = useCallback(() => {
    return db
      .collection('hotels')
      .where('group', 'array-contains', user.id)

      .get()
      .then(snapshots => {
        let result = [];
        snapshots.forEach(doc => {
          result.push(doc.id);
        });
        return result;
      });
  }, [db, user]);

  const loadData = useCallback(async () => {
    if (!user) return;
    const storeList = await loadStoreList();
    const hotelList = await loadHotelList();

    if (storeList)
      db.collection('orders')
        .where('date', '>=', startDate)
        .where('date', '<=', endDate)
        .orderBy('date', 'desc')
        .get()
        .then(snapshots => {
          let result = [];
          snapshots.forEach(doc => {
            result.push({
              ...doc.data(),
              id: doc.id
            });
          });
          setData(
            result.filter(
              item => storeList.includes(item.store_id) || hotelList.includes(item.hotel_id)
            )
          );
        });
  }, [db, endDate, startDate, loadStoreList, user, loadHotelList]);

  useEffect(() => {
    loadData();
  }, [db, loadData]);

  return (
    <>
      {data ? (
        <Container
          title="주문/정산 관리"
          navigator="주문/정산 목록"
          buttons={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <DatePicker
                selected={startDate}
                locale={ko}
                dateFormat="yyyy/MM/dd"
                onChange={d => {
                  setStartDate(d);
                }}
                customInput={<CustomDateButton />}
              />
              <span
                style={{ margin: '0 0.25rem', color: '#aaa', fontWeight: 'bold', fontSize: '16px' }}
              >
                ~
              </span>
              <DatePicker
                selected={endDate}
                locale={ko}
                dateFormat="yyyy/MM/dd"
                onChange={d => {
                  setEndDate(d);
                }}
                customInput={<CustomDateButton />}
              />
              <Button type="normal" onClick={loadData} style={{ marginLeft: '0.5rem' }}>
                불러오기
              </Button>
            </div>
          }
        >
          <Table data={data} headers={normalHeaders} perPage={10} />
        </Container>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default OrderList;

const CustomDateButton = ({ value, onClick }) => {
  return (
    <Button type="normal" onClick={onClick}>
      {value}
    </Button>
  );
};
