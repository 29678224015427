import React, { useState, useContext } from 'react';
import { Gap } from '.';
import Div from './Div';
import Button from './Button';
import { UserStateContext } from '../contexts/UserContext';

function LogList({ data }) {
  const [open, setOpen] = useState(false);
  const user = useContext(UserStateContext);

  return (
    <Gap>
      {user && user.authLevel === 'ROOT' && (
        <>
          <Div title="로그">
            <Button type="normal" onClick={() => setOpen(!open)}>
              {open ? '닫기' : '보기'}
            </Button>
          </Div>
          <div
            style={{ width: '100%', maxHeight: '250px', overflowY: 'scroll', marginTop: '1rem' }}
          >
            {data && open && (
              <table style={{ fontSize: '12px', marginLeft: '120px', color: '#000' }}>
                <tbody>
                  {data &&
                    data.map((item, key) => (
                      <tr key={key}>
                        <td>{item.record}</td>
                        <td>{JSON.stringify(item.detail)}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            )}
          </div>
        </>
      )}
    </Gap>
  );
}

export default LogList;
