import React, { useState } from 'react';
import Div from './Div';
import { Gap } from '.';
import Input from './Input';
import Dropdown from './Dropdown';
import Button from './Button';
import axios from 'axios';

function MakeQrCode({ id, make_type }) {
  const [qrData, setQrData] = useState({
    bgColor: 'transparent',
    size: 300,
    type: 'default',
    type_body: 'default',
    type_logo: 'default',
    kind: 'default',
    encryption: 'WPA'
  });

  const makeQR = (bgColor, size = 300, type, type_body, type_logo) => {
    const ballColors = {
      default: ['#ffffff', '#ffffff', '#ffffff'],
      1: ['#ff7d3c', '#ff3250', '#d21e7d'],
      2: ['#000000', '#000000', '#000000']
    };
    const bodyColors = {
      default: '#ffffff',
      1: '#000000'
    };
    const logoColors = {
      default: `311e260c848276ca4aeb69a562c3e62c3744e335.png`,
      1: `eb20c6609fbbd02f1a2364228484ce1d890b0b7e.png`,
      2: `d7a4f174c98afd1ad9b505fce4802851bf6fe26e.png`, // white-wifi
      3: `c1a6d3e9c78927bf808c267b264cd381b9d7b318.png`, // black-wifi,
      4: null
    };

    const url =
      make_type === 'hotel'
        ? `https://app.deliquick.co.kr/?code=${id}${
            qrData.roomNo ? `&roomNo=${qrData.roomNo}` : ''
          }`
        : `https://app.deliquick.co.kr/preview/${id}`;

    const wifi = `WIFI:S:${qrData.wifiName};T:${qrData.encryption};P:${qrData.password};;`;

    const configData = {
      data: qrData.kind === 'default' ? url : wifi,
      config: {
        body: 'square',
        logo: logoColors[type_logo],
        logoMode: 'clean',
        eye: 'frame1',
        erf1: ['fh'],
        erf3: ['fv', 'fh'], // 왼쪽아래네모
        bgColor: bgColor || '#c63a47',
        bodyColor: bodyColors[type_body],
        eye1Color: bodyColors[type_body],
        eye2Color: bodyColors[type_body],
        eye3Color: bodyColors[type_body],
        eyeBall1Color: ballColors[type][0],
        eyeBall2Color: ballColors[type][1],
        eyeBall3Color: ballColors[type][2]
      },
      size,
      download: true,
      file: 'png'
    };

    axios({
      method: 'post',
      url: `https://qrcode-monkey.p.rapidapi.com/qr/custom`,
      headers: {
        'x-rapidapi-host': `qrcode-monkey.p.rapidapi.com`,
        'x-rapidapi-key': `7279bd3311msh869db087faffe82p137537jsne0b6d7c1ab1c`,
        'content-type': `application/json`,
        accept: `application/json`
      },
      data: configData
    }).then(result => {
      console.log(result);
      window.open(`http:${result.data.imageUrl}`);
    });
  };
  return (
    <Div title="QR코드 생성" style={{ borderTop: '1px solid #ccc', paddingTop: '1rem' }}>
      {make_type === 'hotel' && (
        <Gap>
          <Div title="용도">
            <Dropdown
              defaultValue="default"
              onChange={e => {
                setQrData({ ...qrData, kind: e.target.value });
              }}
              options={[
                { text: '일반', value: 'default' },
                { text: '와이파이', value: 'wifi' }
              ]}
            />
          </Div>
        </Gap>
      )}

      {qrData.kind === 'wifi' && make_type === 'hotel' && (
        <>
          <Input
            required
            title="와이파이명"
            style={{ minWidth: '120px', marginRight: '0.25rem' }}
            onChange={e => {
              setQrData({ ...qrData, wifiName: e.target.value });
            }}
          />
          <Input
            required
            title="비밀번호"
            style={{ minWidth: '120px', marginRight: '0.25rem' }}
            onChange={e => {
              setQrData({ ...qrData, password: e.target.value });
            }}
          />
          <Div title="암호방식">
            <Dropdown
              defaultValue="default"
              onChange={e => {
                setQrData({ ...qrData, encryption: e.target.value });
              }}
              options={[
                { text: 'WPA/WPA2', value: 'WPA' },
                { text: 'WEP', value: 'WEP' },
                { text: '없음', value: 'nopass' }
              ]}
            />
          </Div>
        </>
      )}
      {qrData.kind === 'default' && make_type === 'hotel' && (
        <>
          <Input
            title="방번호"
            placeholder="없으면 미입력"
            style={{ minWidth: '120px', marginRight: '0.25rem' }}
            onChange={e => {
              setQrData({ ...qrData, roomNo: e.target.value });
            }}
          />
        </>
      )}
      <Gap>
        <Input
          title="사이즈"
          placeholder="미입력시 기본: 300"
          style={{ minWidth: '120px', marginRight: '0.25rem' }}
          onChange={e => {
            setQrData({ ...qrData, size: e.target.value });
          }}
        />
        <Input
          title="배경색"
          placeholder="컬러코드 (eg. #ffffff)"
          defaultValue={qrData.bgColor}
          style={{ minWidth: '120px', marginRight: '0.25rem', background: qrData.bgColor }}
          onChange={e => {
            setQrData({ ...qrData, bgColor: e.target.value });
          }}
        />
        <Div title="볼타입선택">
          <Dropdown
            defaultValue="default"
            onChange={e => {
              setQrData({ ...qrData, type: e.target.value });
            }}
            options={[
              { text: '기본흰색', value: 'default' },
              { text: '컬러', value: 1 },
              { text: '검정', value: 2 }
            ]}
          />
        </Div>
        <Div title="바디색상">
          <Dropdown
            defaultValue="default"
            onChange={e => {
              setQrData({ ...qrData, type_body: e.target.value });
            }}
            options={[
              { text: '기본흰색', value: 'default' },
              { text: '검정', value: 1 }
            ]}
          />
        </Div>
        <Div title="로고타입">
          <Dropdown
            defaultValue="default"
            onChange={e => {
              setQrData({ ...qrData, type_logo: e.target.value });
            }}
            options={[
              { text: '기본흰색', value: 'default' },
              { text: '검정', value: 1 },
              { text: '와이파이-흰색', value: 2 },
              { text: '와이파이-검정', value: 3 },
              { text: '없음', value: 4 }
            ]}
          />
        </Div>
      </Gap>

      <span />
      <Button
        onClick={() => {
          makeQR(qrData.bgColor, qrData.size, qrData.type, qrData.type_body, qrData.type_logo);
        }}
      >
        QR코드생성
      </Button>
    </Div>
  );
}

export default MakeQrCode;
