import cogo from "cogo-toast";

const toastConfig = {
  position: "top-right",
  hideAfter: 1
};

const toast = msg => {
  return cogo.success(msg, toastConfig);
};

export default toast;

export const alert = msg => {
  return cogo.warn(msg, toastConfig);
};
