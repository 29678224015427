import React, { useEffect, useContext, useState, useCallback } from 'react';
import { FirebaseContext } from '../../firebase';
import { jsonToSheet } from '../../common/utils';
import { Link } from 'react-router-dom';
import { Button, Toast as toast, Loader, Status, Table, Container } from '../../components';
import { UserStateContext } from '../../contexts/UserContext';
import { formatPhone } from '../../common/format';
import { MdMap } from 'react-icons/md';

function StoreList() {
  const user = useContext(UserStateContext);
  switch (user && user.authLevel) {
    case 'ROOT':
      return <AdminList />;
    case 'NORMAL':
      return <NormalList />;
    default:
      return <Loader />;
  }
}

const headers = [
  {
    title: '업체명',
    value: 'realName',
    render: row => (
      <>
        <Link to={`/store/edit/${row.id}`}>{row.realName}</Link>
        <br />[{row.id}]
      </>
    )
  },

  { title: '전화번호', value: 'phone', render: row => formatPhone(row.phone) },
  {
    title: '주소',
    value: 'address_search',
    render: row => (
      <>
        {row.address && row.address.road}
        {row.address && row.address.detail}
      </>
    )
  },
  {
    title: '협력사명',
    value: 'companyName',
    align: 'center'
  },
  {
    title: '영업상태',
    value: 'active',
    width: '10%',
    align: 'center',
    render: row =>
      row.active ? <Status type="SUCCESS" msg="영업중" /> : <Status type="WAIT" msg="영업종료" />
  },
  {
    title: '등록상태',
    value: 'status',
    width: '10%',
    align: 'center',
    render: row => {
      switch (row.status) {
        case '등록':
          return <Status type="SUCCESS" msg="등록" />;
        case '대기':
          return <Status type="WAIT" msg="대기" />;
        case '해지':
          return <Status type="FAIL" msg="해지" />;
        case '번역완료':
          return <Status type="NORMAL" msg={row.status} />;
        default:
          return <Status msg={row.status} />;
      }
    }
  }
];

const AdminList = () => {
  const { db } = useContext(FirebaseContext);
  const [data, setData] = useState(null);
  const [checkedList, setCheckedList] = useState([]);

  const loadData = useCallback(() => {
    db.collection('stores')
      .orderBy('createdAt', 'desc')
      .get()
      .then(snapshots => {
        let result = [];
        snapshots.forEach(doc => {
          const data = doc.data();
          result.push({
            ...data,
            address_search:
              data.address &&
              (data.address.jibun || '') + (data.address.road || '') + (data.address.detail || ''),
            id: doc.id
          });
        });
        setData(result);
      });
  }, [db]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const setStatus = _ => {
    if (checkedList.length === 0) return alert('선택항목이 없습니다.');
    let batch = db.batch();

    checkedList.forEach(item => {
      const ref = db.collection('stores').doc(item.id);
      batch.update(ref, { status: '해지' });
    });
    batch
      .commit()
      .then(() => {
        toast('업데이트되었습니다.');
        loadData();
      })
      .catch(err => {
        console.log(err);
      });
  };

  return (
    <>
      {data ? (
        <Container
          title="배달업체관리"
          navigator="업체목록"
          buttons={
            <>
              <Button to="/store/map" type="normal">
                <MdMap /> <span> 거리별업체검색</span>
              </Button>
              <Button
                onClick={() => {
                  let arr = [];
                  data.forEach(item => {
                    arr.push({
                      ...item,
                      address: item.address.road || item.address.jibun,
                      address_detail: item.address.detail,
                      createdAt: item.createdAt.toDate().toLocaleDateString(),
                      editedAt: item.editedAt && item.editedAt.toDate().toLocaleDateString()
                    });
                  });
                  jsonToSheet(arr, '배달업체목록');
                }}
                style={{ background: '#0F9D58', color: 'white' }}
              >
                엑셀저장
              </Button>
              <Button onClick={setStatus} type="normal">
                해지
              </Button>
              <Button to={`/store/addcsv`} type="normal">
                메뉴업로드
              </Button>
              <Button to="/store/add" type="normal">
                +업체추가
              </Button>
            </>
          }
        >
          <Table
            index
            data={data}
            perPage={30}
            checkbox
            setCheckedList={setCheckedList}
            headers={headers}
          />
        </Container>
      ) : (
        <Loader />
      )}
    </>
  );
};

const NormalList = () => {
  const { db } = useContext(FirebaseContext);
  const [data, setData] = useState(null);
  const user = useContext(UserStateContext);

  const loadData = useCallback(() => {
    db.collection('stores')
      .where('group', 'array-contains', user.id)
      .orderBy('createdAt', 'desc')
      .get()
      .then(snapshots => {
        let result = [];
        snapshots.forEach(doc => {
          const data = doc.data();
          result.push({
            ...data,
            address_search:
              data.address &&
              (data.address.jibun || '') + (data.address.road || '') + (data.address.detail || ''),
            id: doc.id
          });
        });
        setData(result);
      });
  }, [db, user.id]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <>
      {data ? (
        <Container
          title="배달업체관리"
          navigator="업체목록"
          buttons={
            <>
              <Button to="/store/map" type="normal">
                <MdMap /> <span> 거리별업체검색</span>
              </Button>
              <Button to="/store/add" type="normal">
                +업체추가
              </Button>
            </>
          }
        >
          <Table index data={data} perPage={30} headers={headers} />
        </Container>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default StoreList;
