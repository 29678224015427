import { useEffect, useState, useRef, useCallback, useContext } from 'react';
import { UserStateContext } from '../contexts/UserContext';
// Hook
export const useOnClickOutside = (ref, handler) => {
  useEffect(() => {
    const listener = event => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
};

export class Logger {
  constructor(user, isEdit, logs) {
    this.date = new Date();
    this.isEdit = isEdit;
    this.content = isEdit ? '수정' : '생성';
    this.user = {
      userId: user.id,
      name: user.name,
      companyName: user.companyName
    };
    this.logs = logs;
  }

  toObject() {
    const { name, companyName } = this.user;
    const newObj = {
      record: `[${companyName}] ${name} - ${this.content} - ${this.date.toLocaleString()}`,
      detail: this.isEdit && this.logs
    };
    if (!this.isEdit) delete newObj.detail;
    return newObj;
  }
}

export const useChangeLog = (initialData, isEdit) => {
  const changeLog = useRef(new Map());
  const [data, setData] = useState(null);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const user = useContext(UserStateContext);

  useEffect(() => {
    if (initialData && isFirstLoad) {
      setData(initialData);
      setIsFirstLoad(false);
    }
  }, [isFirstLoad, initialData]);

  const onChangeDataToLog = (key, value) => {
    const currentLog = changeLog.current;
    if (!data) return;
    if (value !== data[key]) {
      currentLog.set(key, `${key}: ${data[key]} => ${value}`);
    } else if (value === data[key]) {
      currentLog.delete(key);
    }
    return;
  };

  const getChangeLog = useCallback(() => {
    return new Logger(user, isEdit, [...changeLog.current.values()]).toObject();
  }, [isEdit, user]);

  return [{ getChangeLog, onChangeDataToLog }];
};
