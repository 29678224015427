import React, { useContext, useEffect, useState, useCallback, useRef } from 'react';
import styled from 'styled-components';
import { useFirebase } from '../../common/fetch';
import Container from '../../components/Container';
import Button from '../../components/Button';
import { UserStateContext } from '../../contexts/UserContext';
import { NoticeMark, NewMark } from '../../components/Board/Board';
import { format, isSameDay } from 'date-fns';
import { MdAccountCircle, MdKeyboardArrowDown } from 'react-icons/md';
import { formatSize, dateToWords } from '../../common/format';
import { FirebaseContext } from '../../firebase';
import toast from '../../components/Toast';
import Dropdown from '../../components/Dropdown';

const View = styled.div`
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  margin-top: 1rem;
  color: #666;
  .line {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ccc;
    padding: 0.5rem;
  }
  .content-view {
    padding: 0.5rem;
    font-size: 14px;
    line-height: 1.6;
    margin: 1rem;
    min-height: 280px;
  }
  .comment-info {
    display: flex;
    align-items: center;
    padding-left: 1.5rem;
    font-size: 12px;
    font-weight: bold;
    .gray {
      color: #ccc;
      font-size: 12px;
    }
    div + div {
      margin-left: 0.25rem;
    }
  }
`;

const CommentGroup = styled.div`
  background: #f6f6f6;
  margin: 1rem 2.5%;
  width: 95%;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  .owner-mark {
    border: 1px solid #ff6d72;
    color: #ff6d72;
    border-radius: 6px;
    padding: 0.2em 0.3em;
    font-size: 9px;
    margin-left: 0.25rem;
  }
`;

function NoticeDetail(props) {
  const { id } = props.match.params;
  const [{ data, docRef, db }] = useFirebase('boards', null, id);
  const [comments, setComments] = useState([]);
  const [sort, setSort] = useState(true);

  const { Timestamp } = useContext(FirebaseContext);

  const [status, setStatus] = useState(data && data.status);

  const onDeleteBoard = () => {
    let result = window.confirm('정말 삭제하시겠습니까?');
    if (result)
      docRef.delete().then(_ => {
        toast('삭제되었습니다.');
        props.history.push('/notice');
      });
  };

  const loadComments = useCallback(() => {
    if (!docRef) return;
    docRef
      .collection('comments')
      .orderBy('createdAt')
      .get()
      .then(snapshots => {
        let result = [];
        snapshots.forEach(doc => {
          result.push({ ...doc.data(), id: doc.id });
        });
        setComments(result);
      });
  }, [docRef]);

  const editCommentCount = useCallback(
    count => {
      db.runTransaction(t => {
        return t.get(docRef).then(doc => {
          let commentsCount = doc.data().commentsCount + count;
          t.update(docRef, { commentsCount });
        });
      });
    },
    [db, docRef]
  );

  useEffect(() => {
    loadComments();
  }, [loadComments]);

  const user = useContext(UserStateContext);

  const onRegisterComment = contents => {
    const newComment = {
      contents,
      user: {
        name: user.name,
        userId: user.id,
        companyName: user.companyName || ''
      },
      createdAt: Timestamp.fromDate(new Date())
    };
    docRef
      .collection('comments')
      .add(newComment)
      .then(() => {
        loadComments();
        editCommentCount(1);
      });
  };

  const onDeleteComment = id => {
    let result = window.confirm('정말 삭제하시겠습니까? ');
    if (!result) return;
    if (!id) return;
    docRef
      .collection('comments')
      .doc(id)
      .delete()
      .then(() => {
        setComments(comments => comments.filter(item => item.id !== id));
        editCommentCount(-1);
        toast('삭제되었습니다.');
      });
  };

  const onEditComment = (id, contents) => {
    if (!id || !contents) return;
    docRef
      .collection('comments')
      .doc(id)
      .update({ contents, editedAt: new Date() })
      .then(() => {
        loadComments();
        toast('수정되었습니다.');
      });
  };

  const changeStatus = () => {
    docRef
      .update({ status })
      .then(_ => toast('업데이트 되었습니다.'))
      .catch(_ => alert('에러가 발생했습니다. 다시 시도해주세요.'));
  };

  return (
    <Container
      title="요청/문의"
      navigator={`게시글 / ${(data && data.id) || ''}`}
      buttons={
        user &&
        data &&
        ((user.id === data.user.userId && user.authLevel === 'ROOT') ||
          user.authLevel === 'ROOT') && (
          <div style={{ display: 'flex' }}>
            {user.id === data.user.userId && (
              <Button type="edit" to={`/notice/edit/${data.id}`}>
                수정
              </Button>
            )}
            <Button onClick={onDeleteBoard} type="delete">
              삭제
            </Button>
          </div>
        )
      }
    >
      {data && (
        <View>
          <div className="line">
            {data.notice ? (
              <NoticeMark />
            ) : (
              <span style={{ color: '#aaa', fontSize: '12px', textAlign: 'center', width: '40px' }}>
                {data.id}
              </span>
            )}
            <span style={{ marginLeft: '0.5rem', fontWeight: 'bold' }}>
              {data.notice ? data.title : data.type}
            </span>
            <span style={{ marginLeft: 'auto', fontSize: '12px', color: '#aaa' }}>
              {!data.editedAt &&
                data.createdAt &&
                format(data.createdAt.toDate(), 'yyyy.MM.dd. HH:mm')}
              {data.editedAt && format(data.editedAt.toDate(), 'yyyy.MM.dd. HH:mm') + ' (수정됨)'}
            </span>
          </div>
          <div className="line" style={{ border: 'none' }}>
            <span
              style={{
                fontSize: '14px',
                fontWeight: 'bold',
                display: 'inline-flex',
                alignItems: 'center',
                marginLeft: '0.5rem'
              }}
            >
              <MdAccountCircle color="#ccc" size="28px" style={{ marginRight: '0.25rem' }} />
              {data.user.name}
            </span>
            <span style={{ fontSize: '12px', marginLeft: '0.25rem', color: '#aaa' }}>
              {data.user.companyName}
            </span>
            {data.file && (
              <div style={{ marginLeft: 'auto', fontSize: '12px', display: 'inline-flex' }}>
                <b>첨부파일</b>
                <div
                  style={{
                    marginLeft: '0.5rem',
                    maxWidth: '150px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap'
                  }}
                >
                  <a href={data.file.fileUrl} download target="_blank" rel="noopener noreferrer">
                    {data.file.fileInfo.name}
                  </a>
                </div>
                {` (${formatSize(data.file.fileInfo.size)})`}
              </div>
            )}
          </div>
          <div className="content-view" dangerouslySetInnerHTML={{ __html: data.contents }} />
          {!data.notice && user && user.authLevel === 'ROOT' && (
            <div
              className="line"
              style={{
                borderTop: '1px solid #ccc',
                borderBottom: '1px solid #ccc',
                marginBottom: '1rem'
              }}
            >
              <div style={{ margin: '0 1rem', fontWeight: 'bold' }}>처리상태</div>
              <Dropdown
                defaultValue={data.status}
                onChange={e => setStatus(e.target.value)}
                options={[
                  {
                    text: '처리완료'
                  },
                  { text: '반려' },
                  { text: '미처리' }
                ]}
              />
              <Button
                onClick={() => {
                  changeStatus();
                }}
              >
                상태변경
              </Button>
            </div>
          )}
          <div className="comment-info">
            <div style={{ display: 'inline-flex', alignItems: 'center' }}>
              <span>댓글 {comments.length}</span>
              {comments.length > 0 &&
                isSameDay(
                  sort
                    ? [...comments].pop().createdAt.toDate()
                    : [...comments]
                        .reverse()
                        .pop()
                        .createdAt.toDate(),
                  new Date()
                ) && <NewMark style={{ margin: 0, marginLeft: '2px' }} />}
            </div>
            <div className="gray">|</div>
            <div
              style={{ display: 'inline-flex', alignItems: 'center', cursor: 'pointer' }}
              onClick={() => {
                setComments(comments => comments.reverse());
                setSort(!sort);
              }}
            >
              {sort ? '등록순' : '최신순'} <MdKeyboardArrowDown />
            </div>
            <div className="gray">|</div>
            <div>조회수 {data.viewCount} </div>
            {!data.notice && (
              <>
                <div className="gray">|</div>
                <div>{data.status} </div>
              </>
            )}
          </div>
          <CommentGroup>
            {comments &&
              comments.map((item, index) => (
                <CommentItem
                  key={index}
                  data={item}
                  boardUserId={data.user.userId}
                  onDeleteComment={onDeleteComment}
                  onEditComment={onEditComment}
                />
              ))}
            <CommentTextarea onRegisterComment={onRegisterComment} />
          </CommentGroup>
        </View>
      )}
    </Container>
  );
}

export default NoticeDetail;

const CommentTextarea = ({ onRegisterComment }) => {
  const [content, setContent] = useState();
  const ref = useRef();

  const onCompelete = () => {
    if (ref.current) ref.current.value = '';
    onRegisterComment(content);
  };

  return (
    <div
      style={{ display: 'flex', padding: '1.5rem', height: '100px', borderTop: '1px dashed #ccc' }}
    >
      <textarea
        onChange={e => setContent(e.target.value.replace(/\n/g, '<br/>'))}
        ref={ref}
        style={{
          border: '1px solid #ccc',
          borderRadius: '4px',
          background: '#fff',
          resize: 'none',
          width: '80%',
          padding: '2.5%',
          fontSize: '14px',
          outline: 'none'
        }}
      />

      <div style={{ marginLeft: '1rem', width: '15%' }}>
        <Button
          type="normal"
          style={{ height: '100px', width: '95%', minWidth: '80px ' }}
          onClick={onCompelete}
        >
          작성
        </Button>
      </div>
    </div>
  );
};

const StyledItem = styled.div`
  display: flex;
  margin: 0 1rem;
  padding: 0.5rem 0;
  & + & {
    border-top: 1px dashed #ccc;
  }
`;

const CommentItem = ({ data, boardUserId, onDeleteComment, onEditComment }) => {
  const [isEdit, setIsEdit] = useState(false);
  const user = useContext(UserStateContext);
  const [content, setContent] = useState(data.contents);

  return (
    <StyledItem className="comment-item">
      {/* 좌측아이콘 */}
      <div style={{ padding: '0 0.5rem 0.5rem 0' }}>
        <MdAccountCircle color="#ccc" size="28px" />
      </div>

      {/* 우측 정보 */}
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginTop: '6px' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            fontSize: '13px',
            fontWeight: 'bold'
          }}
        >
          <span>{data.user.name}</span>
          <span style={{ fontSize: '11px', marginLeft: '0.25rem', color: '#aaa' }}>
            {data.user.companyName}
          </span>
          {data.user.userId === boardUserId && <span className="owner-mark">작성자</span>}
          <span
            style={{ marginLeft: 'auto', fontSize: '12px', color: '#aaa', fontWeight: 'normal' }}
          >
            {data.createdAt && dateToWords(data.createdAt.toDate())}
          </span>
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          {isEdit ? (
            <textarea
              onChange={e => setContent(e.target.value.replace(/\n/g, '<br/>'))}
              defaultValue={content.replace(/<br\/>/g, `\r\n`)}
              style={{
                marginTop: '1rem',
                border: '1px solid #ccc',
                borderRadius: '4px',
                background: '#fff',
                resize: 'none',
                width: '80%',
                padding: '1rem',
                fontSize: '14px',
                outline: 'none'
              }}
            />
          ) : (
            <div dangerouslySetInnerHTML={{ __html: data.contents }} style={{ width: '85%' }} />
          )}

          {data.id && user && user.id === data.user.userId && (
            <div
              style={{
                fontSize: '12px',
                color: '#aaa',
                minWidth: '50px',
                fontWeight: 'bold',
                textAlign: 'right'
              }}
            >
              <span
                style={{ cursor: 'pointer' }}
                onClick={_ => {
                  isEdit && onEditComment(data.id, content);
                  setIsEdit(!isEdit);
                }}
              >
                {isEdit ? '완료' : '수정'}
              </span>{' '}
              | {`  `}
              {isEdit && (
                <span
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setIsEdit(false);
                  }}
                >
                  취소
                </span>
              )}
              {!isEdit && (
                <span
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    onDeleteComment(data.id);
                  }}
                >
                  삭제
                </span>
              )}
            </div>
          )}
        </div>
        {data.editedAt && (
          <div style={{ fontSize: '10px', marginTop: '0.5rem', fontWeight: 'bold' }}>수정됨</div>
        )}
      </div>
    </StyledItem>
  );
};
