import React, { useContext, useState } from 'react';
import { FirebaseContext } from '../../firebase';
import { Div, Dropdown, Toast as toast, Input, Form, Container } from '../../components';

const AddDicWord = props => {
  const { db } = useContext(FirebaseContext);
  const [data, setData] = useState(props.data);

  const onChangeInput = e => {
    setData({
      ...data,
      [e.target.name]: e.target.value
    });
  };

  const onAdd = () => {
    if (!data.type) return alert('분류를 선택하세요.');
    db.collection('words')
      .add({ ...data })
      .then(() => {
        toast('업데이트 되었습니다.');
        props.onClose();
      });
  };

  return (
    <>
      <Container type="small" title="번역에 추가">
        {data && (
          <>
            <Form onSubmit={onAdd}>
              <Div title="분류" required>
                <Dropdown
                  placeholder="분류"
                  options={[
                    { text: '옵션', value: '옵션' },
                    { text: '업체명', value: '업체명' },
                    { text: '기타', value: '기타' }
                  ]}
                  style={{ width: '10%', marginRight: '0.2rem' }}
                  onChange={onChangeInput}
                  name="type"
                  defaultValue={data.type}
                />
              </Div>

              <Input
                title={'한국어'}
                onChange={onChangeInput}
                name="kr"
                value={data && data.kr}
                required
              />
              <Input
                title={'영어'}
                onChange={onChangeInput}
                name="en"
                value={data && data.en}
                required
              />
              <Input title={'중국어'} onChange={onChangeInput} name="cn" value={data && data.cn} />
              <Input title={'일본어'} onChange={onChangeInput} name="jp" value={data && data.jp} />
              <Input title={'태국어'} onChange={onChangeInput} name="th" value={data && data.th} />
            </Form>
          </>
        )}
      </Container>
    </>
  );
};

export default AddDicWord;
