import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  background-color: #f6f6f6;
  width: 100%;
  color: #aaaaaa;
  line-height: 1;
  font-size: 10px;
  text-align: center;
  padding: 2rem 0;
  .footer-top {
    font-size: 12px;
    font-weight: bold;
    line-height: 1.33;
    margin-bottom: 0.5rem;
  }
`;

const Footer = () => {
  return (
    <Container>
      <div className="footer-top">
        <div>델리퀵 관리자시스템 | 퍼블릭스 주식회사</div>
      </div>
      <div style={{ lineHeight: 1.33 }}>
        사업자등록번호 163-88-00051 · 통신판매업신고번호 제 2019-서울강남-00508호 <br />
        서울특별시 강남구 테헤란로 79길 6 3층 · 3F 6, Teheran-ro 79-gil, Gangnam-gu, Seoul <br />
        Tel 02-577-0420 · Fax 050-4014-5898 · E-Mail info@publics.co.kr <br />
        All Contents Copyright © Publics Inc.
      </div>
    </Container>
  );
};

export default Footer;
