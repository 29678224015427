import Input from './Input';
import Button from './Button';
import Address from './Address';
import Dropdown from './Dropdown';
import Loader from './Loader';
import Modal from './Modal';
import Status from './Status';
import Spinner from './Spinner';
import Container from './Container';
import Div from './Div';
import FileInput from './FileInput';
import Form from './Form';
import Notice from './Notice';
import Tag from './Tag';
import Toast from './Toast';
import Table from './Table/Table';
import Textarea from './Textarea';
import styled from 'styled-components';

const Gap = styled.div`
  margin: 1.5rem 0;
`;

export {
  Address,
  Button,
  Container,
  Div,
  Dropdown,
  FileInput,
  Form,
  Input,
  Modal,
  Notice,
  Status,
  Table,
  Tag,
  Toast,
  Loader,
  Gap,
  Spinner,
  Textarea
};
