import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  font-size: 14px;
  padding: 0.25rem 0;
  display: inline-block;
  white-space: nowrap;
  min-height: 20px;
  display: block;
`;

const Div = ({ children, title, required, style }) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        ...style
      }}
    >
      {title && (
        <div style={{ width: '120px' }}>
          {title}
          {required && <span style={{ marginLeft: '0.25rem', color: '#ff3250' }}>*</span>}
        </div>
      )}
      <Container>{children}</Container>
    </div>
  );
};

export default Div;
