import React, { useContext, useState, useEffect, useMemo } from 'react';
import {
  Input,
  Gap,
  Address,
  Modal,
  Loader,
  Div,
  Dropdown,
  Toast as toast,
  Notice,
  Form,
  Container,
  Textarea,
} from '../../components';
import Button from '../../components/Button';
import { FirebaseContext } from '../../firebase';
import { UserStateContext } from '../../contexts/UserContext';
import { useChangeLog } from '../../common/hooks';
import AddServiceStore from './AddServiceStore';
import LogList from '../../components/LogList';
import MakeQrCode from '../../components/MakeQrCode';
import { categoryList } from '../../common/category';

const EditHotel = (props) => {
  const { id } = props.match.params;
  const user = useContext(UserStateContext);
  const { db } = useContext(FirebaseContext);
  const [data, setData] = useState(null);

  const [isAddressOpen, setAddressOpen] = useState(false);
  const [storeOpen, setStoreOpen] = useState(false);

  const [{ getChangeLog, onChangeDataToLog }] = useChangeLog(data, true);
  const [isLoading, setIsLoading] = useState(false);
  const [time, setTime] = useState();

  useEffect(() => {
    if (!user) return;
    db.collection('hotels')
      .doc(id)
      .get()
      .then((doc) => {
        if (!doc.exists) return null;
        const { group, timeData } = doc.data();
        if (user.authLevel !== 'ROOT' && !group.includes(user.id)) {
          alert('잘못된 접속 경로입니다.');
          return props.history.goBack();
        }
        setTime(timeData);
        setData({
          ...doc.data(),
          openTime: doc.data().time ? doc.data().time.split('~')[0] : '',
          closeTime: doc.data().time ? doc.data().time.split('~')[1] : '',
        });
      })
      .catch((err) => console.log(err));
  }, [db, id, props.history, user]);

  const onChangeInput = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
    onChangeDataToLog(e.target.name, e.target.value);
  };

  const onChangeNumber = (e) => {
    const value = e.target.value === '' ? '' : parseInt(e.target.value);
    if (Number.isNaN(value) && value !== '') {
      e.target.value = null;
      return alert('숫자만 입력가능합니다.');
    }
    setData({
      ...data,
      [e.target.name]: value,
    });
    onChangeDataToLog(e.target.name, value);
  };

  const onEdit = () => {
    setIsLoading(true);
    let newData = {
      ...data,
      time: `${time.open_hour}:${time.open_minute === 0 ? '00' : time.open_minute}~${
        time.close_hour
      }:${time.close_minute === 0 ? '00' : time.close_minute}`,
      timeData: {
        ...time,
      },
      editedAt: new Date(),
      logs: [...(data.logs || []), getChangeLog()],
    };

    db.collection('hotels')
      .doc(id)
      .update(newData)
      .then(() => {
        toast('업데이트 되었습니다.');
        setIsLoading(false);
        props.history.goBack();
      });
  };

  const showAddress = useMemo(() => {
    return data && data.address && `${data.address.road || ''} ${data.address.detail || ''}`;
  }, [data]);

  const changeCategory = (e) => {
    const { value, checked } = e.target;
    const newData = data.category || [];

    if (checked) {
      setData({
        ...data,
        category: Array.from(new Set(newData.concat(parseInt(value)))),
      });
      onChangeDataToLog('category', Array.from(new Set(newData.concat(parseInt(value)))));
    } else {
      // 삭제
      setData({
        ...data,
        category: Array.from(new Set(newData.filter((item) => item !== parseInt(value)))),
      });
      onChangeDataToLog(
        'category',
        Array.from(new Set(newData.filter((item) => item !== parseInt(value))))
      );
    }
  };

  const onChangeTime = (e) => {
    setTime({ ...time, [e.target.name]: parseInt(e.target.value) });
  };

  const onChangeRadio = (e) => {
    const { value, name } = e.target;
    const flag = value === 'true';
    setData({ ...data, [name]: flag });
  };

  return (
    <>
      <Container title="숙박업체관리" navigator="숙박업체목록 > 숙박업체수정">
        {data ? (
          <>
            <Form onSubmit={onEdit} btn="저장" requires={['address']} data={data}>
              <Input defaultValue={id} title={'호텔코드'} disabled />
              <Input
                disabled
                defaultValue={data.companyName}
                title={'협력사명'}
                onChange={onChangeInput}
                name="companyName"
              />
              <Input
                defaultValue={data.chargePerson}
                title={'관리담당자명'}
                onChange={onChangeInput}
                name="chargePerson"
              />
              <Gap />
              <Div title="업종분류" required>
                <Dropdown
                  name="type"
                  onChange={onChangeInput}
                  defaultValue={data.type}
                  options={[
                    { text: '호텔' },
                    { text: '모텔' },
                    { text: '게스트하우스' },
                    { text: '펜션' },
                  ]}
                />
              </Div>
              <Input
                defaultValue={data.name}
                title={'업체명'}
                onChange={onChangeInput}
                name="name"
                required
              />
              <Input
                defaultValue={data.name_en}
                title={'업체명(영문)'}
                onChange={onChangeInput}
                name="name_en"
              />
              <Input
                required
                defaultValue={data.phone}
                title={'전화번호'}
                onChange={onChangeInput}
                name="phone"
                placeholder={`'-' 포함한 전체 번호`}
              />
              <Gap>
                <Input
                  required
                  value={showAddress}
                  title={'주소'}
                  name="address"
                  style={{ width: '60%' }}
                  disabled
                >
                  <Button
                    type="primary"
                    onClick={() => {
                      setAddressOpen(true);
                    }}
                  >
                    주소검색
                  </Button>
                </Input>

                <Input
                  value={data && data.address && data.address.en}
                  title={'영문주소'}
                  name="address"
                  style={{ width: '60%' }}
                  disabled
                />
              </Gap>

              <Input
                defaultValue={data.owner}
                title={'대표자명'}
                onChange={onChangeInput}
                name="owner"
              />

              <Input
                defaultValue={data.serviceFee}
                title={
                  <>
                    쉐어금액
                    <br />
                    (계약시 입력)
                  </>
                }
                onChange={onChangeNumber}
                name="serviceFee"
                placeholder="단위(원)(숫자만입력)"
              />

              <Input
                defaultValue={data.defaultMessage}
                style={{ width: '400px' }}
                title={<>주문시 요청사항</>}
                onChange={(e) => {
                  const length = e.target.value.length;
                  if (length > 100) return alert('기본메시지는 100자이내로 작성해주세요.');
                  onChangeInput(e);
                }}
                name="defaultMessage"
              />
              <Notice style={{ marginLeft: '120px' }}>배달업체에 전달될 기본메시지</Notice>

              <Gap>
                <Div title="영업시간" required>
                  <div className="media-flex">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Dropdown
                        options={timeArray.map((item) => ({
                          text: item,
                        }))}
                        className="small"
                        name="open_hour"
                        onChange={onChangeTime}
                        defaultValue={time.open_hour}
                      />

                      <Dropdown
                        options={[0, 15, 30, 45].map((item) => ({
                          text: item,
                        }))}
                        name="open_minute"
                        className="small"
                        onChange={onChangeTime}
                        defaultValue={time.open_minute}
                      />
                    </div>
                    <span style={{ margin: '0 0.5rem', fontSize: '18px', fontWeight: 'bold' }}>
                      ~
                    </span>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Dropdown
                        options={timeArray.map((item) => ({
                          text: item,
                        }))}
                        className="small"
                        name="close_hour"
                        onChange={onChangeTime}
                        defaultValue={time.close_hour}
                      />
                      <Dropdown
                        options={[0, 15, 30, 45].map((item) => ({
                          text: item,
                        }))}
                        className="small"
                        name="close_minute"
                        onChange={onChangeTime}
                        defaultValue={time.close_minute}
                      />
                    </div>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center', marginTop: '1rem' }}>
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setTime({ ...time, isAllDayOpen: e.target.checked });
                      }}
                      defaultChecked={time.isAllDayOpen}
                    />
                    <div>24시간영업</div>
                  </div>
                </Div>
              </Gap>

              <Div title="사용카테고리">
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {categoryList.map((item, index) => (
                    <React.Fragment key={index}>
                      <input
                        type="checkbox"
                        value={index}
                        defaultChecked={data && data.category && data.category.includes(index)}
                        onChange={changeCategory}
                      />
                      <span style={{ marginRight: '0.5rem' }}>{item}</span>
                    </React.Fragment>
                  ))}
                </div>
              </Div>

              <Div title="전화번호 수집">
                <label>
                  <input
                    type="radio"
                    value={true}
                    name="isPhoneNoAble"
                    defaultChecked={data.isPhoneNoAble}
                    onChange={onChangeRadio}
                  />
                  사용
                </label>
                <label style={{ marginLeft: '0.5rem' }}>
                  <input
                    type="radio"
                    value={false}
                    name="isPhoneNoAble"
                    defaultChecked={!data.isPhoneNoAble}
                    onChange={onChangeRadio}
                  />
                  미사용
                </label>
              </Div>
              <Div title="방번호 수집">
                <label>
                  <input
                    type="radio"
                    value={true}
                    name="isRoomNoAble"
                    defaultChecked={data.isRoomNoAble}
                    onChange={onChangeRadio}
                  />
                  사용
                </label>
                <label style={{ marginLeft: '0.5rem' }}>
                  <input
                    type="radio"
                    value={false}
                    name="isRoomNoAble"
                    defaultChecked={!data.isRoomNoAble}
                    onChange={onChangeRadio}
                  />
                  미사용
                </label>
              </Div>

              {user && user.authLevel === 'ROOT' && (
                <Gap>
                  <Input
                    defaultValue={(data && data.whiteList && data.whiteList.toString()) || ''}
                    style={{ width: '80%', textAlign: 'left' }}
                    title="추가업체"
                    onChange={(e) => {
                      const value = e.target.value.replace(/(\s*)/g, '');
                      setData({
                        ...data,
                        whiteList: value.split(','),
                      });
                    }}
                  />
                  <Input
                    defaultValue={(data && data.blackList && data.blackList.toString()) || ''}
                    style={{ width: '80%', textAlign: 'left' }}
                    title="차단업체"
                    onChange={(e) => {
                      const value = e.target.value.replace(/(\s*)/g, '');
                      setData({
                        ...data,
                        blackList: value.split(','),
                      });
                    }}
                  />
                  <Notice style={{ marginLeft: '120px' }}>
                    입력 예시: 코드1,코드2 <br />
                    공백없이 "," (콤마)로만 구분, 대소문자 주의
                  </Notice>
                </Gap>
              )}

              {user && user.authLevel === 'ROOT' && (
                <Gap>
                  <Div title="배달가능상태">
                    <Button
                      style={{ background: data.active ? '#37b24d' : '#868e96' }}
                      onClick={() => {
                        setData({ ...data, active: !data.active });
                      }}
                    >
                      {data.active ? '영업중' : '영업종료'}
                    </Button>
                  </Div>
                  <Div title="등록상태">
                    <Dropdown
                      options={[{ text: '미계약' }, { text: '계약완료' }, { text: '설치완료' }]}
                      defaultValue={data.status}
                      name="status"
                      onChange={onChangeInput}
                    />
                  </Div>
                </Gap>
              )}

              <Notice style={{ marginLeft: '120px' }}>상태변경사항은 저장 후에 반영됩니다.</Notice>
              <Gap>
                <Textarea
                  title="메모"
                  onChange={(e) => {
                    setData({ ...data, [e.target.name]: e.target.value.replace(/\n/g, '<br/>') });
                  }}
                  name="memo"
                  defaultValue={data.memo}
                  placeholder="등록시 전달사항이 있으면 남겨주세요."
                />
              </Gap>
            </Form>
            {data && <LogList data={data.logs} />}
          </>
        ) : (
          <Loader />
        )}
        {isLoading && <Loader />}

        {/* 서비스업체선택 */}
        {storeOpen && (
          <AddServiceStore
            load={storeOpen}
            hotelId={id}
            onClose={() => {
              setStoreOpen(false);
            }}
          />
        )}

        {/* 주소입력용 모달 */}
        {isAddressOpen && (
          <Modal
            onClose={() => {
              setAddressOpen(false);
            }}
          >
            <Address setData={setData} setAddressOpen={setAddressOpen} />
          </Modal>
        )}

        {/* qr생성용링크 */}
        {data && user && user.authLevel === 'ROOT' && <MakeQrCode id={id} make_type="hotel" />}
      </Container>
    </>
  );
};

export default EditHotel;

const timeArray = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23,
];
